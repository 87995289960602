/* HomeSlider */

.HomeSlider {
  margin-top: 175px;
  margin-bottom: 20px;
  height: 600px;
}
.HomeSlider swiper-container {
  margin: 20px auto;
  width: 100%;
  height: 100%;
}
.HomeSlider
  swiper-container
  .swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  background: #bbbbbb !important;
}
.HomeSlider swiper-container .swiper-button-next,
.HomeSlider swiper-container .swiper-button-prev {
  color: #fff !important;
}
.HomeSlider .swiper-pagination-progressbar {
  width: 90% !important;
  height: var(--swiper-pagination-progressbar-size, 4px) !important;
  left: 10px !important;
  top: 0 !important;
}
.HomeSlider swiper-slide {
  text-align: center;
  font-size: 18px;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomeSlider swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.HomeSlider .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  width: 50px;
  height: 50px;
  background: rgb(206, 206, 206) !important;
}
.HomeSlider .swiper-button-next svg,
.swiper-button-prev svg {
  width: 70%;
  height: 70%;
}

@media screen and (max-width: 640px) {
  .HomeSlider {
    height: 300px;
  }
}

/* Caregories Slider */
.CatSliderNoImg {
  padding: 10px;
}
.CatSliderNoImg {
  justify-content: space-around;
}
.CatSliderNoImg ul li {
  font-size: 13px;
  width: 10%;
  color: #6b6b6b;
  font-weight: 400;
  cursor: pointer;
}

.categries {
  box-sizing: border-box;
  padding: 0 0 10px 0;
  width: 100%;
  height: 100%;
  margin: 25px auto;
}

.categries swiper-container {
  width: 100%;
  height: 100%;
}

.categries swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;

  align-items: center;
}

.categries swiper-slide img {
  display: block;
  width: 90%;
  height: 90%;
  margin: auto;
  object-fit: cover;
}

.categries swiper-slide h3 {
  margin: 30px auto;
}
/* Caregories Slider */

.Brands swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px !important;
  height: 100px;
  border-radius: 50%;
  background-color: #f0f0f0;
  margin: auto 20px;
}
/* ProductSlider */
.ProductSlider {
  box-sizing: border-box;
  padding: 0 0 10px 0;
  width: 100%;
  height: 100%;
}

.ProductSlider swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  height: 480px;
  align-items: center;
  background-size: cover;
  background-position: center;
  margin-top: 10px;
}

.ProductSlider swiper-slide img {
  display: block;
  width: 90%;
  height: 90%;
  margin: auto;
  object-fit: cover;
}

.ProductSlider swiper-slide h3 {
  margin: 30px auto;
}

/* CatSliderNoImg */
.home-Categories .navbar-nav {
  margin: auto;
  padding: 1%;
}

/* OfferSlider */
.offerSlider {
  margin: 50px auto;
}

.offerSlider swiper-slide img {
  width: 100% !important;
  object-fit: cover;
}

/* Product Details Slider */
.productDetailSlider swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productDetailSlider swiper-slide img {
  width: 90%;
  height: 90%;
  object-fit: contain;
}
.productDetailSlider swiper-container {
  margin: 10px;
  /* width: 500px; */
  margin-left: auto;
  margin-right: auto;
}

.productDetailSlider swiper-container:nth-child(1) {
  height: 500px;
}

.productDetailSlider swiper-slide {
  background-size: cover;
  background-position: center;
  border: 2px solid #d4d4d459;
  border-radius: 10px;
  padding: 10px;
}

.productDetailSlider .mySwiper {
  height: 80%;
  /* width: 100%; */
}

.productDetailSlider .mySwiper2 {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.productDetailSlider .mySwiper2 .swiper {
  width: 90%;
}
.productDetailSlider swiper-container:nth-child(2) swiper-slide {
  height: 100px;
}

.productDetailSlider .mySwiper2 .swiper-slide-thumb-active {
  opacity: 1;
}

@media screen and (max-width: 640px) {
  .ProductSlider swiper-slide {
    height: 320px;
  }
}
