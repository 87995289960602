.cart-item-quantity {
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 50px;
  height: 50px;
  margin: 0;
  background-color: #eeeeee7d;
  border-radius: 50%;
}

.cart-item-container {
  position: relative;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(190, 190, 190, 0.3);
  padding: 0 15px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.cart-item-container > div > a {
  background-color: #fff;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 10px;
  width: 128px;
  height: 128px;
}
.cart-item-container > div > a img {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  object-fit: cover;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}

.shopping_cart {
  min-height: 750px;
}
.checkout-progress-bar {
  margin-top: 14% !important;
}
.cart-item-container .price svg {
  border: 1px solid #fe9900;
  border-radius: 50%;
  margin: 0 10px;
}
.Trash {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fe9900;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
