.side-nav-filter-title-link {
  display: block;
  position: relative;
  color: inherit;
  transition: all 0.3s;
  text-decoration: none;
  background-color: transparent;
}

.side-nav-filter-title {
  margin: 0;
  color: #313131;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
}

.side-nav-filter-section-container {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin: 0;
  padding: 2rem;
  margin-bottom: 10px;
}
.Brands_list,
.price_ranges {
  padding: 10px;
}

.Brands_list div input,
.price_ranges div input {
  margin: 0px 10px;
  cursor: pointer;
}
.price_form input {
  width: 30%;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
}
.price_form button {
  padding: 5px;
}
.price_form button svg {
  width: 33px;
  height: 17px;
}
.price_ranges input {
  cursor: pointer;
}
