.Products {
  margin-top: 212px !important;
}
.search-products > div {
  margin-bottom: 40px;
}
.LoadProductsButton {
  width: 40%;
  display: block;
  font-size: 16px;
  text-align: center;
  margin: auto;
  background-color: #fdf9f9;
  padding: 10px;
  border-radius: 40px;
  border: 1px solid #c1c1c1;
}
.sidebar-wrapper {
  /* position: fixed; */
  width: 80%;
}
.SortBy {
  width: 50%;
}
.SortBy select {
  width: 100%;
  border-radius: 14px;
}

.filters-button {
  background-color: #fff;
  color: #777;
  width: 40%;
  height: 34px;
  border: 1px solid #ced4da;
  border-radius: 14px;
  font-size: 14px;
}
@media screen and (max-width: 640px) {
  .Products {
    margin-top: 160px !important;
  }
}
