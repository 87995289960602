.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 70%;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  padding: 20px;
  z-index: 1000;
  overflow-y: auto;
}

.side-menu.visible {
  transform: translateX(0);
}

.side-menu a {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  color: #000;
  border-bottom: 0.1px solid rgba(200, 200, 200, 0.4);
}
