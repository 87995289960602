.heading {
  font-size: 40px;
  margin-right: 25px;
  margin: 0;
}

.fa {
  font-size: 25px;
}

.checked {
  color: orange;
}

/* Three column layout */
.side {
  float: left;
  width: 15%;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

.middle {
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
  width: 70%;
}

/* Place text to the right */
.right {
  text-align: center;
  font-size: 16px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* The bar container */
.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
}

/* Individual bars */
.bar-5 {
  height: 18px;
  background-color: #04aa6d;
}
.bar-4 {
  height: 18px;
  background-color: #2196f3;
}
.bar-3 {
  height: 18px;
  background-color: #00bcd4;
}
.bar-2 {
  height: 18px;
  background-color: #ff9800;
}
.bar-1 {
  height: 18px;
  background-color: #f44336;
}

.review-name {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.review-date {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.review-right {
  margin: 10px;
}

.review-left {
  margin: 10px;
  padding: 10px;
}

.review-container {
  background-color: #f9f9f9;
  margin-top: 10px;
  border-radius: 20px;
  align-items: center;
}

.review-content {
  font-size: 14px;
  padding-right: 10px;
}

.overall-ratings {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 20px;
}

/* Responsive layout - make the columns stack on top of each other instead of next to each other */
@media (max-width: 400px) {
  .side,
  .middle {
    width: 100%;
  }
  .right {
    display: none;
  }
}
