.main_navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
.navbar_links {
  text-align: center;
  font-size: 14px;
}
.navbar_links {
  padding: 10px;
}
.navbar_links .dropdown-menu {
  padding: 10px;
  left: 2%;
  cursor: pointer;
}
.navbar_links span {
  cursor: pointer;
}

.navbar_links .dropdown-menu .dropdown-item {
  font-size: 15px;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(76 95 115) !important;
  font-size: 15px;
  font-weight: 700;
}
.navbar {
  padding: 0 !important;
}
.category_nav {
  text-align: center;
  margin: 5px 0;
}
.badge-circle {
  right: -9px !important;
  height: 20px !important;
  width: 20px !important;
  padding: 1%;
  background: #379397 !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.cart-count {
  top: -13px !important;
}

.header-middle {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .header-middle {
    display: none;
  }

  .header-middle.navbar-visible {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

@media (width > 768px) {
  .titleNav {
    display: none;
  }
}

/* Announcement line */
.announcement-container {
  width: 100%;
  overflow: hidden;
  color: white;
  font-family: Arial, sans-serif;
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
}

.announcement-wrapper {
  display: inline-block;
  white-space: nowrap;
  animation: slide 25s linear infinite;
}

.announcement-text {
  font-size: 20px;
  display: inline-block;
  padding-right: 50%;
}

@keyframes slide {
  0% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(-150%);
  }
}
