.Search {
  position: relative;
}
.SearchBar {
  height: 100%;
}
.SearchBar .form {
  position: relative;
  border-radius: 15px;
  border: 2px solid #cccccc5c;
}

.SearchBar .form span {
  position: absolute;
  right: 17px;
  top: 10px;
  padding: 2px;
  padding-left: 5px;
  border-left: 1px solid #d1d5db;
}
.SearchBar .form .left-pan {
  padding-left: 7px;
}

.SearchBar .left-pan i {
  padding-left: 10px;
}

.SearchBar .form-input {
  /* text-indent: 33px; */
  border-radius: 10px;
  border: none !important;
  height: 47px;
  margin-bottom: 0rem;
  font-size: 16px;
}

.form-input:focus {
  box-shadow: none;
  border: none;
  outline: 0;
}

.Search_Result {
  position: absolute;
  width: 100%;
  margin-top: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  background-color: #fff;
  z-index: 99999999;
  display: none;
}

.Search_Result ul li {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
}
.Search_Result ul li:hover {
  background-color: #f3f4f7;
}
.toggle_list {
  display: block;
}

@media screen and (max-width: 640px) {
  .SearchBar .form-input {
    height: 30%;
  }
}
