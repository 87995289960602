.order-address-container {
  border: 1px solid rgba(48, 55, 61, 0.3);
  border-radius: 5px;
  text-align: start;
  padding: 10px;
  margin: 10px;
}

.details-container {
  background-color: #fff;
  display: flex;
  max-width: 80%;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  justify-content: center;
  margin-bottom: 20px;
}

.inner-container {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  gap: 9px;
  width: 100%;
  padding: 20px;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
}

.steps-container {
  align-self: center;
  display: flex;
  width: 400px;
  max-width: 100%;
  gap: 20px;
  justify-content: space-between;
}

.step {
  align-self: start;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #599b41;
  font-weight: 400;
  white-space: nowrap;
  flex: 1;
}

.img {
  object-fit: contain;
  object-position: center;
  width: 64px;
  height: 64px;
  padding: 10px;
  border-radius: 50%;
  align-self: center;
}

.step-title {
  color: #000;
  margin-bottom: 10px;
  font-size: 14px;
}

.delivery-container {
  display: flex;
  margin-top: 21px;
  gap: 16px;
  width: 100% !important;
  font-size: 14px;
  font-weight: 400;
}

.delivery-container div {
  width: 100% !important;
  align-items: center;
}

.delivery-date-container,
.delivered-container {
  display: flex;
  gap: 7px;
}

.delivery-title,
.delivered-title {
  color: #000;
  flex-grow: 1;
}

.delivery-date,
.delivered-name {
  color: #777;
  flex-grow: 1;
}

.order-product-container {
  align-self: start;
  display: flex;
  gap: 20px;
  font-size: 10px;
  color: #777;
  font-weight: 400;
  margin: 41px 0 0 10px;
}

.order-product-img {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100px;
  max-width: 100%;
  margin: auto 0;
  border-radius: 10px;
  border: 1px solid #dd9b41;
}

.order-product-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
}

.product-title {
  color: #000;
  height: auto;
  max-height: 100px;
  width: 100%;
  text-wrap: wrap;
  font: 500 16px "Montserrat", sans-serif;
}

.product-order-details p {
  color: #000;
  font-size: 14px;
  margin: 0 !important;
  padding: 0 !important;
}

.product-order-details h4 {
  color: #777;
  margin: 0 !important;
  padding: 0 !important;
}

.product-order-details div {
  display: flex;
  align-items: center;
}

.actions-container {
  align-self: end;
  display: flex;
  margin-top: 154px;
  flex-direction: column;
  font-size: 11px;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
}

.rate-button {
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  background-color: #599b41;
  color: #fff;
  justify-content: center;
  padding: 6px 24px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: ease-in-out 0.2s;
}
.rate-button:hover {
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  background-color: #fff;
  color: #599b41;
  border: 1px solid #599b41;
  justify-content: center;
  padding: 6px 24px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.invoice-button {
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  border: 1px solid #599b41;
  background-color: #fff;
  margin-top: 10px;
  color: #599b41;
  justify-content: center;
  padding: 6px 16px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}
.invoice-button:hover {
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #599b41;
  margin-top: 10px;
  color: #fff;
  justify-content: center;
  padding: 6px 16px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.return-cancel-button {
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  border: 1px solid #dd9b41;
  background-color: #fff;
  margin-top: 10px;
  color: #dd9b41;
  justify-content: center;
  padding: 6px 16px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}
.return-cancel-button:hover {
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #dd9b41;
  margin-top: 10px;
  color: #fff;
  justify-content: center;
  padding: 6px 16px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.bg-complete {
  background-color: #599b41;
}

.bg-error {
  background-color: #a20000;
}

.bg-not-complete {
  background-color: #777777;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  border-color: #777;
  color: #777;
}

@media (max-width: 991px) {
  .inner-container {
    flex-wrap: wrap;
    padding-right: 20px;
  }
  .content-container {
    max-width: 100%;
  }
  .step {
    white-space: initial;
  }
  .delivery-container {
    flex-wrap: wrap;
  }
  .order-product-container {
    margin-top: 40px;
  }
  .actions-container {
    margin-top: 40px;
    white-space: initial;
  }
  .rate-button {
    white-space: initial;
    padding: 10px 20px;
    margin-top: 10px;
  }
  .invoice-button {
    white-space: initial;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .details-container {
    max-width: 95%;
  }
  .steps-container {
    width: 100%;
  }
  .step {
    font-size: 10px;
  }
  .img {
    width: 50px;
    height: 50px;
  }
  .delivery-container {
    flex-direction: column;
    gap: 10px;
  }
  .delivery-title,
  .delivered-title {
    flex-grow: 0;
  }
  .delivery-date,
  .delivered-name {
    flex-grow: 0;
  }
  .order-product-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .order-product-img {
    width: 100px;
  }
  .actions-container {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .step-title {
    font-size: 12px;
  }
  .delivery-container {
    font-size: 12px;
  }
  .order-product-img {
    width: 100px;
  }
}
