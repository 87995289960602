/* Login Container */
.AuthContainer {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
}
.Auth {
  justify-content: space-around;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 1500px;
  margin: auto;
  height: 900px;
  padding: 0;
}

/* Login Image */
.AuthImg img {
  margin: auto;
  object-fit: cover;
  height: 100%;
  padding: 2%;
  border-radius: 32px;
}

.AuthForm {
  position: relative;
  padding: 10% !important;
}
/* Login Form */
.AuthForm,
.AuthImg {
  transition: transform 1.5s ease; /* Smooth transition */
}
.AuthForm .Logo {
  margin: auto;
  height: 150px;
  width: 150px;
  object-fit: contain;
}

/* Flipped Animation */
.flip-Img {
  transform: translateX(100%);
}

.flip-Form {
  transform: translateX(-100%);
}

/* Sign In Form */
.SignInForm {
  background-color: #fff;
  position: relative;
  padding: 10%;
  width: 100%;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 15px;
}
.SignInForm .Sign_header {
  display: flex;
  justify-content: space-between;
}
.SignInForm .Sign_header button {
  background-color: transparent;
  border: none;
  color: #4285f4;
  font-size: 14px;
  outline: 0;
  cursor: pointer;
}

.SignInForm label {
  padding-left: 20px;
  font-size: 2rem;
}

.SignInForm input {
  padding: 2rem;
  margin: 14px auto;
  font-size: 14px;
  font-family: "Poppins";
  border: 1px solid #ddd;
  background-color: #fff !important;
  border-radius: 1rem;
}
.SignInForm input:focus {
  background-color: #ffffff !important;
  outline: 0 !important;
  font-family: "Poppins";
  border: 1px solid #ddd;
}

.SignInForm .password_con {
  background-color: #ffffff;
  border-radius: 1rem;
  border: 1px solid #ddd;
}
.SignInForm .password_con input,
.SignInForm .password_con button {
  display: inline-block;
  background-color: #ffffff !important;
  margin: 10px auto;
  outline: 0 !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 1px;
}
.SignInForm .password_con input {
  padding: 1rem;
}
.SignInForm .password_con button svg {
  font-size: 20px;
  cursor: pointer;
}
.Login_buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  margin-top: 50px;
  height: 50px;
}
.confirm_signup {
  padding: 10px;
  border: 0;
  border-radius: 10px;
  font-size: 14px;
  background-color: #4285f4;
  color: #fff;
  outline: 0 !important;
}
.custom-google-login-button {
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
}

.custom-google-login-button:focus,
.custom-google-login-button:active {
  outline: none; /* Remove focus outline */
  box-shadow: none; /* Remove focus shadow */
  border: none; /* Remove any border on focus */
}

.GLogo,
.fb {
  height: 50px;
  width: auto;
}
.error-message,
.sucess-message {
  background-color: #ffc4c4;
  color: #b30707;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  width: 62%;
  margin: auto;
  font-family: "Poppins";
  padding: 10px;
  margin-top: 30px;
}
.sucess-message {
  background-color: #b6ffa3;
  color: #04a30c;
}
.custom-google-login-button {
  justify-content: space-around;
}

.Auth .links {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 5px;
  width: 60%;
}

/* Responsive Styling */
@media screen and (max-width: 1920px) {
  .Auth {
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0) 0px 4px 12px;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
  }
  .AuthForm {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0% 10% !important;
  }
  .AuthForm .Logo {
    margin: auto;
    height: 100px;
    width: 100px;
    object-fit: contain;
  }
  .SignInForm h1 {
    font-size: 14px;
  }
  .AuthImg {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .flip-Img {
    transform: translateX(100%);
  }

  .flip-Form {
    transform: translateX(-100%);
  }

  .error-message,
  .sucess-message {
    font-size: 11px;
    font-weight: 500;
    margin-top: 17px;
  }

  .conditions {
    font-size: 10px;
  }

  .SignInForm h2 {
    margin: 5px auto;
    margin-bottom: 30px;
    line-height: 1rem;
    font-size: 2rem;
  }
}
@media screen and (max-width: 640px) {
  .AuthForm {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 4% !important;
  }
  .AuthImg {
    display: none;
  }
  .AuthForm .Logo {
    margin: auto;
    height: 150px;
    width: 150px;
    object-fit: contain;
  }
  .SignInForm input {
    padding: 2rem;
    width: 80%;
    margin: 12px auto;
  }
  .SignInForm button {
    padding: 0.7rem;
    width: 25%;
    /* margin: 20px auto; */
    font-size: 1.5rem;
    font-family: "Poppins";
    line-height: 1.5;
  }
  .flip-Form {
    transform: translateX(0%);
  }
  .iconList li {
    width: 10%;
    margin: 5px;
  }
}
