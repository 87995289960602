.order-table-container .order_card {
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
}
.order_card img {
  width: 75px;
  height: 75px;
  border: 0.5px solid #ccc;
  border-radius: 3px;
  margin: 3px;
  object-fit: contain;
}
.page-header {
  margin-top: 10%;
}
