.Adresses > div {
  padding: 0 !important;
  justify-content: unset;
}
.address-card {
  border: 1px solid #ddd;
  padding: 10px !important;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.active-address-card {
  border: 2px solid #379397;
  border-radius: 5px;
}

.font-14 {
  font-size: 14px;
}
.iban-info {
  text-align: start;
}

.payment-method-label {
  font-weight: normal;
  font-size: 14px;
  padding: 10px 30px;
  color: #000;
}

.payment-method-button {
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
  height: 200px;
}
