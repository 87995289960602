.footer-middle {
  margin: 2rem auto;
  padding: 4rem;
  border-radius: 20px;
  background: #fafafa;
}
.footer-middle h4 {
  color: #000 !important;
  text-transform: math-auto !important;
  font-weight: 500 !important;
}
.footer-middle li,
.footer-middle a {
  font-size: 14px;
  color: #777;
  text-decoration: none;
}
.footer .social-icons {
  padding-top: 11px;
}
.footer-bottom {
  color: #777;
  background: #fff;
  padding: 2rem 9rem;
  font-size: 15px;
}
.footer .social-icon:not(:hover):not(:active):not(:focus) {
  background-color: #37939700 !important;
  border-color: #379397 !important;
}
.contact-widget a {
  text-decoration: none !important;
  color: rgb(129 129 129) !important;
}

@media screen and (max-width: 640px) {
  .footer .social-icons {
    margin-bottom: 20px;
  }
}

.accordion-body p {
  font-size: 16px;
  text-align: start;
  color: #777 !important;
}
