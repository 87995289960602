@media screen and (max-width: 640px) {
  .product-default {
    height: 300px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .product-figure {
    height: 40% !important;
  }

  .product-details a {
    font-size: 12px !important;
  }

  .product-details span {
    font-size: 18px !important;
  }

  .product-category {
    font-size: 12px;
  }
}
