.section-sub-title,
.section-title {
  font-family: "Raleway", sans-serif !important;
}

/* HomeCategory */
.Home-Category {
  height: 700px;
  margin: 50px auto;
}
.Home-Category-mobile {
  height: auto;
  margin: 50px auto;
}
.title {
  font-size: 30px;
  margin: 30px 0 !important;
  color: black;
  font-weight: 300;
}
.Home-Category .Categories {
  height: 100%;
}
.Home-Category .Categories .main_cat {
  border-radius: 20px;
  height: 100%;
}
.Home-Category .Categories .main_cat .Cat_img {
  height: 70%;
  margin: 5px;
  border-radius: 20px;
  background-color: #f5f5f6;
  display: flex;
}
.Home-Category .Categories .main_cat .Cat_info {
  height: 20%;
  margin: 5px;
  display: flex;
  border-radius: 20px;
  background-color: #f5f5f6;
}
.Home-Category .Categories .main_cat .Cat_info h6 {
  font-size: 30px !important;
  text-align: center;
  margin: auto;
}
.Home-Category .Categories .main_cat .Cat_img img {
  height: 80%;
  width: 80%;
  margin: auto;
  object-fit: contain;
}
.Home-Category .sub_cats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.Home-Category .sub_cats .sub_cat {
  width: 30%;
  margin: 5px;
  height: 41%;
  padding: 12px;
  margin: 5px;
  text-align: center;
  background-color: #f5f5f6;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  cursor: pointer;
}
.Home-Category .sub_cats .sub_cat img {
  margin: auto;
  height: 50%;
  object-fit: contain;
}
.Home-Category .categries .sub_cat h4 {
  font-size: 13px;
}
.Home-Category .categries .sub_cat img:hover {
  transform: scale(1.3);
  transition: all 0.5s;
}
.home-Categories nav ul li {
  list-style: none;
  display: inline-block;
  padding-right: 65px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
}

.ProductsNewArrivals,
.TopSellingProducts,
.Brands {
  margin: 50px auto;
}
/* Product Card */
.product-default {
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px; */
  border: 1px solid #77777738;
  border-radius: 10px;
  width: 93%;
  height: 450px;
  padding: 0 !important;
  margin: auto;
}
.product-default:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px !important;
}
.product-default figure {
  width: auto;
  height: 50%;
  padding: 15px !important;
}

.product-default figure > a:first-child {
  width: 100%;
  height: 100%;
}

.product-default figure a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.product-details {
  padding: 10px !important;
}
.product-title {
  width: 90% !important;
  display: block;
  line-height: 20px !important;
}
.product-default .product-title a {
  font-size: 15px;
  white-space: break-spaces;
  color: #000000;
  text-decoration: none;
}
.category-list a {
  font-size: 14px;
}
.product-price {
  color: #fe9900 !important;
  font-size: 27px !important;
}
.product-single-details .product-single-qty .form-control {
  height: 48px;
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 0 !important;
}

.product-details h3 {
  margin-top: 10px !important;
}
.product-details .product-action {
  display: flex;
  justify-content: space-around;
}
.product-default .btn-add-cart {
  background-color: rgb(32 170 175 / 27%) !important;
  color: #03338d !important;
  border-radius: 30px;
  margin: 0 20px;
  border: 0 !important;
  padding: 0 30px !important;
  line-height: 50px !important;
}

.product-default .cart,
.product-default .wish-list-icon {
  position: relative;
  background-color: rgb(32 170 175 / 27%) !important;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.product-default .cart i,
.product-default .wish-list-icon i {
  position: absolute;
  top: 50%;
  color: rgb(6, 92, 141);
  left: 50%;
  transform: translate(-50%, -50%);
}
.inner-icon figure .btn-icon {
  opacity: 1;
  visibility: visible;
}
.inner-icon figure .btn-icon:hover {
  background-color: #379397;
  color: #fff;
  border: 0;
  cursor: pointer;
}
