@charset "UTF-8";
.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mt-6 {
  margin-top: 6rem !important;
}

.mt-7 {
  margin-top: 7rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}

.mt-9 {
  margin-top: 9rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.mb-9 {
  margin-bottom: 9rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0rem !important;
  }

  .mt-sm-1 {
    margin-top: 1rem !important;
  }

  .mt-sm-2 {
    margin-top: 2rem !important;
  }

  .mt-sm-3 {
    margin-top: 3rem !important;
  }

  .mt-sm-4 {
    margin-top: 4rem !important;
  }

  .mt-sm-5 {
    margin-top: 5rem !important;
  }

  .mt-sm-6 {
    margin-top: 6rem !important;
  }

  .mt-sm-7 {
    margin-top: 7rem !important;
  }

  .mt-sm-8 {
    margin-top: 8rem !important;
  }

  .mt-sm-9 {
    margin-top: 9rem !important;
  }

  .mt-sm-10 {
    margin-top: 10rem !important;
  }

  .mb-sm-0 {
    margin-bottom: 0rem !important;
  }

  .mb-sm-1 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 7rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 9rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 10rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0rem !important;
  }

  .mt-md-1 {
    margin-top: 1rem !important;
  }

  .mt-md-2 {
    margin-top: 2rem !important;
  }

  .mt-md-3 {
    margin-top: 3rem !important;
  }

  .mt-md-4 {
    margin-top: 4rem !important;
  }

  .mt-md-5 {
    margin-top: 5rem !important;
  }

  .mt-md-6 {
    margin-top: 6rem !important;
  }

  .mt-md-7 {
    margin-top: 7rem !important;
  }

  .mt-md-8 {
    margin-top: 8rem !important;
  }

  .mt-md-9 {
    margin-top: 9rem !important;
  }

  .mt-md-10 {
    margin-top: 10rem !important;
  }

  .mb-md-0 {
    margin-bottom: 0rem !important;
  }

  .mb-md-1 {
    margin-bottom: 1rem !important;
  }

  .mb-md-2 {
    margin-bottom: 2rem !important;
  }

  .mb-md-3 {
    margin-bottom: 3rem !important;
  }

  .mb-md-4 {
    margin-bottom: 4rem !important;
  }

  .mb-md-5 {
    margin-bottom: 5rem !important;
  }

  .mb-md-6 {
    margin-bottom: 6rem !important;
  }

  .mb-md-7 {
    margin-bottom: 7rem !important;
  }

  .mb-md-8 {
    margin-bottom: 8rem !important;
  }

  .mb-md-9 {
    margin-bottom: 9rem !important;
  }

  .mb-md-10 {
    margin-bottom: 10rem !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0rem !important;
  }

  .mt-lg-1 {
    margin-top: 1rem !important;
  }

  .mt-lg-2 {
    margin-top: 2rem !important;
  }

  .mt-lg-3 {
    margin-top: 3rem !important;
  }

  .mt-lg-4 {
    margin-top: 4rem !important;
  }

  .mt-lg-5 {
    margin-top: 5rem !important;
  }

  .mt-lg-6 {
    margin-top: 6rem !important;
  }

  .mt-lg-7 {
    margin-top: 7rem !important;
  }

  .mt-lg-8 {
    margin-top: 8rem !important;
  }

  .mt-lg-9 {
    margin-top: 9rem !important;
  }

  .mt-lg-10 {
    margin-top: 10rem !important;
  }

  .mb-lg-0 {
    margin-bottom: 0rem !important;
  }

  .mb-lg-1 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 7rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 9rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 10rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-0 {
    margin-top: 0rem !important;
  }

  .mt-xl-1 {
    margin-top: 1rem !important;
  }

  .mt-xl-2 {
    margin-top: 2rem !important;
  }

  .mt-xl-3 {
    margin-top: 3rem !important;
  }

  .mt-xl-4 {
    margin-top: 4rem !important;
  }

  .mt-xl-5 {
    margin-top: 5rem !important;
  }

  .mt-xl-6 {
    margin-top: 6rem !important;
  }

  .mt-xl-7 {
    margin-top: 7rem !important;
  }

  .mt-xl-8 {
    margin-top: 8rem !important;
  }

  .mt-xl-9 {
    margin-top: 9rem !important;
  }

  .mt-xl-10 {
    margin-top: 10rem !important;
  }

  .mb-xl-0 {
    margin-bottom: 0rem !important;
  }

  .mb-xl-1 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 7rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 9rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 10rem !important;
  }
}
@media (min-width: 1600px) {
  .mt-xxl-0 {
    margin-top: 0rem !important;
  }

  .mt-xxl-1 {
    margin-top: 1rem !important;
  }

  .mt-xxl-2 {
    margin-top: 2rem !important;
  }

  .mt-xxl-3 {
    margin-top: 3rem !important;
  }

  .mt-xxl-4 {
    margin-top: 4rem !important;
  }

  .mt-xxl-5 {
    margin-top: 5rem !important;
  }

  .mt-xxl-6 {
    margin-top: 6rem !important;
  }

  .mt-xxl-7 {
    margin-top: 7rem !important;
  }

  .mt-xxl-8 {
    margin-top: 8rem !important;
  }

  .mt-xxl-9 {
    margin-top: 9rem !important;
  }

  .mt-xxl-10 {
    margin-top: 10rem !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0rem !important;
  }

  .mb-xxl-1 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 7rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 9rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 10rem !important;
  }
}
.m-b-1 {
  margin-bottom: 0.4rem !important;
}

.m-b-2 {
  margin-bottom: 0.8rem !important;
}

.m-b-3 {
  margin-bottom: 1.6rem !important;
}

.m-b-4 {
  margin-bottom: 2.4rem !important;
}

.m-b-5 {
  margin-bottom: 4.8rem !important;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: 0;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url("../images/owl.video.play.png") no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  color: #222529;
  font-size: 3rem;
}
.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #222529;
  margin: 0;
  font-size: 3rem;
  padding: 4px 7px;
  background: transparent;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*="owl-"] i:before {
  margin: 0;
  width: auto;
}
.owl-theme .owl-nav [class*="owl-"]:not(.disabled):hover {
  background: transparent;
  color: #222529;
  text-decoration: none;
}
.owl-theme .owl-nav .owl-prev {
  left: 0;
}
.owl-theme .owl-nav .owl-next {
  right: 0;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 2.5rem;
}
.owl-theme .owl-dots {
  line-height: 1;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  position: relative;
  width: 15px;
  height: 15px;
  margin: 2px 2px;
  background: transparent;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all 0.3s ease;
  border: 2px solid rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}
.owl-theme .owl-dots .owl-dot span:before {
  content: "";
  display: block;
  width: 0.7rem;
  height: 0.7rem;
  background-color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -0.35rem;
  margin-left: -0.35rem;
  background-color: #08c;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all 0.3s;
  border-radius: 50%;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  border-color: #08c;
  background: transparent;
}
.owl-theme .owl-dots .owl-dot.active span:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.owl-theme.owl-theme-light .owl-dots .owl-dot span {
  border-color: #fff;
  background: transparent;
}
.owl-theme.owl-theme-light .owl-dots .owl-dot span:before {
  border-color: #fff;
}
.owl-theme.owl-theme-light .owl-dots .owl-dot.active span,
.owl-theme.owl-theme-light .owl-dots .owl-dot:hover span {
  border-color: #08c;
  background: transparent;
}
.owl-theme.owl-theme-light .owl-dots .owl-dot.active span:before {
  border-color: #08c;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #fff;
  opacity: 0.4;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #ccc;
}
.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    transform-origin: 0;
  }

  .mfp-arrow-right {
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/*
 *  Bootstrap TouchSpin - v4.2.5
 *  A mobile and touch friendly input spinner component for Bootstrap 3 & 4.
 *  http://www.virtuosoft.eu/code/bootstrap-touchspin/
 *
 *  Made by István Ujj-Mészáros
 *  Under Apache License v2.0 License
 */
/* This CSS file is unnecessary if you are not using vertical buttons functionality */
.bootstrap-touchspin .input-group-btn-vertical {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 11;
}

.bootstrap-touchspin.input-group {
  max-width: 68px;
  padding-right: 20px;
  margin-bottom: 0;
}

.bootstrap-touchspin .form-control {
  text-align: center;
  margin-bottom: 0;
  height: 4.2rem;
  max-width: 46px;
  padding: 1.1rem 1rem;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  position: absolute;
  right: 0;
  height: 2rem;
  padding: 0;
  width: 2rem;
  text-align: center;
  font-size: 1.2rem;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn:before {
  position: relative;
  margin: 0;
  width: auto;
  line-height: 1;
  width: auto;
  top: -0.1rem;
  margin-right: -0.2rem;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
  top: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0;
  bottom: 0;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 0.3rem;
}

.noUi-horizontal .noUi-handle {
  width: 1.1rem;
  height: 1.1rem;
  left: -0.55rem;
  top: -0.3em;
}

.noUi-vertical {
  width: 0.3rem;
  height: 150px;
}

.noUi-vertical .noUi-handle {
  width: 1.1rem;
  height: 1.1rem;
  left: -0.4rem;
  top: -0.5rem;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -0.55rem;
  left: auto;
}

.noUi-target {
  background: #dfdfdf;
  border-radius: 0;
}

.noUi-connects {
  border-radius: 0;
}

.noUi-connect {
  background: #000;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border-radius: 50%;
  background: #000;
  cursor: pointer;
}

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

.noUi-pips,
.noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  position: absolute;
  background: #ccc;
}

.noUi-marker-sub {
  background: #aaa;
}

.noUi-marker-large {
  background: #aaa;
}

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.alert {
  margin-bottom: 2rem;
  padding: 1.4rem 1.5rem;
  border-radius: 0;
}
.alert.alert-intro {
  font-size: 1.5rem;
}

@keyframes maskUp {
  from {
    transform: translate(0, 100%);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes maskRight {
  from {
    transform: translate(-100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes maskDown {
  from {
    transform: translate(0, -100%);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes maskLeft {
  from {
    transform: translate(100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
.maskUp {
  animation-name: maskUp;
}

.maskRight {
  animation-name: maskRight;
}

.maskDown {
  animation-name: maskDown;
}

.maskLeft {
  animation-name: maskLeft;
}

@keyframes fadeInUpShorter {
  from {
    opacity: 0;
    transform: translate(0, 50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInUpShorter {
  animation-timing-function: ease-out;
  animation-name: fadeInUpShorter;
}

@keyframes fadeInLeftShorter {
  from {
    opacity: 0;
    transform: translate(50px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeftShorter {
  animation-timing-function: ease-out;
  animation-name: fadeInLeftShorter;
}

@keyframes fadeInRightShorter {
  from {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeInRightShorter {
  animation-timing-function: ease-out;
  animation-name: fadeInRightShorter;
}

@keyframes fadeInDownShorter {
  from {
    opacity: 0;
    transform: translate(0, -50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInDownShorter {
  animation-name: fadeInDownShorter;
}

@keyframes blurIn {
  from {
    opacity: 0;
    filter: blur(20px);
    transform: scale(1.3);
  }
  to {
    opacity: 1;
    filter: blur(0);
    transform: none;
  }
}
.blurIn {
  animation-name: blurIn;
}

@keyframes dotPulse {
  from {
    opacity: 1;
    transform: scale(0.2);
  }
  to {
    opacity: 0;
    transform: scale(1);
  }
}
.dotPulse {
  animation-name: dotPulse;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}

@keyframes slideInUp {
  0% {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes slideInDown {
  0% {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes flipInX {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: perspective(400px) rotateY(-90deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes brightIn {
  0% {
    animation-timing-function: ease-in;
    filter: brightness(0%);
  }
  to {
    filter: brightness(100%);
  }
}
@keyframes bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: translateZ(0);
  }
}
.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    transform: rotate(90deg);
    transform-origin: left bottom;
  }
  to {
    opacity: 1;
    transform: translateZ(0);
    transform-origin: left bottom;
  }
}
.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

.brightIn {
  animation-name: brightIn;
}

@keyframes customSVGLineAnimTwo {
  from {
    stroke-dasharray: 820;
    stroke-dashoffset: 500;
  }
  to {
    stroke-dasharray: 1120;
    stroke-dashoffset: 500;
  }
}
.customSVGLineAnimTwo {
  animation-name: customSVGLineAnimTwo;
}

.appear-animate {
  opacity: 0;
}

.appear-animation-visible {
  opacity: 1;
}

.banner {
  position: relative;
  font-size: 1.6rem;
}
.banner figure {
  margin: 0;
}
.banner img {
  width: 100%;
  object-fit: cover;
}
.banner h1,
.banner h2,
.banner h3,
.banner h4,
.banner h5,
.banner h6 {
  line-height: 1;
}
.banner .container:not(.banner-content) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ibanner {
  font-size: 10px;
}

aside .banner {
  font-size: 1.6rem;
}

.banner-layer {
  position: absolute;
  left: 6%;
  right: 6%;
}

.banner-content {
  position: relative;
}

.banner-layer-top {
  top: 7%;
}

.banner-layer-bottom {
  bottom: 5%;
}

.banner-layer-space {
  left: 0;
  right: 0;
  padding: 1em 1.5625em;
}

.banner-layer-left {
  right: auto;
}

.banner-layer-right {
  left: auto;
}

.banner-layer-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.banner-layer-middle {
  top: 50%;
  transform: translateY(-50%);
}

.bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-h-100 {
  height: 100vh;
}

@media (max-width: 1199px) {
  .banner {
    font-size: 1.4rem;
  }

  .ibanner {
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .banner-md-vw-small {
    font-size: 1.8vw;
  }

  .banner-md-vw {
    font-size: 2.3vw;
  }

  .banner-md-vw-large {
    font-size: 3vw;
  }
}
@media (max-width: 575px) {
  .banner-md-vw-small {
    font-size: 2.3vw;
  }

  .banner-sm-vw {
    font-size: 1.2rem;
  }

  .banner-sm-vw-large {
    font-size: 4vw;
  }
}
.breadcrumb-nav {
  color: #8e8e8e;
  border-bottom: 0;
}

.breadcrumb {
  margin-bottom: 0;
  padding: 1rem 0;
  border-radius: 0;
  background-color: transparent;
}

.breadcrumb-item {
  font-size: 12px;
  letter-spacing: -0.01em;
  line-height: 24px;
  text-transform: uppercase;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 1.5rem;
}
.breadcrumb-item + .breadcrumb-item:before {
  color: inherit;
  padding-right: 1.1rem;
  content: "";
  font-size: 12px;
  font-family: "porto";
  vertical-align: middle;
  margin-top: -2px;
}
.breadcrumb-item a:not(:first-child) {
  margin-left: 5px;
}
.breadcrumb-item a,
.breadcrumb-item.active {
  color: inherit;
}

.btn {
  transition: all 0.3s;
  text-transform: uppercase;
  padding: 1.9rem 4.8rem;
  border-radius: 0;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  line-height: 1.429;
}
.btn:focus {
  box-shadow: none;
}

a.btn.disabled {
  pointer-events: auto;
  cursor: not-allowed;
}

.btn-sm {
  padding: 1em 1.6em;
  font-size: 1.2rem;
}

.btn-md {
  padding: 1em 2.2em;
  font-size: 1.3rem;
}

.btn-lg {
  padding: 1em 2.15em;
  font-size: 1.4rem;
}

.btn-xl {
  padding: 1.125em 2.75em;
  font-size: 1.125em;
}

.btn-icon-left i {
  padding-right: 0.7rem;
}

.btn-icon-right i {
  padding-left: 0.7rem;
}

.btn-primary {
  border-color: #08c;
  background-color: #08c;
  color: #fff;
  box-shadow: none;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
  border-color: #00a3f5;
  background-color: #00a3f5;
  color: #fff;
  box-shadow: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  border-color: #08c;
  background-color: #08c;
  color: #fff;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  border-color: #005e8c;
  background-color: #006699;
  color: #fff;
}

.btn-secondary {
  border-color: #ff7272;
  background-color: #ff7272;
  color: #fff;
  box-shadow: none;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus {
  border-color: #ff9b9b;
  background-color: #ff9b9b;
  color: #fff;
  box-shadow: none;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  border-color: #ff7272;
  background-color: #ff7272;
  color: #fff;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  border-color: #ff3232;
  background-color: #ff3f3f;
  color: #fff;
}

.btn-outline {
  border-color: #ccc;
  background-color: transparent;
  background-image: none;
  color: #ccc;
}
.btn-outline:hover,
.btn-outline.focus {
  border-color: #08c;
  background-color: transparent;
  color: #08c;
  box-shadow: none;
}
.btn-outline.disabled,
.btn-outline:disabled {
  background-color: transparent;
  color: #ccc;
}
.btn-outline:not(:disabled):not(.disabled):active,
.btn-outline:not(:disabled):not(.disabled).active,
.show > .btn-outline.dropdown-toggle {
  border-color: #08c;
  background-color: transparent;
  color: #08c;
}

.btn-outline-secondary {
  border-color: #ccc;
  background-color: transparent;
  background-image: none;
  color: #777;
}
.btn-outline-secondary:hover,
.btn-outline-secondary.focus {
  border-color: #08c;
  background-color: #08c;
  color: #fff;
  box-shadow: none;
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  background-color: transparent;
  color: #777;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  border-color: #08c;
  background-color: #08c;
  color: #fff;
}

.btn-dark {
  border-color: #222529;
  background-color: #222529;
  color: #fff;
  box-shadow: none;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark.focus {
  border-color: #34393f;
  background-color: #34393f;
  color: #fff;
  box-shadow: none;
}
.btn-dark.disabled,
.btn-dark:disabled {
  border-color: #222529;
  background-color: #222529;
  color: #fff;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  border-color: #050606;
  background-color: #0b0c0d;
  color: #fff;
}

.btn-gray {
  border-color: #f4f4f4;
  background-color: #f4f4f4;
  color: #222529;
  box-shadow: none;
}
.btn-gray:hover,
.btn-gray:focus,
.btn-gray.focus {
  border-color: #08c;
  background-color: #08c;
  color: #fff;
  box-shadow: none;
}
.btn-gray.disabled,
.btn-gray:disabled {
  border-color: #f4f4f4;
  background-color: #f4f4f4;
  color: #222529;
}
.btn-gray:not(:disabled):not(.disabled):active,
.btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  border-color: #d4d4d4;
  background-color: #dbdbdb;
  color: #222529;
}

.btn-link {
  padding-top: 0;
  padding-bottom: 0;
  color: #08c;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  text-transform: initial;
}
.btn-link:hover,
.btn-link:focus {
  color: #08c;
  text-decoration: underline;
}

.btn-social-login {
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #08c;
  font-family: Poppins, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
}
.btn-social-login span,
.btn-social-login i {
  color: #fff;
}
.btn-social-login i {
  margin-top: -1px;
  padding-right: 6px;
}

.btn-facebook {
  background: #3a589d;
}

.btn-gplus {
  background: #dd4e31;
}

.btn-twitter {
  background: #131516;
}

.owl-dots .owl-dot,
.owl-nav .owl-prev,
.owl-nav .owl-next {
  outline: none;
}

a:focus {
  outline: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.mfp-container .mfp-preloader,
.porto-loading-icon {
  content: "";
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: #08c;
  border-image: none;
  text-indent: -9999px;
  animation: spin 0.75s infinite linear;
  z-index: 3;
}

.mfp-container .mfp-preloader:before,
.porto-loading-icon:before {
  position: absolute;
  top: -2px;
  right: 0;
  left: -2px;
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
  border-top-color: inherit;
  content: "";
  animation: spin 1.5s infinite ease;
}

.card {
  margin-bottom: 3rem;
  border: 1px solid #ddd;
  border-radius: 0;
  font-size: 1.4rem;
}

.card-header {
  margin: 0;
  padding: 1.2rem 1.5rem;
  border-radius: 0;
  border-color: #ddd;
  background-color: #f5f5f5;
  color: #000;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
}
.card-header:after {
  display: block;
  clear: both;
  content: "";
}
.card-header .card-edit {
  margin-top: 0.1rem;
  float: right;
  color: #08c;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: capitalize;
}

.card-body {
  min-height: 135px;
  padding: 2rem 1.5rem;
  border-top: 0;
  border-radius: 0;
}
.card-body a {
  text-decoration: underline;
}
.card-body h4 {
  margin-bottom: 0.7rem;
  color: #666;
}

.card.card-accordion {
  margin-bottom: 0.5rem;
  padding: 0;
  border: 0;
}
.card.card-accordion .card-header {
  cursor: pointer;
  position: relative;
  padding-left: 0;
  padding-right: 25px;
  background: transparent;
  font-family: Poppins, sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
}
.card.card-accordion .card-header:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-family: "porto";
  transition: 0.35s;
}
.card.card-accordion .card-header.collapsed:after {
  content: "";
  transition: 0.35s;
}
.card.card-accordion .card-body {
  min-height: 0;
  padding: 0;
}
.card.card-accordion p {
  margin-bottom: 0.4rem;
  padding: 1.3rem 0;
  letter-spacing: 0;
}

.count-container {
  margin-bottom: 4rem;
}

.count-wrapper {
  margin-bottom: 0.9rem;
  color: #222529;
  text-transform: uppercase;
}

.count-title {
  margin-bottom: 0;
  color: #777;
}

@media (min-width: 992px) {
  .count-wrapper {
    font-size: 3.6rem;
  }
}
.feature-box {
  color: #7b858a;
  font-size: 1.5rem;
  line-height: 2;
  margin-bottom: 4rem;
}
.feature-box i {
  display: inline-block;
  margin-bottom: 2.2rem;
  color: #08c;
  font-size: 5rem;
  line-height: 1;
}
.feature-box i:before {
  margin: 0;
}
.feature-box h3 {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: 0;
}
.feature-box p {
  margin-bottom: 0;
}
.feature-box.border-top-primary {
  border-bottom: 1px solid #dfdfdf;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.feature-box.border-top-primary .feature-box-content {
  border-top: 4px solid #08c;
  padding: 30px 20px 10px 20px;
}

.feature-box-content {
  color: #7b858a;
  font-size: 1.5rem;
  line-height: 1.9;
  padding-left: 10px;
  padding-right: 10px;
}

.feature-rounded i {
  width: 80px;
  height: 80px;
  border-radius: 500px;
  font-size: 35px;
  line-height: 80px;
}

.flag {
  display: inline-block;
  width: 16px;
  height: 11px;
  margin-top: -1px;
  margin-right: 6px;
  margin-left: 1px;
  vertical-align: middle;
  line-height: 0;
}
.flag.flag-ad {
  background-position: -16px 0;
}
.flag.flag-ae {
  background-position: -32px 0;
}
.flag.flag-af {
  background-position: -48px 0;
}
.flag.flag-ag {
  background-position: -64px 0;
}
.flag.flag-ai {
  background-position: -80px 0;
}
.flag.flag-al {
  background-position: -96px 0;
}
.flag.flag-am {
  background-position: -112px 0;
}
.flag.flag-an {
  background-position: -128px 0;
}
.flag.flag-ao {
  background-position: -144px 0;
}
.flag.flag-ar {
  background-position: -160px 0;
}
.flag.flag-as {
  background-position: -176px 0;
}
.flag.flag-at {
  background-position: -192px 0;
}
.flag.flag-au {
  background-position: -208px 0;
}
.flag.flag-aw {
  background-position: -224px 0;
}
.flag.flag-az {
  background-position: -240px 0;
}
.flag.flag-ba {
  background-position: 0 -11px;
}
.flag.flag-bb {
  background-position: -16px -11px;
}
.flag.flag-bd {
  background-position: -32px -11px;
}
.flag.flag-be {
  background-position: -48px -11px;
}
.flag.flag-bf {
  background-position: -64px -11px;
}
.flag.flag-bg {
  background-position: -80px -11px;
}
.flag.flag-bh {
  background-position: -96px -11px;
}
.flag.flag-bi {
  background-position: -112px -11px;
}
.flag.flag-bj {
  background-position: -128px -11px;
}
.flag.flag-bm {
  background-position: -144px -11px;
}
.flag.flag-bn {
  background-position: -160px -11px;
}
.flag.flag-bo {
  background-position: -176px -11px;
}
.flag.flag-br {
  background-position: -192px -11px;
}
.flag.flag-bs {
  background-position: -208px -11px;
}
.flag.flag-bt {
  background-position: -224px -11px;
}
.flag.flag-bv {
  background-position: -240px -11px;
}
.flag.flag-bw {
  background-position: 0 -22px;
}
.flag.flag-by {
  background-position: -16px -22px;
}
.flag.flag-bz {
  background-position: -32px -22px;
}
.flag.flag-ca {
  background-position: -48px -22px;
}
.flag.flag-catalonia {
  background-position: -64px -22px;
}
.flag.flag-cd {
  background-position: -80px -22px;
}
.flag.flag-cf {
  background-position: -96px -22px;
}
.flag.flag-cg {
  background-position: -112px -22px;
}
.flag.flag-ch {
  background-position: -128px -22px;
}
.flag.flag-ci {
  background-position: -144px -22px;
}
.flag.flag-ck {
  background-position: -160px -22px;
}
.flag.flag-cl {
  background-position: -176px -22px;
}
.flag.flag-cm {
  background-position: -192px -22px;
}
.flag.flag-cn {
  background-position: -208px -22px;
}
.flag.flag-co {
  background-position: -224px -22px;
}
.flag.flag-cr {
  background-position: -240px -22px;
}
.flag.flag-cu {
  background-position: 0 -33px;
}
.flag.flag-cv {
  background-position: -16px -33px;
}
.flag.flag-cw {
  background-position: -32px -33px;
}
.flag.flag-cy {
  background-position: -48px -33px;
}
.flag.flag-cz {
  background-position: -64px -33px;
}
.flag.flag-de {
  background-position: -80px -33px;
}
.flag.flag-dj {
  background-position: -96px -33px;
}
.flag.flag-dk {
  background-position: -112px -33px;
}
.flag.flag-dm {
  background-position: -128px -33px;
}
.flag.flag-do {
  background-position: -144px -33px;
}
.flag.flag-dz {
  background-position: -160px -33px;
}
.flag.flag-ec {
  background-position: -176px -33px;
}
.flag.flag-ee {
  background-position: -192px -33px;
}
.flag.flag-eg {
  background-position: -208px -33px;
}
.flag.flag-eh {
  background-position: -224px -33px;
}
.flag.flag-england {
  background-position: -240px -33px;
}
.flag.flag-er {
  background-position: 0 -44px;
}
.flag.flag-es {
  background-position: -16px -44px;
}
.flag.flag-et {
  background-position: -32px -44px;
}
.flag.flag-eu {
  background-position: -48px -44px;
}
.flag.flag-fi {
  background-position: -64px -44px;
}
.flag.flag-fj {
  background-position: -80px -44px;
}
.flag.flag-fk {
  background-position: -96px -44px;
}
.flag.flag-fm {
  background-position: -112px -44px;
}
.flag.flag-fo {
  background-position: -128px -44px;
}
.flag.flag-fr {
  background-position: -144px -44px;
}
.flag.flag-ga {
  background-position: -160px -44px;
}
.flag.flag-gb {
  background-position: -176px -44px;
}
.flag.flag-gd {
  background-position: -192px -44px;
}
.flag.flag-ge {
  background-position: -208px -44px;
}
.flag.flag-gf {
  background-position: -224px -44px;
}
.flag.flag-gg {
  background-position: -240px -44px;
}
.flag.flag-gh {
  background-position: 0 -55px;
}
.flag.flag-gi {
  background-position: -16px -55px;
}
.flag.flag-gl {
  background-position: -32px -55px;
}
.flag.flag-gm {
  background-position: -48px -55px;
}
.flag.flag-gn {
  background-position: -64px -55px;
}
.flag.flag-gp {
  background-position: -80px -55px;
}
.flag.flag-gq {
  background-position: -96px -55px;
}
.flag.flag-gr {
  background-position: -112px -55px;
}
.flag.flag-gs {
  background-position: -128px -55px;
}
.flag.flag-gt {
  background-position: -144px -55px;
}
.flag.flag-gu {
  background-position: -160px -55px;
}
.flag.flag-gw {
  background-position: -176px -55px;
}
.flag.flag-gy {
  background-position: -192px -55px;
}
.flag.flag-hk {
  background-position: -208px -55px;
}
.flag.flag-hm {
  background-position: -224px -55px;
}
.flag.flag-hn {
  background-position: -240px -55px;
}
.flag.flag-hr {
  background-position: 0 -66px;
}
.flag.flag-ht {
  background-position: -16px -66px;
}
.flag.flag-hu {
  background-position: -32px -66px;
}
.flag.flag-ic {
  background-position: -48px -66px;
}
.flag.flag-id {
  background-position: -64px -66px;
}
.flag.flag-ie {
  background-position: -80px -66px;
}
.flag.flag-il {
  background-position: -96px -66px;
}
.flag.flag-im {
  background-position: -112px -66px;
}
.flag.flag-in {
  background-position: -128px -66px;
}
.flag.flag-io {
  background-position: -144px -66px;
}
.flag.flag-iq {
  background-position: -160px -66px;
}
.flag.flag-ir {
  background-position: -176px -66px;
}
.flag.flag-is {
  background-position: -192px -66px;
}
.flag.flag-it {
  background-position: -208px -66px;
}
.flag.flag-je {
  background-position: -224px -66px;
}
.flag.flag-jm {
  background-position: -240px -66px;
}
.flag.flag-jo {
  background-position: 0 -77px;
}
.flag.flag-jp {
  background-position: -16px -77px;
}
.flag.flag-ke {
  background-position: -32px -77px;
}
.flag.flag-kg {
  background-position: -48px -77px;
}
.flag.flag-kh {
  background-position: -64px -77px;
}
.flag.flag-ki {
  background-position: -80px -77px;
}
.flag.flag-km {
  background-position: -96px -77px;
}
.flag.flag-kn {
  background-position: -112px -77px;
}
.flag.flag-kp {
  background-position: -128px -77px;
}
.flag.flag-kr {
  background-position: -144px -77px;
}
.flag.flag-kurdistan {
  background-position: -160px -77px;
}
.flag.flag-kw {
  background-position: -176px -77px;
}
.flag.flag-ky {
  background-position: -192px -77px;
}
.flag.flag-kz {
  background-position: -208px -77px;
}
.flag.flag-la {
  background-position: -224px -77px;
}
.flag.flag-lb {
  background-position: -240px -77px;
}
.flag.flag-lc {
  background-position: 0 -88px;
}
.flag.flag-li {
  background-position: -16px -88px;
}
.flag.flag-lk {
  background-position: -32px -88px;
}
.flag.flag-lr {
  background-position: -48px -88px;
}
.flag.flag-ls {
  background-position: -64px -88px;
}
.flag.flag-lt {
  background-position: -80px -88px;
}
.flag.flag-lu {
  background-position: -96px -88px;
}
.flag.flag-lv {
  background-position: -112px -88px;
}
.flag.flag-ly {
  background-position: -128px -88px;
}
.flag.flag-ma {
  background-position: -144px -88px;
}
.flag.flag-mc {
  background-position: -160px -88px;
}
.flag.flag-md {
  background-position: -176px -88px;
}
.flag.flag-me {
  background-position: -192px -88px;
}
.flag.flag-mg {
  background-position: -208px -88px;
}
.flag.flag-mh {
  background-position: -224px -88px;
}
.flag.flag-mk {
  background-position: -240px -88px;
}
.flag.flag-ml {
  background-position: 0 -99px;
}
.flag.flag-mm {
  background-position: -16px -99px;
}
.flag.flag-mn {
  background-position: -32px -99px;
}
.flag.flag-mo {
  background-position: -48px -99px;
}
.flag.flag-mp {
  background-position: -64px -99px;
}
.flag.flag-mq {
  background-position: -80px -99px;
}
.flag.flag-mr {
  background-position: -96px -99px;
}
.flag.flag-ms {
  background-position: -112px -99px;
}
.flag.flag-mt {
  background-position: -128px -99px;
}
.flag.flag-mu {
  background-position: -144px -99px;
}
.flag.flag-mv {
  background-position: -160px -99px;
}
.flag.flag-mw {
  background-position: -176px -99px;
}
.flag.flag-mx {
  background-position: -192px -99px;
}
.flag.flag-my {
  background-position: -208px -99px;
}
.flag.flag-mz {
  background-position: -224px -99px;
}
.flag.flag-na {
  background-position: -240px -99px;
}
.flag.flag-nc {
  background-position: 0 -110px;
}
.flag.flag-ne {
  background-position: -16px -110px;
}
.flag.flag-nf {
  background-position: -32px -110px;
}
.flag.flag-ng {
  background-position: -48px -110px;
}
.flag.flag-ni {
  background-position: -64px -110px;
}
.flag.flag-nl {
  background-position: -80px -110px;
}
.flag.flag-no {
  background-position: -96px -110px;
}
.flag.flag-np {
  background-position: -112px -110px;
}
.flag.flag-nr {
  background-position: -128px -110px;
}
.flag.flag-nu {
  background-position: -144px -110px;
}
.flag.flag-nz {
  background-position: -160px -110px;
}
.flag.flag-om {
  background-position: -176px -110px;
}
.flag.flag-pa {
  background-position: -192px -110px;
}
.flag.flag-pe {
  background-position: -208px -110px;
}
.flag.flag-pf {
  background-position: -224px -110px;
}
.flag.flag-pg {
  background-position: -240px -110px;
}
.flag.flag-ph {
  background-position: 0 -121px;
}
.flag.flag-pk {
  background-position: -16px -121px;
}
.flag.flag-pl {
  background-position: -32px -121px;
}
.flag.flag-pm {
  background-position: -48px -121px;
}
.flag.flag-pn {
  background-position: -64px -121px;
}
.flag.flag-pr {
  background-position: -80px -121px;
}
.flag.flag-ps {
  background-position: -96px -121px;
}
.flag.flag-pt {
  background-position: -112px -121px;
}
.flag.flag-pw {
  background-position: -128px -121px;
}
.flag.flag-py {
  background-position: -144px -121px;
}
.flag.flag-qa {
  background-position: -160px -121px;
}
.flag.flag-re {
  background-position: -176px -121px;
}
.flag.flag-ro {
  background-position: -192px -121px;
}
.flag.flag-rs {
  background-position: -208px -121px;
}
.flag.flag-ru {
  background-position: -224px -121px;
}
.flag.flag-rw {
  background-position: -240px -121px;
}
.flag.flag-sa {
  background-position: 0 -132px;
}
.flag.flag-sb {
  background-position: -16px -132px;
}
.flag.flag-sc {
  background-position: -32px -132px;
}
.flag.flag-scotland {
  background-position: -48px -132px;
}
.flag.flag-sd {
  background-position: -64px -132px;
}
.flag.flag-se {
  background-position: -80px -132px;
}
.flag.flag-sg {
  background-position: -96px -132px;
}
.flag.flag-sh {
  background-position: -112px -132px;
}
.flag.flag-si {
  background-position: -128px -132px;
}
.flag.flag-sk {
  background-position: -144px -132px;
}
.flag.flag-sl {
  background-position: -160px -132px;
}
.flag.flag-sm {
  background-position: -176px -132px;
}
.flag.flag-sn {
  background-position: -192px -132px;
}
.flag.flag-so {
  background-position: -208px -132px;
}
.flag.flag-somaliland {
  background-position: -224px -132px;
}
.flag.flag-sr {
  background-position: -240px -132px;
}
.flag.flag-ss {
  background-position: 0 -143px;
}
.flag.flag-st {
  background-position: -16px -143px;
}
.flag.flag-sv {
  background-position: -32px -143px;
}
.flag.flag-sx {
  background-position: -48px -143px;
}
.flag.flag-sy {
  background-position: -64px -143px;
}
.flag.flag-sz {
  background-position: -80px -143px;
}
.flag.flag-tc {
  background-position: -96px -143px;
}
.flag.flag-td {
  background-position: -112px -143px;
}
.flag.flag-tf {
  background-position: -128px -143px;
}
.flag.flag-tg {
  background-position: -144px -143px;
}
.flag.flag-th {
  background-position: -160px -143px;
}
.flag.flag-tibet {
  background-position: -176px -143px;
}
.flag.flag-tj {
  background-position: -192px -143px;
}
.flag.flag-tk {
  background-position: -208px -143px;
}
.flag.flag-tl {
  background-position: -224px -143px;
}
.flag.flag-tm {
  background-position: -240px -143px;
}
.flag.flag-tn {
  background-position: 0 -154px;
}
.flag.flag-to {
  background-position: -16px -154px;
}
.flag.flag-tr {
  background-position: -32px -154px;
}
.flag.flag-tt {
  background-position: -48px -154px;
}
.flag.flag-tv {
  background-position: -64px -154px;
}
.flag.flag-tw {
  background-position: -80px -154px;
}
.flag.flag-tz {
  background-position: -96px -154px;
}
.flag.flag-ua {
  background-position: -112px -154px;
}
.flag.flag-ug {
  background-position: -128px -154px;
}
.flag.flag-um {
  background-position: -144px -154px;
}
.flag.flag-us {
  background-position: -160px -154px;
}
.flag.flag-uy {
  background-position: -176px -154px;
}
.flag.flag-uz {
  background-position: -192px -154px;
}
.flag.flag-va {
  background-position: -208px -154px;
}
.flag.flag-vc {
  background-position: -224px -154px;
}
.flag.flag-ve {
  background-position: -240px -154px;
}
.flag.flag-vg {
  background-position: 0 -165px;
}
.flag.flag-vi {
  background-position: -16px -165px;
}
.flag.flag-vn {
  background-position: -32px -165px;
}
.flag.flag-vu {
  background-position: -48px -165px;
}
.flag.flag-wales {
  background-position: -64px -165px;
}
.flag.flag-wf {
  background-position: -80px -165px;
}
.flag.flag-ws {
  background-position: -96px -165px;
}
.flag.flag-xk {
  background-position: -112px -165px;
}
.flag.flag-ye {
  background-position: -128px -165px;
}
.flag.flag-yt {
  background-position: -144px -165px;
}
.flag.flag-za {
  background-position: -160px -165px;
}
.flag.flag-zanzibar {
  background-position: -176px -165px;
}
.flag.flag-zm {
  background-position: -192px -165px;
}
.flag.flag-zw {
  background-position: -208px -165px;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "porto";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;

  font-variant: normal;
  text-transform: none;

  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cart-thick:before {
  content: "";
}
.icon-check-circle:before {
  content: "";
}
.icon-envelop:before {
  content: "";
}
.icon-business-book:before {
  content: "";
}
.icon-long-arrow-right:before {
  content: "";
}
.icon-percent-shape:before {
  content: "";
}
.icon-sale-label:before {
  content: "";
}
.icon-help-circle:before {
  content: "";
}
.icon-sale-discount:before {
  content: "";
}
.icon-shipping-truck:before {
  content: "";
}
.icon-user-3:before {
  content: "";
}
.icon-long-arrow-alt:before {
  content: "";
}
.icon-map-location:before {
  content: "";
}
.icon-phone-call:before {
  content: "";
}
.icon-tablet:before {
  content: "";
}
.icon-spin1:before {
  content: "";
}
.icon-spin2:before {
  content: "";
}
.icon-spin3:before {
  content: "";
}
.icon-spin4:before {
  content: "";
}
.icon-spin5:before {
  content: "";
}
.icon-spin6:before {
  content: "";
}
.icon-firefox:before {
  content: "";
}
.icon-chrome:before {
  content: "";
}
.icon-opera:before {
  content: "";
}
.icon-ie:before {
  content: "";
}
.icon-phone:before {
  content: "";
}
.icon-down-dir:before {
  content: "";
}
.icon-cart:before {
  content: "";
}
.icon-up-dir:before {
  content: "";
}
.icon-mode-grid:before {
  content: "";
}
.icon-mode-list:before {
  content: "";
}
.icon-compare:before {
  content: "";
}
.icon-wishlist:before {
  content: "";
}
.icon-search:before {
  content: "";
}
.icon-left-dir:before {
  content: "";
}
.icon-right-dir:before {
  content: "";
}
.icon-down-open:before {
  content: "";
}
.icon-left-open:before {
  content: "";
}
.icon-right-open:before {
  content: "";
}
.icon-up-open:before {
  content: "";
}
.icon-angle-left:before {
  content: "";
}
.icon-angle-right:before {
  content: "";
}
.icon-angle-up:before {
  content: "";
}
.icon-angle-down:before {
  content: "";
}
.icon-down:before {
  content: "";
}
.icon-left:before {
  content: "";
}
.icon-right:before {
  content: "";
}
.icon-up:before {
  content: "";
}
.icon-angle-double-left:before {
  content: "";
}
.icon-angle-double-right:before {
  content: "";
}
.icon-angle-double-up:before {
  content: "";
}
.icon-angle-double-down:before {
  content: "";
}
.icon-mail:before {
  content: "";
}
.icon-location:before {
  content: "";
}
.icon-skype:before {
  content: "";
}
.icon-right-open-big:before {
  content: "";
}
.icon-left-open-big:before {
  content: "";
}
.icon-down-open-big:before {
  content: "";
}
.icon-up-open-big:before {
  content: "";
}
.icon-cancel:before {
  content: "";
}
.icon-user:before {
  content: "";
}
.icon-mail-alt:before {
  content: "";
}
.icon-fax:before {
  content: "";
}
.icon-lock:before {
  content: "";
}
.icon-company:before {
  content: "";
}
.icon-city:before {
  content: "";
}
.icon-post:before {
  content: "";
}
.icon-country:before {
  content: "";
}
.icon-calendar:before {
  content: "";
}
.icon-doc:before {
  content: "";
}
.icon-mobile:before {
  content: "";
}
.icon-clock:before {
  content: "";
}
.icon-chat:before {
  content: "";
}
.icon-tag:before {
  content: "";
}
.icon-folder:before {
  content: "";
}
.icon-folder-open:before {
  content: "";
}
.icon-forward:before {
  content: "";
}
.icon-reply:before {
  content: "";
}
.icon-cog:before {
  content: "";
}
.icon-cog-alt:before {
  content: "";
}
.icon-wrench:before {
  content: "";
}
.icon-quote-left:before {
  content: "";
}
.icon-quote-right:before {
  content: "";
}
.icon-gift:before {
  content: "";
}
.icon-dollar:before {
  content: "";
}
.icon-euro:before {
  content: "";
}
.icon-pound:before {
  content: "";
}
.icon-rupee:before {
  content: "";
}
.icon-yen:before {
  content: "";
}
.icon-rouble:before {
  content: "";
}
.icon-try:before {
  content: "";
}
.icon-won:before {
  content: "";
}
.icon-bitcoin:before {
  content: "";
}
.icon-ok:before {
  content: "";
}
.icon-chevron-left:before {
  content: "";
}
.icon-chevron-right:before {
  content: "";
}
.icon-export:before {
  content: "";
}
.icon-star:before {
  content: "";
}
.icon-star-empty:before {
  content: "";
}
.icon-plus-squared:before {
  content: "";
}
.icon-minus-squared:before {
  content: "";
}
.icon-plus-squared-alt:before {
  content: "";
}
.icon-minus-squared-alt:before {
  content: "";
}
.icon-truck:before {
  content: "";
}
.icon-lifebuoy:before {
  content: "";
}
.icon-pencil:before {
  content: "";
}
.icon-users:before {
  content: "";
}
.icon-video:before {
  content: "";
}
.icon-menu:before {
  content: "";
}
.icon-desktop:before {
  content: "";
}
.icon-doc-inv:before {
  content: "";
}
.icon-circle:before {
  content: "";
}
.icon-circle-empty:before {
  content: "";
}
.icon-circle-thin:before {
  content: "";
}
.icon-mini-cart:before {
  content: "";
}
.icon-paper-plane:before {
  content: "";
}
.icon-attention-alt:before {
  content: "";
}
.icon-info:before {
  content: "";
}
.icon-compare-link:before {
  content: "";
}
.icon-cat-default:before {
  content: "";
}
.icon-cat-computer:before {
  content: "";
}
.icon-cat-couch:before {
  content: "";
}
.icon-cat-garden:before {
  content: "";
}
.icon-cat-gift:before {
  content: "";
}
.icon-cat-shirt:before {
  content: "";
}
.icon-cat-sport:before {
  content: "";
}
.icon-cat-toys:before {
  content: "";
}
.icon-tag-line:before {
  content: "";
}
.icon-bag:before {
  content: "";
}
.icon-search-1:before {
  content: "";
}
.icon-plus:before {
  content: "";
}
.icon-minus:before {
  content: "";
}
.icon-search-2:before {
  content: "";
}
.icon-bag-1:before {
  content: "";
}
.icon-online-support:before {
  content: "";
}
.icon-shopping-bag:before {
  content: "";
}
.icon-us-dollar:before {
  content: "";
}
.icon-shipped:before {
  content: "";
}
.icon-list:before {
  content: "";
}
.icon-money:before {
  content: "";
}
.icon-shipping:before {
  content: "";
}
.icon-support:before {
  content: "";
}
.icon-bag-2:before {
  content: "";
}
.icon-grid:before {
  content: "";
}
.icon-bag-3:before {
  content: "";
}
.icon-direction:before {
  content: "";
}
.icon-home:before {
  content: "";
}
.icon-magnifier:before {
  content: "";
}
.icon-magnifier-add:before {
  content: "";
}
.icon-magnifier-remove:before {
  content: "";
}
.icon-phone-1:before {
  content: "";
}
.icon-clock-1:before {
  content: "";
}
.icon-heart:before {
  content: "";
}
.icon-heart-1:before {
  content: "";
}
.icon-earphones-alt:before {
  content: "";
}
.icon-credit-card:before {
  content: "";
}
.icon-action-undo:before {
  content: "";
}
.icon-envolope:before {
  content: "";
}
.icon-chart:before {
  content: "";
}
.icon-category-home:before {
  content: "";
}
.icon-category-motors:before {
  content: "";
}
.icon-category-music:before {
  content: "";
}
.icon-category-gifts:before {
  content: "";
}
.icon-category-electronics:before {
  content: "";
}
.icon-category-fashion:before {
  content: "";
}
.icon-category-hot-deals:before {
  content: "";
}
.icon-tag-percent:before {
  content: "";
}
.icon-joystick:before {
  content: "";
}
.icon-category-furniture:before {
  content: "";
}
.icon-category-garden:before {
  content: "";
}
.icon-category-lanterns-lighting:before {
  content: "";
}
.icon-category-mechanics:before {
  content: "";
}
.icon-category-motorcycles:before {
  content: "";
}
.icon-category-sound-video:before {
  content: "";
}
.icon-category-steering:before {
  content: "";
}
.icon-category-external-accessories:before {
  content: "";
}
.icon-category-fluids:before {
  content: "";
}
.icon-category-internal-accessories:before {
  content: "";
}
.icon-category-chains:before {
  content: "";
}
.icon-category-frames:before {
  content: "";
}
.icon-category-pedals:before {
  content: "";
}
.icon-category-saddle:before {
  content: "";
}
.icon-category-tools:before {
  content: "";
}
.icon-search-3:before {
  content: "";
}
.icon-secure-payment:before {
  content: "";
}
.icon-user-2:before {
  content: "";
}
.icon-wishlist-2:before {
  content: "";
}
.icon-gift-2:before {
  content: "";
}
.icon-edit:before {
  content: "";
}
.icon-chef:before {
  content: "";
}
.icon-smiling-girl:before {
  content: "";
}
.icon-tshirt:before {
  content: "";
}
.icon-boy-broad-smile:before {
  content: "";
}
.icon-smiling-baby:before {
  content: "";
}
.icon-bars:before {
  content: "";
}
.icon-twitter:before {
  content: "";
}
.icon-facebook:before {
  content: "";
}
.icon-spinner:before {
  content: "";
}
.icon-instagram:before {
  content: "";
}
.icon-check-empty:before {
  content: "";
}
.icon-check:before {
  content: "";
}
.icon-shopping-cart:before {
  content: "";
}
.icon-phone-2:before {
  content: "";
}
.icon-percent-circle:before {
  content: "";
}
.icon-pulley:before {
  content: "";
}
.icon-password-lock:before {
  content: "";
}
.icon-pin:before {
  content: "";
}
.icon-rotulo:before {
  content: "";
}
.icon-comida-organica:before {
  content: "";
}
.icon-estrela:before {
  content: "";
}
.icon-fazer-compras:before {
  content: "";
}
.icon-gluten:before {
  content: "";
}
.icon-arrow-forward-right:before {
  content: "";
}

form {
  margin-bottom: 3.5rem;
}
form h2 {
  margin-top: 3.4rem;
  margin-bottom: 2.3rem;
}

.form-group {
  margin-bottom: 1.4rem;
}
.form-group .form-control,
.form-group .select-custom {
  margin-bottom: 0;
}
.form-group .form-control + .form-control {
  margin-top: 1rem;
}

.form-group-sm {
  max-width: 480px;
  margin-bottom: 0.9rem;
}

.form-control {
  padding-left: 1.2rem;
  height: 46px;
  margin-bottom: 1rem;
  transition: all 0.3s;
  border: 1px solid #dfdfdf;
  border-radius: 0;
  background-color: #fff;
  color: #777;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
}
.form-control:focus {
  border-color: #dfdfdf;
  background-color: #fff;
  color: #777;
  box-shadow: none;
}

.form-control-sm {
  height: 4.6rem;
  padding: 0.8rem 1.2rem;
}

select.form-control:not([size]):not([multiple]) {
  height: 6rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  padding: 0;
  padding-left: 1.2rem;
  height: 3.7rem;
  font-size: 1.4rem;
  letter-spacing: -0.013em;
}

.main .form-control::-webkit-input-placeholder,
.main .form-control::-moz-placeholder,
.main .form-control:-ms-input-placeholder,
.main .form-control::-ms-input-placeholder,
.main .form-control::placeholder {
  font-family: Poppins, sans-serif;
}

textarea.form-control {
  padding-top: 0.8rem;
  min-height: 151px;
  border-color: #ebebeb;
}

.form-footer {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 3.5rem;
  font-size: 1.2rem;
  vertical-align: middle;
}
.form-footer .form-footer-right {
  margin-right: 0;
  margin-left: auto;
}
.form-footer .form-footer-right .btn {
  margin: 0;
}
.form-footer .btn {
  margin-right: 3rem;
}

.forget-pass {
  line-height: 1.3;
}

label {
  margin: 0 0 0.6rem;
  color: #222529;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
}

.required-field > label:after {
  margin: 0 0 0 0.45rem;
  color: #e02b27;
  font-size: 1.2rem;
  content: "*";
}

.required {
  color: #e02b27;
}
.required + .form-footer {
  margin-top: 0.8rem;
}

.select-custom {
  position: relative;
  margin-bottom: 1rem;
  background: #fff;
}
.select-custom select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  position: relative;
  z-index: 1;
  background: transparent;
}
.select-custom select:focus {
  background: transparent;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .select-custom select::-ms-expand {
    display: none;
  }
  .select-custom select:focus::-ms-value {
    background: transparent;
    color: currentColor;
  }
}
.select-custom:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 1.9rem;
  transform: translateY(-50%);
  font-family: "porto";
  font-size: 1.6rem;
  content: "";
  padding-bottom: 2px;
  font-weight: 400;
}
.select-custom .form-control,
.select-custom .form-control-sm {
  padding-right: 4rem;
}

.form-group-custom-control {
  margin-top: 1.3rem;
}
.form-group-custom-control .custom-control {
  margin-top: 0;
  margin-bottom: 2rem;
}
.form-group-custom-control .custom-radio {
  margin-bottom: 2.2rem;
  padding-left: 2.9rem;
}
.form-group-custom-control + .form-group-custom-control {
  margin-top: -0.5rem;
}
.form-group-custom-control .custom-control-label {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: initial;
  color: #777777;
  font-size: 13px;
}

.custom-control {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 3rem;
}

.custom-control-label {
  position: static;
  margin-bottom: 0;
}
.custom-control-label:before,
.custom-control-label:after {
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}
.custom-control-label:before {
  border: 1px solid #ccc;
  background-color: #fff;
}
.custom-radio .custom-control-label:after {
  top: 4px;
  left: 0.4rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
}

.custom-radio .custom-control-input {
  position: absolute;
  z-index: 333;
  opacity: 0;
  left: 4px;
  top: 3px;
}
.custom-radio .custom-control {
  padding-left: 2.9rem;
}

.custom-checkbox .custom-control-label:after {
  top: 0;
  left: 4px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: transparent;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background: none;
  color: #222529;
  content: "";
  font-family: "porto";
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label:before {
  background-color: #08c;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-radio .custom-control-label:before {
  background-color: transparent !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: none;
  background-color: #08c;
}

.form-control-tooltip {
  position: relative;
  padding-right: 4rem;
}
.form-control-tooltip .input-tooltip {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: all 0.3s;
  color: #777;
  font-size: 2.4rem;
}
.form-control-tooltip .input-tooltip:hover,
.form-control-tooltip .input-tooltip:focus {
  color: #000;
}

.form-input {
  padding: 1rem 1.2rem;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.form-wide {
  width: 100%;
}

@media (min-width: 768px) {
  form,
  .form-footer {
    margin-bottom: 4rem;
  }

  form h2 {
    margin-top: 4.4rem;
  }
}
@media (min-width: 992px) {
  form,
  .form-footer {
    margin-bottom: 5rem;
  }
}
.info-box {
  display: flex;
  align-items: center;
  width: 100%;
  color: #222529;
  padding: 2.5rem 2.4rem 2rem;
}
.info-box i {
  color: inherit;
  font-size: 3.7rem;
  text-align: center;
}
.info-box i:before {
  width: auto;
  margin: 0 0.1em;
}
.info-box h4 {
  color: inherit;
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.1;
}
.info-box p {
  margin-bottom: 0;
  font-size: 1.3rem;
  line-height: 1.6;
}
.info-box .porto-info {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #2f6473;
}

.info-box.with-icon p:before {
  float: left;
  content: "";
  color: #5bc0de;
  font-family: "Font Awesome 5 Free";
  font-size: 2rem;
  font-weight: 900;
  line-height: 1;
  margin-right: 5px;
  margin-top: 3px;
}

.info-box-icon-left {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.info-box-icon-left i {
  line-height: 0;
  margin-right: 1.5rem;
}

.modal form {
  margin: 0;
}
.modal form .form-group {
  max-width: 480px;
}
.modal form .form-control {
  max-width: 100%;
}

.modal-body {
  padding: 1.5rem;
}

.modal-content {
  border-radius: 0;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
}

.modal-header,
.modal-footer {
  display: flex;
  align-items: center;
  height: 80px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.modal-title {
  font-weight: 400;
}

.close {
  font-size: 2.2rem;
}

@media (min-width: 576px) {
  .modal-content {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal-body {
    max-height: calc(100vh - 210px);
    padding-top: 2rem;
    overflow-y: auto;
  }

  .modal-header,
  .modal-footer,
  .modal-body {
    padding-right: 2em;
    padding-left: 2rem;
  }
}
@media (min-width: 992px) {
  .modal-dialog {
    max-width: 800px;
  }

  .modal-header,
  .modal-footer,
  .modal-body {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media (max-width: 479px) {
  .modal-open,
  .modal-open .modal {
    padding-right: 0 !important;
  }
}
.page-header {
  padding: 5.5rem 0;
  color: #222529;
  background-color: #f4f4f4;
  text-align: center;
}
.page-header .breadcrumb {
  padding-top: 0.5rem;
  padding-bottom: 0;
}
.page-header .breadcrumb a {
  color: #08c;
}
.page-header .breadcrumb a:hover {
  text-decoration: underline;
}
.page-header .breadcrumb .breadcrumb-item:last-child:before {
  padding-right: 0.8rem;
}
.breadcrumb-nav + .page-header {
  margin-top: -2rem;
}

.page-header h1,
.page-title {
  margin-bottom: 0.4rem;
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  color: #222529;
}

@media (min-width: 768px) {
  .breadcrumb-nav + .page-header {
    margin-top: -3rem;
  }
}
@media (min-width: 992px) {
  .breadcrumb-nav + .page-header {
    margin-top: -4rem;
  }
}
.toolbox {
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  -ms-flex-pack: justify;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
}
.toolbox .select-custom:after {
  right: 1.5rem;
  font-size: 1.6rem;
  color: #222529;
}
.toolbox .select-custom .form-control {
  max-width: 160px;
  padding-right: 2.5rem;
  padding-left: 0.8rem;
  font-size: 1.3rem;
  padding-top: 1px;
}
.toolbox label {
  margin: 1px 1.1rem 0 0;
  color: #777;
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
.toolbox .form-control {
  display: inline-block;
  margin-bottom: 0;
  padding: 0 0.8rem;
  color: #777;
}
.toolbox .form-control:focus {
  color: #777;
}
.toolbox select.form-control:not([size]):not([multiple]) {
  height: 34px;
}
.toolbox .toolbox-show .select-custom:after {
  right: 1rem;
}

.toolbox,
.toolbox-left,
.toolbox-right,
.toolbox-item {
  display: flex;
  align-items: center;
}

.toolbox-item {
  margin-bottom: 10px;
}
.toolbox-item:not(:last-child) {
  margin-right: 10px;
}
.toolbox-item.layout-modes {
  margin-top: -1px;
}
.toolbox-item.toolbox-sort {
  margin-right: 1.5rem;
}
.toolbox-item .select-custom {
  margin-bottom: 0;
}

.toolbox-pagination {
  border-top: 1px solid #efefef;
  padding-top: 2.5rem;
  margin-bottom: 3.5rem;
}

.pagination {
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  color: #706f6c;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
}

.page-item:not(:first-child) {
  margin-left: 0.5rem;
}
.page-item.active .page-link {
  color: #706f6c;
  background-color: transparent;
  border-color: #08c;
}
.page-item.disabled {
  display: none;
}

.page-link {
  border: 1px solid #ccc;
  padding: 0 0.5em;
  min-width: 2.2em;
  color: inherit;
  line-height: 2.1em;
  text-align: center;
}
.page-link:hover,
.page-link:focus {
  color: #706f6c;
  background-color: transparent;
  border-color: #08c;
  box-shadow: none;
}
.page-link i {
  font-size: 2rem;
}

span.page-link,
.page-link-btn {
  border: 0;
}

.layout-btn {
  display: inline-block;
  width: 1.2em;
  color: #000;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
}
.layout-btn:not(:last-child) {
  margin-right: 4px;
}
.layout-btn.active {
  color: #08c;
}

@media (min-width: 992px) {
  .toolbox-pagination {
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  aside .toolbox-item {
    display: block;
  }
  aside .toolbox-item:after {
    content: normal;
  }

  .toolbox:not(.toolbox-pagination) {
    padding: 10px;
    background-color: #f4f4f4;
    margin-bottom: 2rem;
  }
  .toolbox:not(.toolbox-pagination) .toolbox-item {
    margin-bottom: 0;
  }

  .toolbox label {
    font-size: 11px;
    font-weight: 600;
    color: #222529;
  }
  .toolbox .select-custom .form-control {
    font-size: 11px;
    font-weight: 600;
    max-width: 140px;
    text-transform: uppercase;
    color: #222529;
  }
}
@media (max-width: 767px) {
  .toolbox label {
    display: none;
  }
  .toolbox .select-custom:after {
    padding: 2px 0 0;
  }
}
@media (max-width: 575px) {
  .toolbox .layout-modes {
    display: none;
  }
  .toolbox .toolbox-show,
  .toolbox .toolbox-sort {
    margin-right: 0;
  }
  .toolbox .select-custom .form-control {
    max-width: 132px;
  }
}
.minipopup-area {
  position: fixed;
  right: 20px;
  bottom: 20px;
  font-size: 1.1em;
  text-align: center;
  z-index: 20002;
}

.minipopup-box {
  position: absolute;
  right: -100px;
  text-align: left;
  margin: auto auto 20px;
  padding: 20px;
  line-height: 1.4;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.08);
  word-break: break-word;
  transform: translateY(-100%);
  width: 300px;
  z-index: 1000;
  background: #fff;
  opacity: 0;
  transition: right 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
.minipopup-box.active {
  right: 0;
  opacity: 1;
}
.minipopup-box .product {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.minipopup-box figure {
  max-width: 60px;
  margin-right: 10px;
  margin-bottom: 0;
}
.minipopup-box .product-detail {
  font-family: Poppins, sans-serif;
  font-weight: 500;
}
.minipopup-box .product-detail .product-name {
  color: #222529;
  font-size: 12px;
  font-weight: 700;
}
.minipopup-box .product-detail .product-name:hover {
  color: #08c;
}
.minipopup-box .product-detail p {
  font-size: 12px;
  letter-spacing: -0.025em;
  margin-bottom: 0;
}
.minipopup-box .product-action .btn {
  min-width: 120px;
  letter-spacing: 0.025em;
  font-size: 11.2px;
  padding: 11.2px 22.4px;
}
.minipopup-box .product-action .btn.viewcart {
  background-color: #efefef;
  color: #000;
}
.minipopup-box .product-action .btn.checkout {
  float: right;
}

.wishlist-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-width: 4px 0 0;
  font-weight: 600;
  line-height: 1.5;
  padding: 15px 20px;
  width: 250px;
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s;
  z-index: 100;
}
.wishlist-popup .wishlist-popup-msg {
  font-weight: 600;
  line-height: 1.6;
  text-align: center;
}
.wishlist-popup.active {
  opacity: 1;
  visibility: visible;
  z-index: 1071;
}

.login-popup .mfp-content {
  margin-top: 2.1rem;
  max-width: 872px;
  background-color: #fff;
}
.login-popup .btn-regist {
  margin-top: 3.6rem;
  font-size: 1.6rem;
  letter-spacing: -0.025em;
}
.login-popup .form-footer-right {
  margin-bottom: 0.6rem;
}
.login-popup .form-input {
  height: 42px;
}
.login-popup .container {
  padding: 3.9rem 3.6rem 4.7rem;
}
.login-popup .title {
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.45;
  margin-bottom: 0.9rem;
}
.login-popup form {
  display: block;
}
.login-popup label {
  color: #777;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.57;
  margin-bottom: 0.6rem;
}
.login-popup .form-footer {
  margin: 1rem 0 2.1rem;
}
.login-popup .form-footer .custom-control {
  margin: 0 0 0 auto;
  font-size: 1.3rem;
  padding-left: 2.5rem;
}
.login-popup .forget-password {
  color: #222529;
  font-size: 1.4rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.login-popup .btn-block {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 1.5rem 2.4rem;
  letter-spacing: -0.02em;
}
.login-popup .form-control {
  padding-left: 2.5rem;
}
.login-popup .form-control:hover {
  outline: none;
}
.login-popup .custom-control-label {
  margin-top: 2px;
  font-size: 1.2rem;
}

.newsletter-popup {
  position: relative;
  max-width: 740px;
  margin-right: auto;
  margin-left: auto;
  padding: 64px 40px;
  border-radius: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

.mfp-bg {
  background-color: #777777;
}

button.mfp-close {
  position: absolute;
  top: 0;
  right: 0;
  overflow: visible;
  opacity: 0.65;
  cursor: pointer;
  background: transparent;
  border: 0;
  text-indent: -9999px;
  transform: rotateZ(45deg);
  color: #838383;
}
button.mfp-close:hover {
  opacity: 1;
}
.mfp-image-holder button.mfp-close {
  width: 41px;
  color: #fff;
  text-align: left;
}
button.mfp-close:after {
  content: "";
  position: absolute;
  height: 17px;
  top: 12px;
  left: 20px;
  border-left: 1px solid;
}
button.mfp-close:before {
  content: "";
  position: absolute;
  width: 17px;
  top: 20px;
  left: 12px;
  border-top: 1px solid;
}

.newsletter-popup-content {
  max-width: 357px;
}
.newsletter-popup-content .form-control {
  height: auto;
  padding: 7px 12px 9px 22px;
  border-radius: 3rem 0 0 3rem;
  font-size: 1.36rem;
  line-height: 2.4;
  border: none;
  background-color: #f4f4f4;
}
.newsletter-popup-content .form-control::placeholder {
  position: relative;
  top: 2px;
  color: #999;
}
.newsletter-popup-content .btn {
  margin-left: -1px;
  padding: 0 32px 0 25px;
  border-radius: 0 3rem 3rem 0;
  font-size: 1.28rem;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
}

.logo-newsletter {
  display: inline-block;
  max-width: 111px;
  height: auto;
}

.newsletter-popup h2 {
  margin: 24px 0 5px;
  color: #313131;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}

.newsletter-popup p {
  font-size: 1.4rem;
  line-height: 1.85;
  letter-spacing: -0.02em;
  margin-bottom: 2.4rem;
}

.newsletter-popup form {
  margin: 0 0 2.7rem;
}

.newsletter-popup .custom-control {
  margin: 0 0 4px 1px;
  padding-left: 2.5rem;
}

.newsletter-subscribe {
  font-size: 1.1rem;
  text-align: left;
}
.newsletter-subscribe .checkbox {
  margin: 1.5rem 0;
}
.newsletter-subscribe input {
  margin-right: 0.5rem;
  vertical-align: middle;
}
.newsletter-subscribe label {
  margin-top: 0.2rem;
  color: inherit;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  text-transform: none;
}

.mfp-newsletter.mfp-removing {
  transition: opacity 0.35s ease-out;
  opacity: 0;
}

.mfp-ready .mfp-preloader {
  display: none;
}

.mfp-zoom-out-cur .mfp-bg {
  opacity: 0.8;
  background-color: #0b0b0b;
}
.mfp-zoom-out-cur .mfp-counter {
  color: #fff;
}
.mfp-zoom-out-cur .mfp-arrow-right:before {
  border-left: 0;
}
.mfp-zoom-out-cur .mfp-arrow-left:before {
  border-right: 0;
}

.mfp-ajax-product.mfp-bg,
.login-popup.mfp-bg {
  opacity: 0.6;
  background-color: transparent;
}

.mfp-ajax-product .product-single-container {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
}

.mfp-wrap .mfp-content {
  transition: all 0.35s ease-out;
  opacity: 0;
}

.login-popup.mfp-wrap .mfp-content {
  max-width: 525px;
}

.mfp-ajax-product.mfp-wrap .mfp-content {
  max-width: 931px;
}

.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-ajax-product {
  z-index: 1058;
}

.mfp-bg.mfp-newsletter,
.mfp-bg.login-popup,
.mfp-wrap.mfp-newsletter,
.mfp-wrap.login-popup {
  z-index: 1058;
}

@media (max-width: 991px) {
  .mfp-container {
    padding: 0.6rem;
  }
}
@media (max-width: 479px) {
  .minipopup-area {
    right: 10px;
  }

  .minipopup-box {
    width: 290px;
  }

  .newsletter-popup {
    padding: 54px 30px;
  }
}
@media (min-width: 768px) {
  .login-popup .col-md-6 {
    padding: 0 2rem;
  }
  .login-popup .col-md-6:first-child {
    border-right: 1px solid #f5f6f6;
  }
}
.product-intro.owl-carousel.owl-theme .owl-nav.disabled + .owl-dots {
  margin: 0;
}
.product-intro.owl-carousel.owl-theme .owl-dots {
  top: -58px;
  position: absolute;
  right: 0;
}
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border: 2px solid;
  background: none;
  margin: 5px 2px;
  border-radius: 7px;
  border-color: rgba(0, 68, 102, 0.4);
  transition: opacity 0.2s;
}
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot.active span,
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot:hover span {
  background: none;
  border-color: #08c;
}
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot.active span:before,
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot:hover span:before {
  display: none;
}
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot.active span:after,
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot:hover span:after {
  content: "";
  position: absolute;
  left: 3px;
  bottom: 3px;
  right: 3px;
  top: 3px;
  border-radius: 10px;
  background-color: #08c;
}
.product-intro.owl-carousel.owl-theme .owl-nav {
  color: #333;
  font-size: 2.4rem;
}
.product-intro.owl-carousel.owl-theme .owl-nav .owl-prev,
.product-intro.owl-carousel.owl-theme .owl-nav .owl-next {
  opacity: 0;
  transition: opacity 0.2s, transform 0.4s;
  top: 30%;
  width: 30px;
}
.product-intro.owl-carousel.owl-theme .owl-nav .owl-prev {
  text-align: left;
  left: -30px;
  padding-right: 30px;
  transform: translateX(-10px);
}
.product-intro.owl-carousel.owl-theme .owl-nav .owl-next {
  text-align: right;
  right: -30px;
  padding-left: 30px;
  transform: translateX(10px);
}
.product-intro.owl-carousel.owl-theme:hover .owl-prev,
.product-intro.owl-carousel.owl-theme:hover .owl-next {
  transform: translateX(0);
  opacity: 1;
}

.product-panel {
  margin-bottom: 3.5rem;
}
.product-panel .section-title {
  color: #313131;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 2.4rem;
}
.product-panel .section-title h2 {
  font-weight: 700;
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.01em;
  line-height: 22px;
  text-transform: uppercase;
}

.tooltiptext {
  visibility: hidden;
  position: absolute;
  background-color: #333;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-align: center;
  padding: 1rem 0.7rem;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
}

figure .porto-loading-icon {
  position: absolute;
}

.tooltip-top:after {
  content: "";
  position: absolute;
  top: 96%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.old-price {
  text-decoration: line-through;
  font-size: 1.4rem;
  letter-spacing: 0.005em;
  color: #999;
  margin-right: 3px;
}

.product-price {
  color: #222529;
  font-size: 1.8rem;
  line-height: 1;
}

.price-box {
  margin-bottom: 1.4rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 1;
}

.ratings-container {
  line-height: 1;
  margin: 0 0 12px 1px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.ratings-container .product-ratings,
.ratings-container .ratings {
  position: relative;
  display: inline-block;
  font-size: 11px;
  letter-spacing: 0.1em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.ratings-container .product-ratings {
  height: 11px;
}
.ratings-container .product-ratings:before {
  content: "";
  color: rgba(0, 0, 0, 0.16);
}
.ratings-container .product-ratings:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.ratings-container .ratings {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.ratings-container .ratings:before {
  content: "";
  color: #6a6a6d;
}

.product-select-group {
  display: flex;
  display: -ms-flexbox;
}

.product-select {
  margin: 0 4px 0 0;
  cursor: pointer;
}
.product-select.type-image {
  width: 32px;
  height: 32px;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.09);
}
.product-select.type-image.checked,
.product-select.type-image.hover {
  border: 1px solid #08c;
}
.product-select.type-check {
  margin: 5px;
  overflow: visible;
  display: block;
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.product-select.type-check:after {
  content: "";
  background-color: transparent;
  border: 1px solid black;
  position: absolute;
  left: -3px;
  top: -3px;
  border-radius: 50%;
  width: 18px;
  display: block;
  height: 18px;
}
.product-select.type-check.checked:before {
  font-size: 8px;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  text-indent: 0;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  color: #fff;
  height: 12px;
  line-height: 12px;
  margin-top: -6px;
  text-align: center;
}

.product-nav-filter {
  display: flex;
  align-items: center;
}

.product-nav-thumbs a,
.product-nav-thumbs span {
  margin-right: 0.6rem;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent !important;
  border: 1px solid #e9e9e9;
  transition: border-color 0.35s;
}
.product-nav-thumbs a:hover,
.product-nav-thumbs span:hover {
  border-color: #1d70ba;
}

.product-nav-dots {
  padding-top: 2px;
}

.product-nav-dots a,
.product-nav-dots span {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  border: 0.2rem solid #fff;
  margin-right: 0.6rem;
  transition: box-shadow 0.35s ease;
  box-shadow: 0 0 0 0.1rem #222529;
}

.product-single-qty {
  display: inline-block;
  max-width: 104px;
  vertical-align: middle;
}
.product-single-qty .bootstrap-touchspin.input-group {
  flex-wrap: nowrap;
  max-width: none;
  padding-right: 0;
}
.product-single-qty .bootstrap-touchspin .form-control {
  width: 2.7em;
  height: 36px;
  padding: 10px 2px;
  color: #222529;
  font-size: 1.4rem;
  font-family: Poppins, sans-serif;
  text-align: center;
}

.product-single-qty .btn {
  width: 2.2em;
  padding: 0;
}
.product-single-qty .btn.btn-down-icon:hover:before,
.product-single-qty .btn.btn-down-icon:hover:after,
.product-single-qty .btn.btn-up-icon:hover:before,
.product-single-qty .btn.btn-up-icon:hover:after {
  background-color: #08c;
}
.product-single-qty .btn.btn-outline {
  border-color: #e7e7e7;
}
.product-single-qty .btn.btn-down-icon:after,
.product-single-qty .btn.btn-up-icon:before,
.product-single-qty .btn.btn-up-icon:after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 1px;
  margin-left: -0.55rem;
  background: #222529;
  content: "";
}
.product-single-qty .btn.btn-up-icon:before {
  transform: rotate(90deg);
}
.product-single-qty .horizontal-quantity::-webkit-outer-spin-button,
.product-single-qty .horizontal-quantity::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.config-swatch-list {
  margin: 1.5rem 0 0;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.config-swatch-list li a {
  position: relative;
  display: block;
  width: 2.8rem;
  height: 2.8rem;
  margin: 3px 6px 3px 0;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}
.config-swatch-list li .color-panel {
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
  border: 1px solid #fff;
  transition: all 0.3s;
  margin-right: 1.5rem;
}
.config-swatch-list li span:last-child {
  cursor: pointer;
}
.config-swatch-list li:hover span:last-child {
  color: #08c;
}
.config-swatch-list li.active a:before {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #fff;
  font-family: "porto";
  font-size: 1.1rem;
  line-height: 1;
  content: "";
}
.config-swatch-list a:hover .color-panel,
.config-swatch-list a:focus .color-panel,
.config-swatch-list li.active .color-panel {
  box-shadow: 0 0 0 0.1rem #dfdfdf;
}

.modal#addCartModal {
  width: 340px;
  top: calc((100% - 320px) / 2);
  left: calc((100% - 320px) / 2);
  padding: 10px !important;
  overflow: hidden;
}
.modal#addCartModal .modal-dialog {
  margin: 0;
}
.modal#addCartModal .modal-content {
  margin: 0;
  border: none;
  box-shadow: none;
}

.add-cart-box {
  padding: 19px 10px 20px !important;
  border-top: 4px solid #08c;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
.add-cart-box h4 {
  font-weight: 500;
  color: #08c;
  margin-bottom: 1.8rem;
}
.add-cart-box img {
  margin: 0 auto 10px;
  width: 120px;
}
.add-cart-box .btn-actions {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-around;
  -ms-flex-pack: distribute;
}
.add-cart-box .btn-actions .btn-primary {
  width: 140px;
  padding: 8px 0;
  font: 500 16px "Open Sans", sans-serif;
  border: none;
  cursor: pointer;
}
.add-cart-box .btn-actions .btn-primary:focus,
.add-cart-box .btn-actions .btn-primary:active,
.add-cart-box .btn-actions .btn-primary:active:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.divide-line > .col-1:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-1:nth-child(12n) {
  border-right: none;
}
.divide-line > .col-2:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-2:nth-child(6n) {
  border-right: none;
}
.divide-line > .col-3:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-3:nth-child(4n) {
  border-right: none;
}
.divide-line > .col-4:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-4:nth-child(3n) {
  border-right: none;
}
.divide-line > .col-5:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-5:nth-child(2n) {
  border-right: none;
}
.divide-line > .col-6:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-6:nth-child(2n) {
  border-right: none;
}
.divide-line > .col-7:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-7:nth-child(1n) {
  border-right: none;
}
.divide-line > .col-8:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-8:nth-child(1n) {
  border-right: none;
}
.divide-line > .col-9:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-9:nth-child(1n) {
  border-right: none;
}
.divide-line > .col-10:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-10:nth-child(1n) {
  border-right: none;
}
.divide-line > .col-11:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-11:nth-child(1n) {
  border-right: none;
}
.divide-line:not(.up-effect) .product-default .btn-quickview {
  width: calc(100% - 30px);
  margin: 0 15px;
}
.divide-line:not(.up-effect) .product-default .product-details {
  padding: 0 1.5rem;
}
.divide-line.up-effect {
  margin-top: -2rem;
}
.divide-line.up-effect .product-default {
  padding-top: 5rem;
  margin: 0;
  transition: 0.3s;
}
.divide-line.up-effect .product-default .product-action {
  transition: 0.3s;
  opacity: 0;
}
.divide-line.up-effect .product-default:hover {
  padding-top: 1rem;
  padding-bottom: 4rem;
}
.divide-line.up-effect .product-default:hover .product-action {
  opacity: 1;
}
.divide-line .product-default {
  margin-bottom: 0;
}
.divide-line .product-default:hover {
  box-shadow: 0 25px 35px -5px rgba(0, 0, 0, 0.1);
}
.divide-line .product-default:hover figure {
  box-shadow: none;
}

.inner-quickview figure {
  position: relative;
}
.inner-quickview figure .btn-quickview {
  position: absolute;
  padding: 0.8rem 1.4rem;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  color: #fff;
  background-color: #379397;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  visibility: hidden;
  opacity: 0;
  transform: none;
  margin: 0;
  border: none;
  line-height: 1.82;
  transition: padding-top 0.2s, padding-bottom 0.2s;
  z-index: 2;
  text-align: center;
}
.inner-quickview figure .btn-quickview:hover {
  color: #fff;
  opacity: 1;
}
.inner-quickview .product-details {
  align-items: flex-start;
  -ms-flex-align: start;
}
.inner-quickview .category-wrap,
.inner-quickview .title-wrap {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  -ms-flex-pack: justify;
  align-items: center;
  -ms-flex-align: center;
  width: 100%;
}
.inner-quickview .category-wrap .btn-icon-wish,
.inner-quickview .title-wrap .btn-icon-wish {
  transform: none;
  opacity: 1;
  width: auto;
  height: auto;
  border: none;
  overflow: visible;
  font-size: 1.5rem;
  line-height: 0;
}
.inner-quickview .category-list {
  text-align: left;
}
.inner-quickview .category-wrap .btn-icon-wish {
  font-size: 1.6rem;
  padding-top: 1px;
}
.inner-quickview:hover .btn-quickview {
  visibility: visible;
  opacity: 0.85;
}

.inner-icon {
  position: relative;
  margin-bottom: 1.9rem;
}
.inner-icon:not(.product-widget) .product-details {
  padding: 0;
}
.inner-icon .category-list {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 20px);
  text-align: left;
}
.inner-icon .product-title {
  font-family: Poppins, sans-serif;
  letter-spacing: -0.01em;
}
.inner-icon .ratings-container {
  margin-left: 0;
}
.inner-icon .price-box {
  margin-bottom: 1.5rem;
  font-family: "Open Sans", sans-serif;
}
.inner-icon .btn-icon-group {
  z-index: 2;
}
.inner-icon .btn-icon-wish,
.inner-icon .btn-quickview {
  top: auto;
}
.inner-icon .btn-icon-wish {
  left: auto;
  right: 0;
}
.inner-icon:not(.product-widget):hover {
  box-shadow: none;
}
.inner-icon:not(.product-widget):hover figure .btn-quickview {
  padding-top: 1.2rem;
  padding-bottom: 1.3rem;
  transition: padding-top 0.2s, padding-bottom 0.2s, opacity 0.2s;
}
.inner-icon .btn-add-cart,
.inner-icon .btn-icon-wish,
.inner-icon .btn-quickview {
  background-color: transparent;
}
.inner-icon figure {
  position: relative;
}
.inner-icon figure .btn-icon-group {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.inner-icon figure .btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 50%;
  margin: 0 0 5px;
  width: 36px;
  height: 36px;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s, opacity 0.3s, background-color 0.3s, color 0.3s,
    border-color 0.3s;
  transform: none;
}
.inner-icon figure .btn-icon i:not(.fa)::before {
  font-weight: 400;
}
.inner-icon figure .btn-icon .fa {
  font-size: 12px;
  font-weight: 600;
}
.inner-icon figure .btn-icon i {
  font-size: 1.6rem;
  margin-bottom: 0;
}
.inner-icon figure .btn-icon i:before {
  margin: 0;
}
.inner-icon figure .btn-icon i.icon-bag {
  font-size: 1.8rem;
}
.inner-icon figure .btn-icon:hover {
  background-color: #08c;
  border-color: #08c;
  color: #fff;
}
.inner-icon:hover .btn-icon {
  background-color: #fff;
  border-color: #ddd;
  color: black;
  visibility: visible;
  opacity: 1;
}

.left-details .product-details {
  align-items: flex-start;
}
.left-details .btn-icon-wish,
.left-details .btn-quickview,
.left-details .btn-add-cart {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: black;
}
.left-details .btn-icon-wish,
.left-details .btn-quickview {
  transform: none;
}
.left-details .btn-add-cart {
  margin-left: 0;
  padding: 0 1.5rem;
}

.hidden-description {
  position: relative;
}
.hidden-description:hover figure {
  box-shadow: none;
}
.hidden-description:hover .btn-add-cart {
  background-color: #f4f4f4;
}
.hidden-description:hover .product-details {
  opacity: 1;
  transform: translateY(0);
}
.hidden-description figure {
  margin-bottom: 0;
}
.hidden-description figure .btn-icon-group {
  top: 1rem;
  right: 1rem;
}
.hidden-description .product-details {
  position: absolute;
  width: 100%;
  bottom: 46px;
  padding: 15px 20px 0;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  opacity: 0;
  transform: translateY(5px);
  transition: all 0.3s ease;
}
.hidden-description .product-action {
  position: absolute;
  left: 0;
  bottom: -46px;
  width: 100%;
}
.hidden-description .btn-quickview {
  transform: none;
  opacity: 0.85;
  background-color: #08c;
  color: #fff;
  width: 50%;
  margin: 0;
  border: none;
  height: 45px;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}
.hidden-description .btn-quickview:hover {
  opacity: 1;
  color: #fff;
}
.hidden-description .btn-add-cart {
  z-index: 3;
  justify-content: center;
  margin: 0;
  width: 50%;
  height: 45px;
  border: none;
  background: #f4f4f4;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}
.hidden-description .btn-add-cart:hover {
  background-color: #08c;
  color: #fff;
}

.full-width {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.full-width [class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

.no-gaps {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
}
.no-gaps [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.no-gaps .product-details {
  padding: 0 1rem;
}
.no-gaps .product-default {
  margin-bottom: 0;
}
.no-gaps .product-default:nth-child(even) figure > a:first-child:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 37, 41, 0.01);
}

.inner-icon-inline figure .btn-icon-group {
  display: flex;
  flex-direction: row;
}
.inner-icon-inline figure .btn-icon {
  margin-left: 5px;
}

.overlay figure {
  margin: 0;
}
.overlay figure > a:first-child:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(27, 27, 23, 0);
  transition: all 0.25s;
}
.overlay figure .btn-quickview,
.overlay figure .btn-icon-group {
  z-index: 1;
}
.overlay figure .btn-icon {
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  background-color: #4d4d4a;
  opacity: 0;
}
.overlay .product-details {
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.4s;
}
.overlay .product-details .product-category,
.overlay .product-details .product-title a,
.overlay .product-details .product-price {
  color: #fff;
}
.overlay .product-details a:hover {
  color: #08c;
}
.overlay .product-details .ratings-container .product-ratings:before {
  color: rgba(255, 255, 255, 0.6);
}
.overlay .product-details .price-box {
  margin-bottom: 0;
}
.overlay:hover figure,
.overlay:nth-child(even):hover figure {
  box-shadow: none;
}
.overlay:hover figure > a:first-child:after,
.overlay:nth-child(even):hover figure > a:first-child:after {
  background-color: rgba(27, 27, 23, 0.6);
}
.overlay:hover figure .btn-quickview,
.overlay:hover figure .btn-icon,
.overlay:nth-child(even):hover figure .btn-quickview,
.overlay:nth-child(even):hover figure .btn-icon {
  opacity: 0.85;
  visibility: visible;
}
.overlay:hover figure .btn-quickview:hover,
.overlay:hover figure .btn-icon:hover,
.overlay:nth-child(even):hover figure .btn-quickview:hover,
.overlay:nth-child(even):hover figure .btn-icon:hover {
  opacity: 1;
}
.overlay:hover figure .btn-icon,
.overlay:nth-child(even):hover figure .btn-icon {
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  background-color: #4d4d4a;
  opacity: 0.85;
}
.overlay:hover .product-details,
.overlay:nth-child(even):hover .product-details {
  opacity: 1;
  transform: scale(1);
}

.overlay-dark figure {
  margin: 0;
}
.overlay-dark figure > a:first-child:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(27, 27, 23, 0.3);
  transition: all 0.25s;
}
.overlay-dark figure .btn-quickview,
.overlay-dark figure .btn-icon-group {
  z-index: 1;
}
.overlay-dark figure .btn-icon {
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  background-color: #4d4d4a;
  opacity: 0;
  margin-left: 8px;
}
.overlay-dark figure .btn-quickview {
  border: 2px solid #fff;
  background-color: #4d4d4a;
  border-radius: 2rem;
  padding: 1rem 2.3rem;
  width: auto;
  height: auto;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  opacity: 0;
  transition: all 0.1s;
}
.overlay-dark .product-details {
  position: absolute;
  width: 100%;
  left: 2rem;
  bottom: 4rem;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.4s;
}
.overlay-dark .product-details .product-category,
.overlay-dark .product-details .product-title a,
.overlay-dark .product-details .product-price {
  color: #fff;
}
.overlay-dark .product-details a:hover {
  color: #08c;
}
.overlay-dark .product-details .ratings-container .product-ratings:before {
  color: rgba(255, 255, 255, 0.6);
}
.overlay-dark .product-details .price-box {
  margin-bottom: 0;
}
.overlay-dark:hover figure,
.overlay-dark:nth-child(even):hover figure {
  box-shadow: none;
}
.overlay-dark:hover figure > a:first-child:after,
.overlay-dark:nth-child(even):hover figure > a:first-child:after {
  background-color: rgba(27, 27, 23, 0.7);
}
.overlay-dark:hover figure .btn-quickview,
.overlay-dark:hover figure .btn-icon,
.overlay-dark:nth-child(even):hover figure .btn-quickview,
.overlay-dark:nth-child(even):hover figure .btn-icon {
  opacity: 0.85;
}
.overlay-dark:hover figure .btn-quickview:hover,
.overlay-dark:hover figure .btn-icon:hover,
.overlay-dark:nth-child(even):hover figure .btn-quickview:hover,
.overlay-dark:nth-child(even):hover figure .btn-icon:hover {
  background-color: #4d4d4a;
  opacity: 1;
}
.overlay-dark:hover figure .btn-icon,
.overlay-dark:nth-child(even):hover figure .btn-icon {
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  background-color: #4d4d4a;
  opacity: 0.85;
}
.overlay-dark:hover .product-details,
.overlay-dark:nth-child(even):hover .product-details {
  opacity: 1;
  transform: translateY(0);
}

.creative-grid > div:not(:last-child) .product-default {
  height: 600px;
}
.creative-grid > div:last-child .product-default {
  height: 300px;
}
.creative-grid .product-default {
  padding-bottom: 2rem;
  margin-bottom: 0;
  position: relative;
}
.creative-grid .product-default figure {
  height: 100%;
}
.creative-grid .product-default figure img {
  position: absolute;
  object-fit: cover;
  height: 100%;
  transition: opacity 0.3s linear;
}
.creative-grid .product-default figure img:last-child {
  opacity: 0;
}
.creative-grid .product-default figure img:first-child {
  opacity: 1;
}
.creative-grid .product-default:hover figure img:first-child {
  opacity: 0;
}
.creative-grid .product-default:hover figure img:last-child {
  opacity: 1;
}

.inner-btn figure .btn-icon-group {
  top: auto;
  left: auto;
  right: 1.5rem;
  bottom: 1.5rem;
}
.inner-btn figure .btn-icon {
  position: relative;
  margin-bottom: 0;
}
.inner-btn figure .btn-quickview {
  background-color: #fff;
}
.inner-btn figure .btn-quickview i {
  font-size: 1.4rem;
}

.quantity-input .product-details {
  align-items: center;
}
.quantity-input .product-single-qty {
  margin: 0 0 1rem;
}
.quantity-input .btn-add-cart {
  margin: 0 0 1rem 2px;
}
.quantity-input .btn-add-cart:hover {
  background-color: #08c;
  border-color: #08c;
  color: #fff;
}

.product-list {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
.product-list:not(.inner-icon) .btn-add-cart:not(.product-type-simple) i {
  display: block;
}
.product-list .product-action {
  margin-bottom: 0;
}
.product-list:hover .btn-icon {
  padding-right: 0.8rem;
  transition: 0.35s;
}
.product-list:hover .btn-icon i {
  opacity: 1;
  transition: 0.35s;
}
.product-list:hover .btn-icon span {
  padding-left: 1.3rem;
  transition: 0.35s;
}
.product-list figure {
  max-width: 250px;
  margin-right: 1.2rem;
  margin-bottom: 0;
}
.product-list figure img {
  object-fit: cover;
  height: 100%;
}
.product-list .product-details {
  padding-top: 3px;
  max-width: calc(100% - 270px);
}
.product-list .product-title {
  margin-bottom: 0.6rem;
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif;
}
.product-list .ratings-container {
  margin: 0 0 10px 0px;
}
.product-list .product-description {
  display: -webkit-box;
  margin-bottom: 1.6rem;
  max-width: 100%;
  font-weight: 400;
  font-size: 1.4rem;
  font-family: "Open Sans", sans-serif;
  line-height: 24px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-list .price-box {
  margin-bottom: 1.6rem;
}
.product-list .category-list {
  margin-bottom: -1px;
}
.product-list .btn-add-cart {
  margin: 0 3px 5px 0;
  padding: 0 1.4rem;
  background-color: #08c;
  border-color: #08c;
  color: #fff;
}
.product-list .btn-icon {
  position: relative;
  transition: 0.35s;
}
.product-list .btn-icon i {
  position: absolute;
  display: inline-block;
  opacity: 0;
  left: 8px;
  top: 26%;
  transition: 0.35s;
}
.product-list .btn-icon i::before {
  margin: 0;
  line-height: 1;
  font-weight: 800;
}
.product-list .btn-icon span {
  display: inline-block;
  transition: 0.35s;
}
.product-list .btn-icon-wish,
.product-list .btn-quickview {
  position: static;
  opacity: 1;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  color: #333333;
  margin: 0 0 5px;
  line-height: 32px;
}
.product-list .btn-icon-wish {
  position: relative;
}
.product-list:hover {
  box-shadow: none;
}
.product-list:hover figure {
  box-shadow: none;
}
.product-list:hover .product-action a.btn-icon-wish {
  left: 0;
}

.product-widget {
  display: flex;
  display: -ms-flexbox;
  margin-bottom: 1.6rem;
}
.product-widget figure {
  max-width: 84px;
  margin-right: 1rem;
  margin-bottom: 0;
}
.product-widget figure img {
  object-fit: cover;
  height: 100%;
}
.product-widget .ratings-container {
  margin-bottom: 1rem;
}
.product-widget .product-details {
  margin-bottom: 2px;
  max-width: calc(100% - 104px);
}
.product-widget .product-title {
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
}
.product-widget .price-box {
  margin-bottom: 0;
}
.product-widget .product-price {
  font-size: 1.5rem;
}
.product-widget .old-price {
  font-size: 1.2rem;
}
.product-widget:hover,
.product-widget:hover figure {
  box-shadow: none;
}

.product-quick-view {
  padding: 3rem;
  background-color: #fff;
}
.product-quick-view .product-single-filter label {
  margin-right: 0;
}
.product-quick-view .product-single-details .product-title {
  width: 100%;
}
.product-quick-view .view-cart {
  padding: 13px 10px;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
}
.product-quick-view .product-single-details .product-single-filter:last-child {
  margin-left: -1px;
}

.image-bg-white {
  filter: brightness(1.08);
}

.post-slider > .owl-stage-outer,
.products-slider > .owl-stage-outer {
  margin: -10px -20px;
  padding: 10px 20px;
}

@media (max-width: 1280px) {
  .post-slider > .owl-stage-outer,
  .products-slider > .owl-stage-outer {
    margin: -10px -15px;
    padding: 10px 15px;
  }
}
.modal-backdrop.show {
  opacity: 0;
}

@media (max-width: 1200px) {
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-prev {
    left: 10px;
  }
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-next {
    right: 10px;
  }
}
@media (max-width: 1159px) {
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-prev {
    left: -30px;
  }
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-next {
    right: -30px;
  }
}
@media (max-width: 1000px) {
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-prev {
    left: 10px;
  }
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-next {
    right: 10px;
  }
}
@media (min-width: 576px) {
  .divide-line > .col-sm-1:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-1:nth-child(12n) {
    border-right: none;
  }

  .divide-line > .col-sm-2:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-2:nth-child(6n) {
    border-right: none;
  }

  .divide-line > .col-sm-3:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-3:nth-child(4n) {
    border-right: none;
  }

  .divide-line > .col-sm-4:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-4:nth-child(3n) {
    border-right: none;
  }

  .divide-line > .col-sm-5:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-5:nth-child(2n) {
    border-right: none;
  }

  .divide-line > .col-sm-6:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-6:nth-child(2n) {
    border-right: none;
  }

  .divide-line > .col-sm-7:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-7:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-sm-8:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-8:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-sm-9:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-9:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-sm-10:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-10:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-sm-11:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-11:nth-child(1n) {
    border-right: none;
  }
}
@media (min-width: 768px) {
  .divide-line > .col-md-1:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-1:nth-child(12n) {
    border-right: none;
  }

  .divide-line > .col-md-2:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-2:nth-child(6n) {
    border-right: none;
  }

  .divide-line > .col-md-3:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-3:nth-child(4n) {
    border-right: none;
  }

  .divide-line > .col-md-4:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-4:nth-child(3n) {
    border-right: none;
  }

  .divide-line > .col-md-5:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-5:nth-child(2n) {
    border-right: none;
  }

  .divide-line > .col-md-6:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-6:nth-child(2n) {
    border-right: none;
  }

  .divide-line > .col-md-7:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-7:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-md-8:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-8:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-md-9:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-9:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-md-10:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-10:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-md-11:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-11:nth-child(1n) {
    border-right: none;
  }

  .product-quick-view .product-single-details {
    position: absolute;
    right: 0;
    height: 100% !important;
    overflow-y: auto;
  }
  .product-quick-view .product-single-details::-webkit-scrollbar {
    height: 10px;
    width: 3px;
  }
  .product-quick-view .product-single-details::-webkit-scrollbar-thumb {
    background: #ebebeb;
    border-radius: 10px;
    position: absolute;
  }
  .product-quick-view .product-single-details::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
    margin: 8px;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .divide-line > .col-lg-1:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-1:nth-child(12n) {
    border-right: none;
  }

  .divide-line > .col-lg-2:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-2:nth-child(6n) {
    border-right: none;
  }

  .divide-line > .col-lg-3:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-3:nth-child(4n) {
    border-right: none;
  }

  .divide-line > .col-lg-4:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-4:nth-child(3n) {
    border-right: none;
  }

  .divide-line > .col-lg-5:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-5:nth-child(2n) {
    border-right: none;
  }

  .divide-line > .col-lg-6:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-6:nth-child(2n) {
    border-right: none;
  }

  .divide-line > .col-lg-7:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-7:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-lg-8:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-8:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-lg-9:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-9:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-lg-10:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-10:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-lg-11:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-11:nth-child(1n) {
    border-right: none;
  }
}
@media (min-width: 1200px) {
  .divide-line > .col-xl-1:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-1:nth-child(12n) {
    border-right: none;
  }

  .divide-line > .col-xl-2:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-2:nth-child(6n) {
    border-right: none;
  }

  .divide-line > .col-xl-3:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-3:nth-child(4n) {
    border-right: none;
  }

  .divide-line > .col-xl-4:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-4:nth-child(3n) {
    border-right: none;
  }

  .divide-line > .col-xl-5:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-5:nth-child(2n) {
    border-right: none;
  }

  .divide-line > .col-xl-6:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-6:nth-child(2n) {
    border-right: none;
  }

  .divide-line > .col-xl-7:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-7:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-xl-8:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-8:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-xl-9:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-9:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-xl-10:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-10:nth-child(1n) {
    border-right: none;
  }

  .divide-line > .col-xl-11:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-11:nth-child(1n) {
    border-right: none;
  }

  .col-xl-7col .product-default .product-title,
  .col-xl-8col .product-default .product-title {
    font-size: 1.3rem;
  }
  .col-xl-7col .old-price,
  .col-xl-8col .old-price {
    font-size: 1.2rem;
  }
  .col-xl-7col .product-price,
  .col-xl-8col .product-price {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  .product-list {
    flex-direction: column;
  }
  .product-list figure {
    max-width: none;
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .product-list .product-details {
    max-width: none;
    width: 100%;
  }
  .product-list:not(.inner-icon) .btn-add-cart:not(.product-type-simple) i {
    display: block;
  }
  .product-list .product-action > a {
    padding: 0;
    width: 34px;
    height: 34px;
  }
  .product-list .btn-icon {
    margin-right: 1px;
  }
  .product-list .btn-icon i {
    position: static;
    opacity: 1;
  }
  .product-list .btn-icon:hover {
    padding: 0;
  }
  .product-list .btn-icon span {
    display: none;
  }
  .product-list .btn-icon:not(.product-type-simple) i {
    margin-top: 1.1rem;
  }

  .product-quick-view {
    padding: 2rem;
  }
  .product-quick-view .product-single-details .product-title {
    font-size: 2.6rem;
  }
}
@media (max-width: 479px) {
  .product-price {
    font-size: 1.3rem;
  }

  .product-quick-view {
    padding: 2rem;
  }
}
.product-category-panel {
  margin-bottom: 35px;
}
.product-category-panel .owl-carousel {
  margin-top: -10px;
  padding-top: 10px;
}
.product-category-panel .owl-carousel .owl-nav button.owl-prev,
.product-category-panel .owl-carousel .owl-nav button.owl-next {
  width: 30px;
  font-size: 24px;
  color: #333;
  line-height: 22px;
}
.product-category-panel .owl-carousel .owl-nav button.owl-prev {
  left: -41px;
}
.product-category-panel .owl-carousel .owl-nav button.owl-next {
  right: -41px;
}
.product-category-panel .section-title {
  padding-bottom: 1rem;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 2.5rem;
}
.product-category-panel .section-title h2 {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.2;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.05em;
  color: #282d3b;
  text-transform: uppercase;
}

.product-category {
  color: #1d2127;
  margin-bottom: 2rem;
  position: relative;
}
.product-category a:hover {
  color: inherit;
}
.product-category img,
.product-category figure:after {
  border-radius: 50%;
}
.product-category figure {
  margin-bottom: 0;
  position: relative;
}
.product-category figure:after {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent;
  transition: all 0.3s;
  z-index: 1;
  content: "";
}
.product-category:hover figure:after {
  background-color: rgba(27, 27, 23, 0.15);
}
.owl-item > .product-category {
  margin-bottom: 0;
}

.category-content {
  padding: 2rem;
  display: flex;
  display: -ms-flex-box;
  flex-direction: column;
  align-items: center;
  -ms-flex-align: center;
}
.category-content h3 {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.35;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.005em;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.category-content span {
  font-weight: 400;
  font-size: 10.2px;
  line-height: 1.8;
  font-family: "Open Sans", sans-serif;
  letter-spacing: normal;
  margin-top: -10px;
  text-transform: uppercase;
  opacity: 0.7;
}
.category-content span mark {
  padding: 0;
  background-color: transparent;
  color: inherit;
}

.content-left-center .category-content,
.content-left-bottom .category-content,
.content-center .category-content,
.content-center-bottom .category-content {
  padding: 20.4px 25.5px;
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
  z-index: 2;
}
.content-left-center .category-content h3,
.content-left-center .category-content span,
.content-left-bottom .category-content h3,
.content-left-bottom .category-content span,
.content-center .category-content h3,
.content-center .category-content span,
.content-center-bottom .category-content h3,
.content-center-bottom .category-content span {
  color: #fff;
}

.content-left-center .category-content,
.content-center .category-content {
  left: 0;
  top: 50%;
}

.content-left-center .category-content {
  align-items: flex-start;
}

.content-left-bottom .category-content {
  align-items: flex-start;
  left: 0;
  bottom: 0;
  transform: none;
}

.content-center-bottom figure {
  min-height: 90px;
}
.content-center-bottom .category-content {
  bottom: 0;
  transform: none;
  padding: 20.4px 0;
}
.content-center-bottom .category-content h3,
.content-center-bottom .category-content span {
  margin-bottom: 0;
  color: #1d2127;
}

.overlay-lighter figure:after {
  background-color: rgba(27, 27, 23, 0);
}
.overlay-lighter:hover figure:after {
  background-color: rgba(27, 27, 23, 0.15);
}

.overlay-darker figure:after {
  background-color: rgba(27, 27, 23, 0.25);
}
.overlay-darker:hover figure:after {
  background-color: rgba(27, 27, 23, 0.4);
}

.overlay-light figure:after {
  background-color: rgba(27, 27, 23, 0.75);
}
.overlay-light:hover figure:after {
  background-color: rgba(27, 27, 23, 0.6);
}

.hidden-count .category-content span {
  max-height: 10px;
  transition: all 0.5s;
  transform: translateY(20%);
  opacity: 0;
}
.hidden-count:hover .category-content span {
  max-height: 30px;
  transform: none;
  opacity: 0.7;
}

.creative-grid .product-category {
  height: 100%;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.creative-grid .product-category.content-left-bottom .category-content {
  margin-bottom: 20px;
}
.creative-grid .product-category figure {
  height: 100%;
}
.creative-grid .product-category figure img {
  object-fit: cover;
}

.height-600 {
  height: 600px;
}

.height-400 {
  height: 400px;
}

.height-300 {
  height: 300px;
}

.height-200 {
  height: 200px;
}

@media (min-width: 1160px) {
  .col-5col-1 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (max-width: 767px) {
  .height-600 {
    height: 400px;
  }

  .height-300 {
    height: 200px;
  }
}
@media (max-width: 450px) {
  .content-center-bottom .category-content {
    padding: 16.8px 21px;
    text-align: center;
    flex-wrap: wrap;
  }
}
@media (max-width: 400px) {
  .content-center-bottom .category-content {
    padding-bottom: 1rem;
  }
}
@media (max-width: 1200px) {
  .product-category-panel .owl-carousel .owl-nav button.owl-next,
  .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    width: 15px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-next {
    right: -18px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    left: -18px;
  }
}
@media (max-width: 1159px) {
  .product-category-panel .owl-carousel .owl-nav button.owl-next,
  .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    width: 30px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-next {
    right: -41px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    left: -41px;
  }
}
@media (max-width: 1024px) {
  .product-category-panel .owl-carousel .owl-nav button.owl-next,
  .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    width: 15px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-next {
    right: -18px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    left: -18px;
  }
}
.testimonial-owner {
  display: flex;
  -ms-flex-align: center;
  align-content: center;
}
.testimonial-owner figure {
  max-width: 40px;
  margin-right: 25px;
  margin-bottom: 2rem;
}
.testimonial-owner h4 {
  display: block;
  margin-bottom: 0.5rem;
  padding-top: 0.7rem;
  color: #111;
  font-size: 1.4rem;
  text-transform: uppercase;
}
.testimonial-owner span {
  display: block;
  color: #666;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.045em;
  line-height: 1.2;
  font-weight: 600;
}

.testimonial blockquote {
  position: relative;
  margin: 0 0 0 15px;
  padding: 1rem 2rem;
}
.testimonial blockquote:before {
  position: absolute;
  top: 0;
  left: -0.4em;
  color: #08c;
  font-family: "Playfair Display";
  font-size: 5rem;
  font-weight: 900;
  line-height: 1;
  content: "“";
}
.testimonial blockquote p {
  margin-bottom: 0;
  font-family: inherit;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #62615e;
}

@media (max-width: 480px) {
  .testimonial blockquote:before {
    left: -15px;
  }
}
.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #fff;
  background-color: #08c;
  font-size: 14px;
  line-height: 3.2rem;
  text-align: center;
  text-decoration: none;
  opacity: 1;
}
.social-icon + .social-icon {
  margin-left: 0.6rem;
}
.social-icons .social-icon:hover,
.social-icons .social-icon:focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}
.social-icon.social-facebook {
  background-color: #3b5a9a;
}
.social-icon.social-twitter {
  background-color: #131516;
}
.social-icon.social-instagram {
  background-color: #7c4a3a;
}
.social-icon.social-linkedin {
  background-color: #0073b2;
}
.social-icon.social-gplus {
  background-color: #dd4b39;
}
.social-icon.social-mail {
  background-color: #dd4b39;
}

.nav-tabs {
  margin: 0;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 2px;
}
.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs .nav-item .nav-link {
  padding: 1.2rem 0;
  border: 0;
  border-bottom: 2px solid transparent;
  color: #282d3b;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}
.nav-tabs .nav-item .nav-link:hover {
  color: #08c;
}
.nav-tabs .nav-item:not(:last-child) {
  margin-right: 3.5rem;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item .nav-link.active {
  border-bottom-color: #08c;
  color: #08c;
}

.product-single-tabs.product-tabs-list .product-desc-content p {
  margin-bottom: 1.3rem;
}
.product-single-tabs.product-tabs-list .product-desc-content ul,
.product-single-tabs.product-tabs-list .product-desc-content ol {
  padding-left: 5.8rem;
  margin-bottom: 2rem;
}
.product-single-tabs.product-tabs-list .product-desc-content li::before {
  left: 2.4rem;
}

.product-slider-tab .tab-content {
  position: relative;
}
.product-slider-tab .tab-content > .tab-pane {
  display: block !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 0 !important;
  opacity: 0;
  z-index: -1;
  transition: visibility 0.3s, opacity 0.3s;
}
.product-slider-tab .tab-content > .tab-pane:not(.active) {
  overflow: hidden;
  visibility: hidden;
}
.product-slider-tab .tab-content > .active {
  position: relative;
  height: auto !important;
  opacity: 1;
  z-index: auto;
}

@media (min-width: 992px) {
  .product-single-tabs.product-tabs-list {
    padding-bottom: 2px;
  }
  .product-single-tabs.product-tabs-list .col-lg-2 {
    flex: 0 0 21.4%;
    max-width: 21.4%;
  }
  .product-single-tabs.product-tabs-list .col-lg-10 {
    flex: 0 0 78.6%;
    max-width: 78.6%;
  }
  .product-single-tabs.product-tabs-list .nav.nav-tabs {
    flex-direction: column;
    border: none;
  }
  .product-single-tabs.product-tabs-list .nav.nav-tabs .nav-item {
    margin-right: 0;
    margin-bottom: 0.8rem;
    border-bottom: 1px solid #e7e7e7;
  }
  .product-single-tabs.product-tabs-list .nav.nav-tabs .nav-link {
    display: inline-block;
    padding: 1.4rem 0 1.5rem;
    margin-bottom: -1px;
  }
  .product-single-tabs.product-tabs-list .tab-pane {
    padding-top: 0.5rem;
  }
  .product-single-tabs.product-tabs-list .tab-content {
    padding-left: 0.9rem;
  }
}
@media (max-width: 479px) {
  .nav-tabs .nav-item:not(:last-child) {
    margin-right: 2.5rem;
  }
}
.tooltip {
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  width: 1rem;
  height: 1rem;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 1rem 0;
}

.bs-tooltip-top .arrow:before,
.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  margin-left: -0.5rem;
  border-width: 1rem 1rem 0;
  border-top-color: #ddd;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 1rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  width: 1rem;
  height: 2rem;
}

.bs-tooltip-right .arrow:before,
.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-width: 1rem 1rem 1rem 0;
  border-right-color: #ddd;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 1rem 0;
}

.bs-tooltip-bottom .arrow:before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  margin-left: -0.5rem;
  border-width: 0 1rem 1em;
  border-bottom-color: #ddd;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 1rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  width: 1rem;
  height: 1rem;
}

.bs-tooltip-left .arrow:before,
.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-width: 1rem 0 1rem 1rem;
  border-left-color: #ddd;
}

.tooltip-inner {
  max-width: 270px;
  padding: 1.2rem 1.5rem;
  border: 1px solid #ddd;
  border-radius: 0.1rem;
  background-color: #f4f4f4;
  color: #777;
  text-align: left;
}

html {
  overflow-x: hidden;
  font-size: 62.5%;
  font-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  color: #777;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4;
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}
/* body:not(.loaded) > :not(.loading-overlay) {
  visibility: hidden !important;
  transition: none !important;
} */
/* body:not(.loaded) > :not(.loading-overlay) * {
  visibility: hidden !important;
  transition: none !important;
} */

::selection {
  background-color: #08c;
  color: #fff;
}

p {
  margin-bottom: 1.5rem;
}

ul,
ol {
  margin: 0 0 2.25rem;
  padding: 0;
  list-style: none;
}

b,
strong {
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

hr {
  max-width: 1730px;
  margin: 5.5rem auto 5.2rem;
  border: 0;
  border-top: 1px solid #e7e7e7;
}

sub,
sup {
  font-size: 70%;
}

sup {
  font-size: 50%;
}

sub {
  bottom: -0.25em;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

button:focus {
  outline: none;
}

body.modal-open {
  padding-right: 0 !important;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  background: #fff;
  opacity: 1;
  visibility: visible;
  z-index: 999999;
}
.loaded > .loading-overlay {
  opacity: 0;
  visibility: hidden;
}

.bounce-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  margin: -9px 0 0 -35px;
  transition: all 0.2s;
  text-align: center;
  z-index: 10000;
}
.bounce-loader .bounce1,
.bounce-loader .bounce2,
.bounce-loader .bounce3 {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #ccc;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
}
.bounce-loader .bounce1 {
  animation-delay: -0.32s;
}
.bounce-loader .bounce2 {
  animation-delay: -0.16s;
}

.mobile-cart > div::-webkit-scrollbar,
.mobile-sidebar::-webkit-scrollbar,
.custom-srcollbar::-webkit-scrollbar {
  height: 10px;
  width: 6px;
}
.mobile-cart > div::-webkit-scrollbar-thumb,
.mobile-sidebar::-webkit-scrollbar-thumb,
.custom-srcollbar::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 10px;
  position: absolute;
}
.mobile-cart > div::-webkit-scrollbar-track,
.mobile-sidebar::-webkit-scrollbar-track,
.custom-srcollbar::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  margin: 8px;
  width: 100%;
}

.loading:not(.load-more-overlay),
.load-more-overlay.loading::after {
  animation: spin 650ms infinite linear;
  border: 2px solid #fff;
  border-radius: 32px;
  border-top: 2px solid rgba(0, 0, 0, 0.4) !important;
  border-right: 2px solid rgba(0, 0, 0, 0.4) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.4) !important;
  content: "";
  display: block;
  height: 20px;
  top: 50%;
  margin-top: -10px;
  left: 50%;
  margin-left: -10px;
  right: auto;
  position: absolute;
  width: 20px;
  z-index: 3;
}

.load-more-overlay {
  position: relative;
}
.load-more-overlay.loading::after {
  content: "";
}
.load-more-overlay::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.8;
  z-index: 3;
}

.popup-loading-overlay {
  position: relative;
}
.popup-loading-overlay.porto-loading-icon::before {
  content: "";
}
.popup-loading-overlay::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.8;
}

.col-6.fade.in {
  opacity: 1;
  transition: opacity 0.5s;
}

.col-6.fade {
  opacity: 0;
  transition: opacity 0.5s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@media (max-width: 767px) {
  html {
    font-size: 9px;
  }
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: 1.8rem;
  color: #222529;
  font-weight: 700;
  line-height: 1.1;
  font-family: Poppins, sans-serif;
}

h1,
.h1 {
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.223;
}

h2,
.h2 {
  font-size: 3rem;
  line-height: 1.5;
}

h3,
.h3 {
  font-size: 2.5rem;
  line-height: 1.28;
}

h4,
.h4 {
  font-size: 2rem;
  line-height: 1.35;
}

h5,
.h5 {
  font-size: 1.4rem;
  line-height: 1.429;
}

h6,
.h6 {
  font-size: 1.3rem;
  line-height: 1.385;
  font-weight: 600;
}

a {
  transition: all 0.3s;
  color: #08c;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #08c;
  text-decoration: none;
}

.heading {
  margin-bottom: 3rem;
  color: #222529;
}
.heading .title {
  margin-bottom: 1.6rem;
}
.heading p {
  letter-spacing: -0.015em;
}
.heading p:last-child {
  margin-bottom: 0;
}

.light-title {
  margin-bottom: 2rem;
  font-weight: 300;
}

.section-title {
  text-transform: uppercase;
  font-size: 1.8rem;
}

.section-sub-title {
  font-size: 1.6rem;
  text-transform: uppercase;
}

.text-transform-none {
  text-transform: none;
}

.ls-10 {
  letter-spacing: 0.01em !important;
}

.ls-0 {
  letter-spacing: 0 !important;
}

.ls-n-10 {
  letter-spacing: -0.01em !important;
}

.ls-n-15 {
  letter-spacing: -0.015em !important;
}

.ls-n-20 {
  letter-spacing: -0.02em !important;
}

.ls-n-25 {
  letter-spacing: -0.025em !important;
}

.bg-gray {
  background-color: #f4f4f4 !important;
}

.bg-dark {
  background-color: #222529 !important;
}

.bg-dark2 {
  background-color: #2f3946 !important;
}

.bg-primary {
  background-color: #08c !important;
}

.bg-secondary {
  background-color: #ff7272 !important;
}

.text-dark {
  color: #222529 !important;
}

.text-primary {
  color: #08c !important;
}

.text-secondary {
  color: #ff7272 !important;
}

.text-body {
  color: #777 !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.font1 {
  font-family: "Open Sans", sans-serif !important;
}

.font2 {
  font-family: Poppins, sans-serif !important;
}

.font3 {
  font-family: "Segoe Script", "Savoye LET" !important;
}

.font4 {
  font-family: Oswald, sans-serif !important;
}

.title-decorate::before,
.title-decorate::after {
  content: "";
  top: 50%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.08);
  flex: 1;
}

.title-decorate::before {
  margin-right: 2rem;
}

.title-decorate::after {
  margin-left: 2rem;
}

.box-shadow-none {
  box-shadow: none !important;
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .w-md-100 {
    width: 100% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }
}
@media (max-width: 575px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }
}
@media (max-width: 479px) {
  .w-xs-100 {
    width: 100% !important;
  }
}
@media (max-width: 480px) {
  .w-xs-100 {
    width: 100% !important;
  }
}
@media (max-width: 400px) {
  .w-xxs-100 {
    width: 100% !important;
  }
}
.round-images img {
  border-radius: 50%;
}

@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 4.5rem;
  }

  h2,
  .h2 {
    font-size: 2.5rem;
  }

  .heading {
    margin-bottom: 4rem;
  }
}
@media (min-width: 992px) {
  h1,
  .h1 {
    font-size: 5rem;
  }

  h2,
  .h2 {
    font-size: 3rem;
  }

  .heading {
    margin-bottom: 5rem;
  }

  .w-lg-max {
    flex: 1;
    -ms-flex: 1;
    max-width: 100%;
  }
}
.page-wrapper {
  position: relative;
  left: 0;
  transition: all 0.25s;
}

.main {
  flex: 1 1 auto;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

[class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row-sparse {
  margin-left: -15px;
  margin-right: -15px;
}
.row-sparse > [class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

.row-sm {
  margin-left: -6px;
  margin-right: -6px;
}
.row-sm > [class*="col-"] {
  padding-left: 6px;
  padding-right: 6px;
}

.row-joined {
  margin-left: 0;
  margin-right: 0;
}
.row-joined > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.container :not(.sticky-header) > .container,
.container :not(.sticky-header) > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.ajax-overlay {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  background-color: #0b0b0b;
  z-index: 1055;
}

@media (max-width: 1280px) {
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1220px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1200px) {
  .col-xl-5col {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xl-5col-2 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-xl-7col {
    flex: 0 0 14.2857%;
    max-width: 14.2857%;
  }

  .col-xl-8col {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .row-xl-tight {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row-xl-tight > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .row-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row-lg [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 991px) {
  .container {
    max-width: none;
  }

  .mmenu-active .page-wrapper,
  .sidebar-opened .page-wrapper {
    left: 260px;
  }
}
@media (min-width: 768px) {
  .row-md-tight {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row-md-tight > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.owl-carousel .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-carousel .owl-dots .owl-dot span {
  width: 16px;
  height: 16px;
  border-width: 2px;
}
.owl-carousel .owl-dots .owl-dot span:before {
  margin: 0;
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%);
}
.owl-carousel .owl-dots .owl-dot:hover span:before,
.owl-carousel .owl-dots .owl-dot.active span:before {
  transform: translate(-50%, -50%);
}
.owl-carousel.dots-m-0 .disabled + .owl-dots {
  margin: 0;
}
.owl-carousel.dots-mt-1 .disabled + .owl-dots {
  margin-top: 1rem;
}
.owl-carousel.nav-big .owl-nav {
  font-size: 3.7rem;
}
.owl-carousel.nav-big .owl-nav i {
  padding: 4px 7px;
}
.owl-carousel.nav-large .owl-nav {
  font-size: 4.5rem;
}
.owl-carousel.nav-large .owl-nav i {
  padding: 4px 2px;
}
.owl-carousel.nav-image-center .owl-nav button {
  top: 35%;
}
.owl-carousel.show-nav-hover .owl-nav {
  opacity: 0;
  transition: opacity 0.2s, color 0.2s;
}
.owl-carousel.show-nav-hover:hover .owl-nav {
  opacity: 1;
}
.owl-carousel .owl-nav .owl-prev {
  left: 1vw;
}
.owl-carousel .owl-nav .owl-next {
  right: 1vw;
}
@media (min-width: 992px) {
  .owl-carousel.nav-outer .owl-prev {
    left: -1.7vw;
  }
  .owl-carousel.nav-outer .owl-next {
    right: -1.7vw;
  }
  .owl-carousel.nav-outer.nav-large .owl-prev {
    left: -2.3vw;
  }
  .owl-carousel.nav-outer.nav-large .owl-next {
    right: -2.3vw;
  }
}
.owl-carousel.dots-top .owl-dots {
  position: absolute;
  right: 0;
  bottom: 100%;
  margin: 0 0 3.4rem;
}
.owl-carousel.dots-small .owl-dots span {
  width: 14px;
  height: 14px;
}
.owl-carousel.dots-small .owl-dots span:before {
  width: 4px;
  height: 4px;
}
.owl-carousel.dots-simple .owl-dots .owl-dot.active span::before {
  background-color: #222529;
}
.owl-carousel.dots-simple .owl-dots .owl-dot span {
  margin: 1px 1px 2px 0px;
  border: none;
}
.owl-carousel.dots-simple .owl-dots .owl-dot span::before {
  opacity: 1;
  visibility: visible;
  background-color: #d6d6d6;
}
.owl-carousel.images-center img {
  width: auto;
  margin: auto;
}

.dots-left .owl-dots {
  text-align: left;
}

.owl-carousel-lazy {
  display: block;
}
.owl-carousel-lazy .home-slide:first-child,
.owl-carousel-lazy .category-slide:first-child,
.owl-carousel-lazy .owl-item:first-child .home-slide,
.owl-carousel-lazy .owl-item:first-child .category-slide {
  display: block;
}
.owl-carousel-lazy:not(.owl-loaded) > *:not(:first-child) {
  display: none;
}

.home-slide,
.category-slide {
  width: 100%;
  position: relative;
  background-color: #ccc;
}
.home-slide:after,
.home-slide:before,
.category-slide:after,
.category-slide:before {
  position: absolute;
  top: calc(50% - 2rem);
  left: calc(50% - 2rem);
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: #08c;
  border-image: none;
  content: "";
}
.home-slide:before,
.category-slide:before {
  animation: spin 1s infinite ease;
  z-index: 4;
}
.home-slide:after,
.category-slide:after {
  animation: spin 0.5s infinite linear;
  z-index: 5;
}
.owl-carousel.loaded .home-slide::after,
.owl-carousel.loaded .home-slide::before,
.owl-carousel.loaded .category-slide::after,
.owl-carousel.loaded .category-slide::before {
  display: none;
}

div.slide-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

img.slide-bg {
  object-fit: cover;
  object-position: center top;
}

.owl-carousel.dot-inside .owl-dots {
  position: absolute;
  right: 3.6rem;
  left: 3.6rem;
  bottom: 4.1rem;
  text-align: center;
}

.noUi-target {
  background: #eee;
}

.noUi-handle {
  background: #08c;
}

.noUi-connect {
  background: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.38) inset;
}

.sticky-header {
  transition: left 0.25s;
}
.sticky-header.fixed {
  display: flex;
  position: fixed;
  right: 0;
  left: 0;
  width: 100%;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
  z-index: 1040;
}
.sticky-header.fixed .logo {
  max-width: 89px;
}
.sticky-header.header-middle.fixed {
  height: 60px;
}
.sticky-header.deprecated-sticky:not(.fixed) .logo,
.sticky-header.deprecated-sticky:not(.fixed) .cart-dropdown {
  display: none;
}
.sticky-header.deprecated-sticky.fixed .main-nav {
  width: auto;
}
.main .sticky-header:not(.fixed) > .container {
  padding-left: 0;
  padding-right: 0;
}
.main .sticky-header.fixed {
  min-height: 38px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 997;
}
.main .sticky-header.fixed .container {
  margin: 0 auto;
}
.main .sticky-header.toolbox.fixed {
  padding: 1.5rem;
  padding-right: 1.5rem !important;
  background-color: #f4f4f4;
  margin: 0 !important;
}
.main .sticky-header.toolbox.fixed .toolbox-left {
  margin-right: 3rem;
}
.sidebar-opened .sticky-header.fixed:not(.sticky-header),
.mmenu-active .sticky-header.fixed {
  left: 260px;
  transition: left 0.25s;
}

.sticky-navbar {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 100%;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e7e7e7;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
  z-index: 997;
}
.sticky-navbar.fixed {
  opacity: 1;
  visibility: visible;
  transform: translateY(-100%);
}
.sidebar-opened .sticky-navbar.fixed,
.mmenu-active .sticky-navbar.fixed {
  left: 260px;
  transition: all 0.25s;
}
.sticky-navbar .sticky-info {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 1rem 0;
}
.sticky-navbar .sticky-info:not(:last-child) {
  border-right: 1px solid #e7e7e7;
}
.sticky-navbar .sticky-info a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #222529;
  font-family: Poppins, sans-serif;
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
}
.sticky-navbar .sticky-info i {
  font-size: 27px;
  font-weight: 400;
}
.sticky-navbar .sticky-info i span {
  font-style: normal;
  right: -4px;
  top: 3px;
}

@media (min-width: 576px) {
  .sticky-navbar {
    display: none;
  }
}
@media (max-width: 991px) {
  .sidebar-opened .sticky-header.fixed {
    left: 260px;
    transition: left 0.25s;
  }

  .main .sticky-header.toolbox.fixed .toolbox-left {
    margin-right: 0;
  }
}
header {
  position: relative;
}
header .container,
header .container-fluid {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -ms-flex-pack: justify;
  position: relative;
}
header p {
  margin-bottom: 0;
}
header a {
  color: inherit;
}

.header-middle {
  color: #222529;
}
.header-left,
.header-center,
.header-right {
  display: flex;
  align-items: center;
}

.header-center,
.header-right {
  margin-left: auto;
}

.header-bottom .header-center {
  width: 100%;
}
.header-bottom:not(.fixed) .header-left,
.header-bottom:not(.fixed) .header-right {
  display: none;
}
.header-bottom .header-right {
  padding-right: 2.6rem;
  color: #222529;
}
.header-bottom.fixed .header-center {
  margin-left: 4.7rem;
  width: auto;
}
.header-bottom.fixed .main-nav .float-right {
  display: none;
}
.header-bottom.fixed .logo {
  max-width: 89px;
}
.header-bottom.fixed .header-search-inline.header-search {
  margin-right: 2.3rem;
}
.header-bottom.fixed .header-search-inline.header-search i {
  font-size: 2.3rem;
}
.header-bottom.fixed
  .header-search-inline.header-search
  .header-search-wrapper {
  display: flex;
  position: absolute;
  margin-top: 10px;
  border: 5px solid #08c;
}
.header-bottom.fixed
  .header-search-inline.header-search:not(.show)
  .header-search-wrapper {
  display: none;
}
.header-bottom.fixed .header-search-inline.header-search .search-toggle,
.header-bottom.fixed
  .header-search-inline.header-search
  .header-search-inline
  .btn:after {
  display: block;
}

.header-transparent {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1040;
}

.logo {
  max-width: 111px;
}

.header-contact {
  align-items: center;
  text-transform: uppercase;
}
.header-contact i {
  margin-right: 1.1rem;
  font-size: 2.9rem;
}
.header-contact img {
  margin-right: 0.7rem;
}
.header-contact h6 {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.3;
  color: #777;
}
.header-contact a {
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
  white-space: nowrap;
}

.mobile-menu-toggler {
  border: 0;
  background: transparent;
  color: #222529;
  padding: 1rem 0.5rem;
  margin-right: 0.5rem;
  font-size: 20px;
  line-height: 0;
  cursor: pointer;
}

.badge-circle {
  position: absolute;
  top: -9px !important;
  right: 0;
  width: 1.6rem;
  border-radius: 50%;
  color: #fff;
  background: #379397 !important;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}

header .social-icon {
  border-radius: 50%;
  font-size: 13px;
  width: 2.6rem;
  height: 2.6rem;
  line-height: 2.6rem;
}
header .social-icon:not(:hover):not(:active):not(:focus) {
  background-color: transparent;
}
header .social-icon + .social-icon {
  margin-left: 3px;
}
header .separator {
  height: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.04);
  margin: 0 14px;
}

.top-notice {
  position: relative;
  padding: 0.9rem 2rem 1.1rem;
  font-size: 1.3rem;
  line-height: 2;
  letter-spacing: 0.025em;
}
.top-notice h5 {
  color: inherit;
  font-size: inherit;
  font-weight: 500;
}
.top-notice small {
  font-size: 0.8461em;
  letter-spacing: 0.025em;
  opacity: 0.5;
}
.top-notice a {
  color: inherit;
  font-weight: 700;
}
.top-notice .category {
  display: inline-block;
  padding: 0.3em 0.8em;
  background: #0075af;
  font-size: 1rem;
}
.top-notice .mfp-close {
  top: 50%;
  transform: translateY(-50%) rotateZ(45deg) translateZ(0);
  color: inherit;
  opacity: 0.7;
  z-index: 10;
}
.top-notice .mfp-close:hover {
  opacity: 1;
}

.header-icon:not(:last-child) {
  margin-right: 2.2rem;
}
.header-icon i {
  font-size: 2.7rem;
}
.header-icon:hover {
  color: #222529;
}

.minicart-icon {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 20px;
  border: 2px solid #222529;
  border-radius: 0 0 5px 5px;
  position: relative;
  opacity: 0.9;
  margin: 6px 3px 0;
  z-index: 1;
}
.minicart-icon::before {
  content: "";
  position: absolute;
  border: 2px solid;
  border-color: inherit;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  left: 50%;
  top: -8px;
  margin-left: -7px;
  width: 15px;
  height: 11px;
}

.cart-count {
  z-index: 1;
}

@media (min-width: 992px) {
  .mobile-menu-toggler {
    display: none;
  }
}
@media (max-width: 991px) {
  .main-nav {
    display: none;
  }

  .minicart-icon {
    width: 24px;
  }

  .header-search i {
    font-size: 2.4rem;
  }
}
@media (max-width: 767px) {
  .header .separator {
    display: none;
  }

  .logo {
    max-width: 100px;
  }
}
@media (max-width: 575px) {
  .top-notice {
    line-height: 1.5;
  }

  .logo {
    max-width: 90px;
  }

  .header-icon:not(:last-child) {
    margin-right: 1.2rem;
  }
}
.dropdownmenu-wrapper {
  position: relative;
  background-color: #fff;
}

.dropdown-toggle i {
  font-size: 2.8rem;
}
.dropdown-toggle:not(.dropdown-arrow):after {
  display: none;
}

.dropdown-arrow:after {
  content: "";
  border: 0;
  margin: -5px 2px 0 11px;
  font-size: 15px;
  font-family: porto;
  width: auto;
  height: auto;
}
.dropdown-arrow .badge-circle {
  top: 3px;
  left: 19px;
  z-index: 2;
}

.cart-dropdown a:hover,
.cart-dropdown a:focus {
  color: inherit;
}
.cart-dropdown .mobile-cart {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  width: 300px;
  margin: 0;
  transform: translate(340px);
  transition: transform 0.2s ease-in-out 0s;
  background-color: #fff;
  z-index: 1050;
  border: 1px solid #f0f0f0;
  border-radius: 0;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
}
.cart-opened .cart-dropdown .mobile-cart {
  transform: none;
}
.cart-dropdown .mobile-cart .btn-close {
  position: absolute;
  left: -4.2rem;
  top: 0.7rem;
  font-size: 3.3rem;
  color: #fff;
  font-weight: 300;
}

.cart-product-info {
  color: #696969;
}

.cart-opened .cart-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1050;
}

.header-dropdowns {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
}

.header-menu:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  z-index: 1151;
  margin-left: -9px;
  margin-top: -1rem;
  border-width: 0 9px 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
  visibility: hidden;
  opacity: 1;
  transform: translateY(-1rem);
}

.header-dropdown {
  position: relative;
  line-height: 1.5;
  padding: 5px 0;
}
.header-dropdown > a {
  padding: 0 5px;
}
.header-dropdown > a:after {
  content: "";
  font-family: "porto";
  font-weight: 400;
  margin-left: 0.5rem;
}
.header-dropdown img {
  display: inline-block;
  max-width: none;
  margin: -2px 6px 0 1px;
}
.header-dropdown ul {
  position: absolute;
  top: -300rem;
  z-index: 1150;
  min-width: 100%;
  margin: 0;
  padding: 0.5rem 0;
  background: #fff;
  box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-1rem);
}
.header-dropdown ul a {
  display: block;
  padding: 0.5em 1.5em;
  color: #777;
  white-space: nowrap;
}
.header-dropdown ul a:hover {
  background: #f4f4f4;
}
.header-dropdown + .header-dropdown {
  margin-left: 1.4rem;
}
.header-dropdown:hover .header-menu:before,
.header-dropdown:hover ul {
  top: 100%;
  opacity: 1;
  visibility: visible;
  transform: none;
}

.cart-dropdown .dropdownmenu-wrapper {
  padding: 2rem;
  overflow-y: auto;
  height: 100%;
}
.cart-dropdown .dropdownmenu-wrapper:before {
  right: 28px;
  left: auto;
}
.cart-dropdown .dropdownmenu-wrapper:after {
  right: 29px;
  left: auto;
}
.cart-dropdown .product {
  display: flex;
  margin: 0 !important;
  padding: 2rem 0;
  align-items: center;
  border-bottom: 1px solid #e6ebee;
  box-shadow: none !important;
  font-family: Poppins, sans-serif;
}
.cart-dropdown .product-image-container {
  position: relative;
  max-width: 80px;
  width: 100%;
  margin: 0;
  margin-left: auto;
  border: 1px solid #f4f4f4;
}
.cart-dropdown .product-image-container a:after {
  display: none;
}
.cart-dropdown .product-title {
  padding-right: 1.5rem;
  margin-bottom: 1.1rem;
  font-size: 1.4rem;
  line-height: 19px;
  color: #222529;
  font-weight: 500;
}
.cart-dropdown .product-title a {
  color: #222529;
}
.cart-dropdown .product-details {
  margin-bottom: 3px;
  font-size: 1.3rem;
}
.cart-dropdown .btn-remove {
  position: absolute;
  top: -11px;
  right: -9px;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: inherit;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 2rem;
  font-size: 1.8rem;
  font-weight: 500;
}
.cart-dropdown .btn-remove span {
  display: block;
  margin-top: 1px;
}
.cart-dropdown .btn-remove:hover,
.cart-dropdown .btn-remove:focus {
  color: #08c;
}
.sticky-header .cart-dropdown {
  margin-top: 0;
  margin-bottom: 0;
}

.dropdown-cart-header,
.dropdown-cart-total {
  font-weight: 700;
  line-height: 38px;
}

.dropdown-cart-header {
  margin-bottom: 1.7rem;
}

.dropdown-cart-total {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.4rem;
  font-size: 1.3rem;
  font-family: Poppins, sans-serif;
}
.dropdown-cart-total .cart-total-price {
  margin-left: auto;
  font-size: 1.5rem;
}

.dropdown-cart-header {
  font-size: 2rem;
  font-family: Poppins, sans-serif;
}
.dropdown-cart-header a:hover {
  text-decoration: underline;
}

.dropdown-cart-action .btn {
  padding: 1.3rem 2.5rem 1.4rem;
  border-radius: 0.2rem;
  color: #fff;
  height: auto;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.025em;
  border-color: transparent;
}
.dropdown-cart-action .btn:last-child:hover {
  color: #fff;
}
.dropdown-cart-action .view-cart {
  margin: 1rem 0;
  background: #e7e7e7;
  color: #222529;
}
.dropdown-cart-action .view-cart:hover,
.dropdown-cart-action .view-cart:focus {
  background: #f1f1f1;
  color: #222529;
}

.compare-dropdown .dropdown-toggle {
  text-transform: uppercase;
}
.compare-dropdown .dropdown-toggle i {
  margin-top: -0.2rem;
  margin-right: 0.2rem;
}
.compare-dropdown .dropdown-toggle i:before {
  margin: 0;
}
.compare-dropdown .dropdown-toggle:after {
  display: none;
}

.compare-products {
  margin: 0;
  padding: 0;
  list-style: none;
}
.compare-products .product {
  position: relative;
  margin: 0;
  padding: 0.5rem 0;
  box-shadow: none !important;
}
.compare-products .product:hover {
  box-shadow: none;
}
.compare-products .product-title {
  margin: 0;
  color: #696969;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.35;
  text-transform: uppercase;
}
.compare-products .btn-remove {
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 2.3rem;
  height: 2.3rem;
  margin-top: -1.2rem;
  padding: 0.5rem 0;
  color: #777;
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;
  overflow: hidden;
}

.compare-actions {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.compare-actions .action-link {
  display: inline-block;
  color: #777;
  font-size: 1.1rem;
  text-transform: uppercase;
}
.compare-actions .btn {
  min-width: 110px;
  margin-left: auto;
  padding: 0.9rem 1rem;
  border: 0;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.025rem;
  text-align: center;
  text-transform: uppercase;
}

.btn-remove {
  position: absolute;
  top: -10px;
  right: -8px;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: #474747;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  text-align: center;
  line-height: 2rem;
}
.btn-remove:hover,
.btn-remove:focus {
  color: #08c;
}

.icon-cancel:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

@media (min-width: 992px) {
  .dropdown-expanded > a,
  .dropdown-expanded .header-menu:before {
    display: none;
  }
  .dropdown-expanded li + li {
    margin-left: 3rem;
  }
  .dropdown-expanded ul {
    position: static;
    display: flex;
    display: -ms-flexbox;
    padding: 0;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
  }
  .dropdown-expanded ul a {
    padding: 0;
    color: inherit;
  }
  .dropdown-expanded ul a:hover {
    background-color: transparent;
  }
  .dropdown-expanded .header-menu ul {
    transform: none;
  }
}
@media (max-width: 575px) {
  .compare-dropdown {
    display: none;
  }
}
@media (max-width: 480px) {
  .cart-dropdown .dropdown-menu,
  .compare-dropdown .dropdown-menu {
    width: 262px;
  }
}
.header-search {
  position: relative;
}
.header-search form {
  margin: 0;
}
.header-search .form-control,
.header-search select {
  margin: 0;
  border: 0;
  color: inherit;
  font-size: 1.3rem;
  height: 100%;
  box-shadow: none;
}
.header-search .form-control,
.header-search .select-custom {
  background: #f4f4f4;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header-search .form-control {
    flex: 1;
  }
}
.header-search .form-control::placeholder {
  color: #a8a8a8;
}
.header-search:not(.header-search-category) .form-control {
  border-radius: 5rem;
}
.header-search:not(.header-search-category) .btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  border: 0;
  padding: 0 0.8em;
  color: #333;
}

.search-toggle:after {
  content: "";
  position: absolute;
  right: calc(50% - 10px);
  bottom: -10px;
  border: 10px solid transparent;
  border-bottom-color: #08c;
}
.header-search:not(.show) .search-toggle:after {
  display: none;
}

.header-search-category .form-control {
  border-radius: 5rem 0 0 5rem;
}
.header-search-category .btn {
  border-radius: 0 5rem 5rem 0;
}

.header-search-wrapper {
  display: flex;
  display: -ms-flexbox;
  position: absolute;
  right: -2.3rem;
  z-index: 999;
  margin-top: 10px;
  color: #8d8d8d;
  box-sizing: content-box;
  height: 40px;
  border-radius: 5rem;
  border: 5px solid #08c;
}
.header-search-wrapper:after {
  display: block;
  clear: both;
  content: "";
}
.header-search:not(.show) .header-search-wrapper {
  display: none;
}
.header-search-wrapper .select-custom {
  margin: 0;
  width: 13rem;
  flex: 0 0 13rem;
}
.header-search-wrapper .select-custom:after {
  font-size: 1.4rem;
  line-height: 0;
  margin-top: 1px;
  right: 13px;
}
.header-search-wrapper select {
  width: 100%;
  border-left: 1px solid #fff;
  padding-left: 15px;
  padding-right: 14px;
  line-height: 36px;
  letter-spacing: 0.005em;
  color: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.header-search-wrapper select:focus {
  outline: none;
}
.header-search-wrapper .btn {
  position: relative;
  padding: 0 0 3px 0;
  border: 0;
  border-left: 1px solid #fff;
  min-width: 40px;
  color: #222529;
  font-size: 16px;
  background: #f4f4f4;
}
.header-search-wrapper .btn:before {
  display: inline-block;
  margin-top: 5px;
  font-weight: 800;
}

.header-search-popup .form-control {
  min-width: 266px;
  padding: 4px 22px;
  font-size: 1.4rem;
  line-height: 20px;
}
.header-search-popup .form-control:focus {
  border: #e7e7e7;
}

.header-search-inline .form-control {
  min-width: 21rem;
  padding: 1rem 2rem;
}
@media (min-width: 992px) {
  .header-search-inline .search-toggle,
  .header-search-inline .btn:after {
    display: none;
  }
  .header-search-inline.header-search .header-search-wrapper {
    display: flex;
    display: -ms-flex;
    position: static;
    margin: 0;
    border-width: 0;
  }
}

@media (max-width: 767px) {
  .header-search .form-control {
    min-width: 17rem;
  }
}
@media (max-width: 575px) {
  .header-search-wrapper {
    left: 15px;
    right: 15px;
  }
}
.menu,
.menu li,
.menu ul,
.menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}
.menu:after {
  display: block;
  clear: both;
  content: "";
}
.menu .show > ul,
.menu .show > .megamenu {
  opacity: 1;
}
.menu li > a {
  display: block;
  padding: 0.8rem 1.8rem;
  transition: 0.2s ease-out;
  color: #777;
}
.menu li:hover > a,
.menu li.show > a,
.menu li.active > a {
  background: #f4f4f4;
}
.menu > li {
  float: left;
  position: relative;
  margin-right: 2.8rem;
}
.menu > li > a {
  padding: 1rem 0;
  font-size: 13px;
  font-weight: 400;
  color: #555;
}
.menu > li:hover > a,
.menu > li.show > a,
.menu > li.active > a {
  color: #08c;
  background: transparent;
}
.menu > li > .sf-with-ul:before {
  content: "";
  position: absolute;
  z-index: 1000;
  left: 50%;
  bottom: 0;
  margin-left: -14px;
  border: 10px solid;
  border-color: transparent transparent #fff;
  opacity: 0;
}
.menu > li.show > .sf-with-ul:before {
  opacity: 1;
  visibility: visible;
}
.menu .megamenu {
  display: none;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.15);
  border: 1px solid #eee;
  border-top: 3px solid #08c;
  left: 15px;
  padding: 10px 20px;
  width: 580px;
}
.menu .megamenu.megamenu-3cols {
  width: 600px;
}
.menu .megamenu .row > div {
  padding-top: 1.5rem;
}
.menu .megamenu img {
  width: 300px;
  height: 100%;
  object-fit: cover;
}
.menu .megamenu .submenu {
  margin: 0;
  padding-top: 0;
  border-top: none;
  display: block;
  position: static;
  box-shadow: none;
  min-width: 0;
}
.menu .megamenu .submenu a {
  padding: 7px 8px 8px 0;
}
.menu .megamenu .submenu li:hover a {
  text-decoration: underline;
  background: transparent;
}
.menu .nolink {
  cursor: default;
  display: inline-block;
  padding-bottom: 11px;
  font-weight: 700;
  color: #333;
}
.menu ul {
  display: none;
  position: absolute;
  min-width: 200px;
  padding: 5px 0;
  border-top: 3px solid #08c;
  top: 100%;
  left: 0;
  z-index: 101;
  background-color: #fff;
  box-shadow: 0 29px 29px rgba(0, 0, 0, 0.1);
}
.menu ul ul {
  top: -5px;
  left: 100%;
}
.menu.sf-arrows .sf-with-ul + ul > li {
  position: relative;
}
.menu.sf-arrows .sf-with-ul:after {
  position: absolute;
  right: 1rem;
  content: "";
  font-family: "porto";
}
.menu.sf-arrows > li > .sf-with-ul:after {
  content: "";
  position: static;
  margin-left: 5px;
  font-weight: 400;
}

.main-nav .menu {
  text-transform: uppercase;
  font-size: 12px;
}
.main-nav .menu > li {
  margin-right: 35px;
}
.main-nav .menu > li > a {
  font-size: 12px;
  font-weight: 700;
  padding: 19px 0;
}
.main-nav .menu > li:first-child > a {
  padding-left: 0;
}
.main-nav .menu > li:not(.float-right):last-child,
.main-nav .menu > li:not(.float-right) + li.float-right {
  margin-right: 0;
}
.main-nav .menu.sf-arrows ul {
  border-top: none;
}
.main-nav .menu > li > ul {
  left: -15px;
}
.main-nav .menu .megamenu {
  top: 100%;
  left: -15px;
  border-top: none;
}
.main-nav .menu .megamenu img {
  height: 100%;
  object-fit: cover;
}
.tip {
  display: inline-block;
  position: relative;
  margin: -2px 0 0 1rem;
  padding: 3px 4px;
  border-radius: 2px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  vertical-align: middle;
  z-index: 1;
}
.tip:before {
  position: absolute;
  top: 50%;
  right: 100%;
  left: auto;
  margin-top: -3px;
  border: 3px solid transparent;
  border-width: 3px 2px 0 2px;
  content: "";
}

.tip-new {
  background-color: #0fc567;
}
.tip-new:not(.tip-top):before {
  border-right-color: #0fc567;
}
.tip-new.tip-top:before {
  border-top-color: #0fc567;
}

.tip-hot {
  background-color: #eb2771;
}
.tip-hot:not(.tip-top):before {
  border-right-color: #eb2771;
}
.tip-hot.tip-top:before {
  border-right-color: #eb2771;
}

.tip-top {
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: 6px;
  margin-left: -2px;
  transform: translate(-50%);
}
.tip-top:before {
  top: 100%;
  right: 70%;
  margin: 0;
}

.menu-banner {
  height: 100%;
}
.menu-banner figure {
  margin-bottom: 0;
  height: 100%;
}
.menu-banner .banner-content {
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
}
.menu-banner h4 {
  font-size: 2.7rem;
  font-weight: 600;
  line-height: 1;
  color: #485156;
  margin-bottom: 3.5rem;
}
.menu-banner h4 span {
  font-size: 3.1rem;
  font-weight: 700;
}
.menu-banner h4 b {
  font-size: 3.2rem;
  color: #f4762a;
  font-family: Oswald, sans-serif;
}
.menu-banner h4 i {
  position: absolute;
  top: 33.5%;
  font-family: Oswald, sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  transform: translateY(-50%) rotate(-90deg);
}
.menu-banner .btn {
  font-family: Oswald, sans-serif;
  border-radius: 1px;
  font-weight: 300;
  color: #fff;
}
.menu-banner.menu-banner-2 {
  max-height: 317px;
}
.menu-banner.menu-banner-2 figure img {
  object-position: center 80%;
}
.menu-banner.menu-banner-2 .banner-content {
  top: 10px;
  left: auto;
  right: 10px;
  transform: none;
}
.menu-banner.menu-banner-2 .banner-content b {
  color: #08c;
}
.menu-banner.menu-banner-2 i {
  position: absolute;
  font-style: normal;
  font-size: 108px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.02em;
  color: #fff;
  top: 58px;
  left: -58px;
  transform: rotate(-90deg);
}
.menu-banner.menu-banner-2 .btn {
  position: absolute;
  bottom: 10px;
  padding: 8px 32px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  font-weight: 300;
}

.mobile-menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 260px;
  background-color: #1d1e20;
  font-size: 1.2rem;
  line-height: 1.5;
  z-index: 1051;
  transform: translateX(-100%);
  transition: transform 0.25s;
  overflow-y: auto;
}
.mmenu-active .mobile-menu-container {
  transform: translateX(0);
}
.mobile-menu-container .social-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
.mobile-menu-container .social-icon + .social-icon {
  margin-left: 1.2rem;
}
.mobile-menu-container .search-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.mobile-menu-container .search-wrapper .form-control {
  background: #282e36;
  border: 0;
  line-height: 22px;
  padding: 8px 12px;
  height: 38px;
}
.mobile-menu-container .search-wrapper .btn {
  position: absolute;
  right: 28px;
}

.mobile-menu-wrapper {
  position: relative;
  padding: 4.7rem 0 3rem;
}

.mobile-menu-close {
  position: absolute;
  top: 1.2rem;
  right: 2.1rem;
  padding: 0.4rem;
  color: #fff;
  line-height: 1;
  cursor: pointer;
  z-index: 9;
  font-size: 1.3rem;
}

.mobile-menu-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.25s;
  background: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 1050;
}

.mmenu-active .mobile-menu-overlay {
  opacity: 0.35;
  visibility: visible;
}

.mmenu-active .sidebar-product {
  display: none;
}

.mmenu-active .mobile-sidebar {
  display: none;
}

.mobile-nav {
  margin: 0 0 2rem;
  padding: 0;
}

.mobile-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mobile-menu li ul {
  display: none;
}
.mobile-menu > li > a {
  text-transform: uppercase;
}
.mobile-menu li {
  display: block;
  position: relative;
}
.mobile-menu li:not(:last-child) {
  border-bottom: 1px solid #242527;
}
.mobile-menu li a {
  display: block;
  position: relative;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
  padding: 1rem 0 1.1rem 0.7rem;
  color: #fff;
  font-size: 1.3rem;
}
.mobile-menu li a:hover,
.mobile-menu li a:focus {
  color: #fff;
  text-decoration: none;
}
.mobile-menu li.open > a,
.mobile-menu li.active > a {
  color: #fff;
  background-color: #282e36;
}
.mobile-menu li > div {
  padding-left: 1rem;
}
.mobile-menu li ul {
  margin: 0;
  padding: 0;
}
.mobile-menu li ul li a {
  padding-left: 2.5rem;
}
.mobile-menu li ul ul li a {
  padding-left: 3.5rem;
}

.mmenu-btn {
  display: block;
  position: absolute;
  top: 46%;
  right: 0.5rem;
  width: 3rem;
  height: 3rem;
  margin-top: -1.5rem;
  text-align: center;
  border-radius: 0;
  outline: none;
  font-weight: bold;
  background-color: transparent;
  color: #fff;
  font-size: 1.7rem;
  line-height: 3rem;
  cursor: pointer;
}
.open > .mmenu-btn::after {
  content: "";
}
.mmenu-btn:after {
  display: inline-block;
  margin-top: -2px;
  font-family: "porto";
  content: "";
}
.open > a > .mmenu-btn:after {
  content: "";
}

footer {
  font-size: 1.3rem;
  line-height: 24px;
}
footer .container {
  position: relative;
}
footer p {
  color: inherit;
}

footer a {
  color: inherit;
}

footer .social-icon {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  font-size: 1.8rem;
  line-height: 4rem;
}
footer .social-icon:not(:hover):not(:active):not(:focus) {
  background: transparent;
}
footer .payment-icons {
  margin-right: 2px;
}
footer .payment-icons .payment-icon {
  display: inline-block;
  vertical-align: middle;
  margin: 1px;
  width: 56px;
  height: 32px;
  background-color: #d6d3cc;
  background-size: 80% auto;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.25s;
  filter: invert(1);
  border-radius: 4px;
}
footer .payment-icons .payment-icon:hover {
  opacity: 0.7;
}
footer .payment-icons .payment-icon.paypal {
  background-size: 85% auto;
  background-position: 50% 48%;
}
footer .payment-icons .payment-icon.stripe {
  background-size: 60% auto;
}
footer .widget {
  margin-bottom: 3rem;
}
footer .widget-title {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 1.7rem;
}
footer .tagcloud a {
  padding: 0.6em;
  margin: 0 0.8rem 0.8rem 0;
  border: 1px solid #313438;
  color: inherit;
  font-size: 11px;
  background: transparent;
}
footer .tagcloud a:hover {
  border-color: #fff;
  background: transparent;
}
footer .contact-info {
  margin: 0;
  padding: 0;
}
footer .contact-info li {
  position: relative;
  margin-bottom: 1rem;
  line-height: 1.4;
}
footer .contact-info-label {
  display: block;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 1px;
}

.footer-ribbon {
  position: absolute;
  top: 0;
  margin: -16px 0 0;
  padding: 10px 20px 6px;
  color: #fff;
  font-size: 1.6em;
  z-index: 101;
  background-color: #0088cc;
  font-family: "Shadows Into Light", sans-serif;
  font-weight: 400;
}
.footer-ribbon::before {
  content: "";
  display: block;
  height: 0;
  position: absolute;
  top: 0;
  width: 7px;
  right: 100%;
  border-right: 10px solid #005580;
  border-top: 16px solid transparent;
}

#scroll-top {
  height: 40px;
  position: fixed;
  right: 15px;
  width: 40px;
  z-index: 9999;
  bottom: 0;
  color: #fff;
  background-color: #43494e;
  font-size: 16px;
  text-align: center;
  line-height: 1;
  padding: 11px 0;
  visibility: hidden;
  opacity: 0;
  border-radius: 0 0 0 0;
  transition: all 0.3s, margin-right 0s;
  transform: translateY(40px);
}
#scroll-top > i {
  position: absolute;
  height: 24px;
  line-height: 24px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
#scroll-top > i::before {
  font-weight: 700;
  font-size: 2rem;
}
#scroll-top:hover,
#scroll-top:focus {
  background-color: #3a4045;
}
#scroll-top.fixed {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  color: #fff;
  width: 49px;
  height: 48px;
  right: 10px;
  text-align: center;
  text-decoration: none;
  z-index: 996;
  transition: background 0.3s ease-out;
  background: rgba(64, 64, 64, 0.75);
}
#scroll-top.fixed:hover {
  color: #08c;
}

@media (max-width: 575px) {
  footer {
    margin-bottom: 68px;
  }

  #scroll-top {
    display: none;
  }
}
.flag {
  margin-top: -2px;
}

.header {
  border-bottom: 1px solid #f4f4f4;
}

.header-top {
  padding: 8px 0;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 24px;
}
.header-top a {
  color: inherit;
}
.header-top .dropdown-expanded li + li {
  margin-left: 0;
}
.header-top .dropdown-expanded a {
  padding-left: 11px;
  padding-right: 12px;
}

.header-middle {
  display: flex;
  padding: 2.7rem 0;
  border-top: 1px solid #e7e7e7;
}

.header-bottom .container:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  border-top: 1px solid #f4f4f4;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
}
.header-bottom.fixed .main-nav .float-right {
  display: block;
}

.top-notice h5 {
  margin-bottom: 1px;
  margin-right: 4px;
}
.top-notice h5 b {
  letter-spacing: 0.04em;
}

.header-dropdown {
  padding: 4px 0;
}
.header-dropdown .dropdown-toggle:after {
  font-size: 17px;
}
.header-dropdown .badge-circle {
  left: 21px;
}
.header-dropdown img {
  margin-top: -1px;
}

.dropdown-arrow .badge-circle {
  top: 1px;
  left: 23px;
}
.dropdown-arrow::after {
  border: 0;
  margin: 0 0 0 15px;
  font-size: 17px;
}

.cart-dropdown {
  margin-left: -2px;
}
.cart-dropdown a.dropdown-toggle {
  display: flex;
  align-items: center;
  min-height: 38px;
}
.cart-dropdown a.dropdown-toggle:hover {
  color: inherit;
}

.header-search form {
  padding-left: 11px;
}
.header-search .select-custom {
  min-width: 129px;
  flex: 0 0 129px;
}
.header-search .select-custom select {
  border-right: 1px solid #fff;
}
.header-search .btn {
  border-left: none;
}

.header-contact {
  margin-right: 28px;
  margin-left: 1.2rem;
}
.header-contact h6 {
  margin-top: 1px;
  margin-left: 1px;
}

.header-icon:not(:last-child) {
  margin-top: -2px;
  margin-right: 2.2rem;
}

.main-nav .menu > li > a {
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  padding: 17px 0;
  letter-spacing: -0.01em;
}
.main-nav .menu > li.active > a,
.main-nav .menu > li > a:hover {
  border-top-color: #08c;
}
.main-nav .menu > li.float-right a:hover {
  border-top-color: transparent;
}
.main-nav .menu > li.float-right:last-child {
  margin-right: 8px;
}
.main-nav .menu .tip-top {
  right: -5px;
  top: 5px;
  left: auto;
  font-size: 9px;
  padding: 2px;
  margin-left: 10px;
  margin-top: 0;
  transform: none;
}

.footer-middle {
  padding: 6.5rem 0 2.3rem;
}

.footer-bottom {
  padding: 2.7rem 0;
  border-top: 1px solid #313438;
}

.footer {
  letter-spacing: 0.005em;
}
.footer .widget li {
  color: #a8a8a8;
}
.footer .widget-title {
  letter-spacing: normal;
}
.footer .social-icons {
  display: flex;
  align-items: center;
}
.footer .social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  width: 40px;
  height: 40px;
  box-shadow: none;
  font-size: 1.5rem;
  line-height: 3.6rem;
  border-radius: 50%;
  border: 1px solid #313438;
}
.footer .social-icon:not(:hover):not(:active):not(:focus) {
  background-color: transparent;
  border-color: #313438;
  color: #fff;
}
.footer .social-icon:hover {
  border-color: transparent;
}
.footer .social-icon + .social-icon {
  margin-left: 0.5rem;
}
.footer .contact-info {
  margin-bottom: 3rem;
}
.footer .contact-info li {
  position: relative;
  margin-bottom: 1rem;
  line-height: 1.4;
}
.footer .contact-info li:last-child {
  margin-bottom: 0.4rem;
}
.footer .contact-info-label {
  display: block;
  margin-bottom: 0;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
}
.footer .tagcloud {
  margin-top: -0.3em;
  margin-bottom: -0.3846em;
}
.footer .tagcloud a {
  display: inline-block;
  margin: 0.3846em 0.3846em 0.3846em 0;
  padding: 0.5em 0.6em 0.65em;
  border-radius: 0;
  font-size: 11px !important;
  font-weight: 400;
  border: 1px solid #313438;
  color: #a8a8a8;
  background: none;
  text-transform: none;
}
.footer .tagcloud a:hover {
  border-color: #fff;
  color: #fff;
  background: transparent;
}
.footer .widget-newsletter p {
  margin-top: -3px;
  margin-bottom: 1.3rem;
  line-height: 1.9;
  color: #a8a8a8;
}
.footer .widget-newsletter .form-control {
  height: 4.4rem;
  padding: 0.8rem 2.4rem;
  border: 0;
  border-radius: 5rem;
  background: #292c30;
  color: #777;
  font-size: inherit;
}
.footer .widget-newsletter .form-control::placeholder {
  color: #999999;
}
.footer .widget-newsletter .btn {
  padding: 1.2rem 2.7rem;
  border-radius: 5rem;
  font-size: inherit;
  font-family: "Open Sans", sans-serif;
}

.footer-copyright {
  color: #777;
  font-size: 0.9em;
  line-height: 24px;
  letter-spacing: 0.065px;
}

.coupon-sale-text {
  position: relative;
  display: inline-block;
  padding: 5px 8px;
  transform: rotate(-2deg);
}
.coupon-sale-text i {
  position: absolute;
  left: -2.25em;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  font-size: 0.65em;
  font-style: normal;
  opacity: 0.6;
  letter-spacing: 0;
}
.coupon-sale-text b {
  display: inline-block;
  padding: 5px 8px;
  font-size: 1.6em;
  background-color: #fff;
}
.coupon-sale-text em {
  font-size: 2em;
  font-style: normal;
}

.btn-lg {
  padding: 1.07em 2.76em;
}

.btn-light {
  background: #fff;
  border-color: #fff;
}

.btn-black {
  background: #0e0f11;
  border-color: #0e0f11;
}

.product-default .btn-add-cart i {
  display: none;
}

@media (max-width: 1199px) {
  .header-top .dropdown-expanded a {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 767px) {
  .minicart-icon {
    width: 22px;
    height: 18px;
  }
}
@media (max-width: 575px) {
  .header-icon,
  .cart-dropdown {
    padding: 0;
  }

  .header-search {
    display: none;
  }
}
@media (max-width: 479px) {
  .header-icon:not(:last-child) {
    margin-right: 1.5rem;
    margin-bottom: 3px;
  }

  .sticky-header .cart-dropdown {
    margin-bottom: 4px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .dropdown-expanded li:not(:first-child) {
    margin-left: 1.3rem;
  }
}
.about .feature-box h3 {
  margin-bottom: 1.2rem;
  text-transform: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #21293c;
}
.about .feature-box i {
  margin-bottom: 1.3rem;
  font-size: 5.5rem;
}
.about .feature-box p {
  line-height: 27px;
}

.page-header-bg {
  padding: 8.3rem 0 8.7rem;
}
.page-header-bg h1 {
  color: #1e3636;
  font-size: 3.6rem;
  font-weight: 900;
  margin-bottom: 2.4rem;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0;
}
.page-header-bg h1 span {
  display: block;
  color: #1e3636;
  font-size: 0.5em;
  font-weight: 700;
  line-height: 1.2;
  font-family: Poppins, sans-serif;
  letter-spacing: 0;
}
.page-header-bg .btn {
  padding: 11px 29px;
  font-family: Oswald, sans-serif;
  font-weight: inherit;
  font-size: inherit;
  letter-spacing: 0;
  z-index: 3;
}

.subtitle {
  color: #21293c;
  font-size: 1.9rem;
}

.about-section {
  padding: 1rem 0 2.5rem;
  color: #7b858a;
  font-size: 1.5rem;
  line-height: 1.8;
}
.about-section .subtitle {
  margin-bottom: 1.7rem;
}
.about-section p {
  margin-bottom: 2rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.about-section .lead {
  font-family: Poppins, sans-serif;
  color: #21293c;
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 400;
}

.features-section {
  padding: 5.1rem 0 2rem;
}
.features-section .subtitle {
  margin-bottom: 1.7rem;
}
.features-section h3 {
  font-family: Poppins, sans-serif;
}
.features-section .feature-box {
  padding: 3rem 4rem;
}

.testimonials-section {
  padding: 5.1rem 0 7rem;
}
.testimonials-section .subtitle {
  margin-bottom: 5.2rem;
}

.testimonials-carousel blockquote {
  margin-bottom: 0;
}
.testimonials-carousel.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0.5rem;
}

.testimonial-title {
  display: block;
  margin-bottom: 2px;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: #2b2b2d;
}

.counters-section {
  padding: 5rem 0 2.4rem;
}

.count-container .count-wrapper {
  color: #0087cb;
  font-size: 3.2rem;
  font-weight: 800;
  line-height: 3.2rem;
  font-family: "Open Sans", sans-serif;
}
.count-container span:not(.count-to) {
  font-size: 1.9rem;
}
.count-container .count-title {
  color: #7b858a;
  font-size: 1.4rem;
  font-weight: 600;
}

.team-info figure {
  position: relative;
}
.team-info:hover .prod-full-screen {
  opacity: 1;
}
.team-info .prod-full-screen {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: #222529;
  border-radius: 50%;
  bottom: 5px;
  right: 5px;
}
.team-info .prod-full-screen i {
  color: #fff;
}

.owl-carousel.images-left img {
  width: auto;
}

@media (min-width: 992px) {
  .counters-section .col-md-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 768px) {
  .about-section {
    padding-top: 3.1rem;
    padding-bottom: 4.5rem;
  }
}
@media (min-width: 576px) {
  .testimonial blockquote {
    margin-left: 85px;
    padding: 2rem 3rem 1.5rem 2rem;
  }
}
.sidebar-shop {
  font-size: 1.3rem;
}
.sidebar-shop .product-widget .product-title {
  margin-bottom: 0.4rem;
  font-family: "Open Sans", sans-serif;
}
.sidebar-shop .product-widget .product-details {
  margin-bottom: 1px;
}
.sidebar-shop .widget {
  padding: 2rem;
  border: 1px solid #e7e7e7;
}
.sidebar-shop .widget:after {
  display: block;
  clear: both;
  content: "";
}
.sidebar-shop .widget:not(:last-child) {
  border-bottom: 0;
}
.sidebar-shop .widget-title {
  margin: 0;
  color: #313131;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
}
.sidebar-shop .widget-title a {
  display: block;
  position: relative;
  color: inherit;
}
.sidebar-shop .widget-title a:hover,
.sidebar-shop .widget-title a:focus {
  text-decoration: none;
}
.sidebar-shop .widget-title a:before,
.sidebar-shop .widget-title a:after {
  display: inline-block;
  position: absolute;
  top: 50.4%;
  right: 2px;
  width: 10px;
  height: 2px;
  margin-top: -1px;
  transition: all 0.35s;
  background: #222529;
  content: "";
}
.sidebar-shop .widget-title a.collapsed:after {
  transform: rotate(-90deg);
}
.sidebar-shop .widget-body {
  padding: 1.5rem 0 0.7rem;
}
.sidebar-shop .widget-featured {
  position: relative;
  padding-bottom: 0.5rem;
}
.sidebar-shop .widget-featured .widget-body {
  padding-top: 1.5rem;
}
.sidebar-shop .widget-featured .product-sm:last-child {
  margin-bottom: 0;
}
.sidebar-shop .widget-featured .ratings-container {
  margin-left: 0;
}

.widget-featured-products .product-widget {
  margin-bottom: 1.6rem;
}
.widget-featured-products .product-widget figure {
  margin-right: 1.2rem;
  max-width: 84px;
  flex-shrink: 0;
}
.widget-featured-products .product-widget .ratings-container {
  margin-bottom: 1.2rem;
  margin-top: 2px;
}

.widget .owl-carousel .owl-nav {
  position: absolute;
  top: -3.5rem;
  right: 1px;
  line-height: 0;
}
.widget .owl-carousel .owl-nav button.owl-next,
.widget .owl-carousel .owl-nav button.owl-prev {
  padding: 0 0.4rem !important;
  border-radius: 0;
  color: #222529;
  font-size: 1.8rem;
  line-height: 1;
  background-color: transparent;
}
.widget .owl-carousel .owl-nav i:before {
  width: auto;
  margin: 0;
}

.cat-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.cat-list li {
  position: relative;
  margin-bottom: 1.3rem;
  font-size: 14px;
  font-weight: 500;
}
.cat-list li:last-child {
  margin-bottom: 0;
}
.cat-list li a {
  color: #777;
  font-weight: 500;
}
.cat-list li a:hover,
.cat-list li a:focus {
  color: #08c;
}
.cat-list .products-count {
  margin-left: 3px;
  font-size: 13px;
  font-weight: 500;
}

.cat-sublist {
  margin-top: 1.3rem;
  margin-left: 1.4rem;
}

span.toggle {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: absolute;
  right: -5px;
  top: -3px;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  line-height: 23px;
  font-family: "Porto";
  font-weight: 900;
  color: #222529;
}
span.toggle::before {
  content: "";
}
.collapsed span.toggle::before {
  content: "";
}

.config-size-list {
  margin: 0;
  padding: 0;
  font-size: 0;
  list-style: none;
}

.config-size-list li {
  display: inline-flex;
}

.config-size-list a {
  display: block;
  position: relative;
  min-width: 32px;
  text-align: center;
  margin: 3px 6px 3px 0;
  padding: 4px 8px;
  transition: all 0.3s;
  border: 1px solid #e9e9e9;
  color: #777;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.6rem;
  text-decoration: none;
}
.config-size-list a:hover,
.config-size-list a:focus,
.config-size-list a.active {
  border-color: #08c;
  background-color: #08c;
  color: #fff;
  text-decoration: none;
}

.price-slider-wrapper {
  padding: 1.5rem 0.4rem 0.5rem 0.6rem;
}

.filter-price-action {
  margin-top: 2.5rem;
  padding-bottom: 0.5rem;
}
.filter-price-action .btn {
  padding: 5px 1.5rem 6px 1.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}
.filter-price-action .filter-price-text {
  font-size: 1.2rem;
  line-height: 2;
}

.widget-block {
  font-size: 1.5rem;
  line-height: 1.42;
}
.widget-block h5 {
  margin-bottom: 1.5rem;
  color: #313131;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}
.widget-block p {
  font-size: 1.4rem;
  line-height: 1.75;
  margin-bottom: 0;
}
.widget-block .widget-title {
  padding-bottom: 3px;
}

.widget .config-swatch-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.3rem;
}
.widget .config-swatch-list li {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.3rem;
}
.widget .config-swatch-list li a {
  margin: 3px 6px 3px 0;
  box-shadow: none;
}
.widget.widget-color .widget-body {
  padding-top: 0.6rem;
}
.widget.widget-size .widget-body {
  padding-top: 1.1rem;
}

.shop-toggle.sidebar-toggle {
  display: inline-flex;
  position: static;
  align-items: center;
  width: auto;
  height: 34px;
  background: #fff;
  padding: 0 8px;
  text-transform: uppercase;
  color: inherit;
  border: 1px solid #e7e7e7;
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 0;
  z-index: 1;
}
.shop-toggle.sidebar-toggle span {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: -0.05em;
  margin-left: 0.6rem;
  color: #222528;
}
.sidebar-opened .shop-toggle.sidebar-toggle {
  z-index: 1;
}
.sidebar-opened .shop-toggle.sidebar-toggle i::before {
  content: "";
}

.horizontal-filter {
  margin-bottom: 2rem;
  padding: 6px 0 0px;
  background-color: #f4f4f4;
}
.horizontal-filter.filter-sorts {
  padding: 12px 12px 2px;
}
.horizontal-filter.filter-sorts .select-custom select {
  border: none;
}
.horizontal-filter:not(.filter-sorts) .toolbox-item:not(:last-child) {
  margin-right: 1.6rem;
}
.horizontal-filter:not(.filter-sorts).toolbox label {
  margin: 0px 1rem 1px 0px;
  font-family: Poppins, sans-serif;
  letter-spacing: -0.025em;
}
.horizontal-filter:not(.filter-sorts).toolbox .form-control {
  padding: 0 0.8rem 2px;
  color: #222529;
  font-family: "poppins";
}
.horizontal-filter:not(.filter-sorts).toolbox .select-custom .form-control {
  padding-right: 2.4rem;
}
.horizontal-filter:not(.filter-sorts) .toolbox-item.toolbox-sort {
  margin-right: 3.2rem;
}
.horizontal-filter .filter-price-form {
  font-family: "Open Sans", sans-serif;
  font-size: 1.36rem;
}
.horizontal-filter .filter-price-form .btn {
  font-family: inherit;
  padding: 0.7rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 400;
}
.horizontal-filter .input-price {
  display: block;
  width: 50px;
  padding: 6px;
  line-height: 1.45;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.09);
}
.horizontal-filter select {
  border: 0;
}
.horizontal-filter:not(.filter-sorts) {
  background-color: #fff;
}
.horizontal-filter:not(.filter-sorts) .layout-btn {
  width: 36px;
  border: 1px solid #dfdfdf;
  line-height: 34px;
}
.horizontal-filter:not(.filter-sorts) .layout-btn.active {
  color: #222529;
  border-color: #222529;
}
.horizontal-filter:not(.filter-sorts) .layout-btn:not(:last-child) {
  margin-right: 8px;
}
.horizontal-filter .select-custom select {
  border: 1px solid #dfdfdf;
}

.sort-menu-trigger {
  display: block;
  color: #313131;
  font-size: 12px;
  line-height: 1.4;
  text-transform: uppercase;
}

.sort-list li {
  padding: 1rem 0;
  font-size: 12px;
  text-transform: uppercase;
}
.sort-list li a {
  color: inherit;
  font-weight: 600;
}
.sort-list li:hover,
.sort-list li:focus,
.sort-list li.active {
  color: #08c;
}
.sort-list.cat-list li {
  margin-bottom: 0;
}
.sort-list.cat-list li span.toggle {
  top: 5px;
}

.filter-toggle span {
  margin-bottom: 2px;
  color: #777;
  font-size: 1.3rem;
  letter-spacing: -0.02em;
}
.filter-toggle a {
  display: inline-block;
  position: relative;
  width: 46px;
  height: 26px;
  margin-left: 7px;
  border-radius: 13px;
  background: #e6e6e6;
  text-decoration: none;
}
.filter-toggle a:before {
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.3s linear;
  border-radius: 11px;
  background-color: #fff;
  content: "";
}
.filter-toggle a:after {
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
  border-radius: 11px;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  content: "";
}
.filter-toggle.opened a {
  background-color: #08c;
}
.filter-toggle.opened a:before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}
.filter-toggle.opened a:after {
  transform: translate3d(22px, 2px, 0);
}

.shop-off-canvas .mobile-sidebar {
  display: block;
  position: fixed;
  padding: 1.3rem 0.8rem 1.3rem 0.9rem;
  top: 0;
  bottom: 0;
  left: 0;
  width: 300px;
  margin: 0;
  transform: translate(-300px);
  transition: transform 0.2s ease-in-out 0s;
  background-color: #fff;
  z-index: 9999;
  overflow-y: auto;
}
.shop-off-canvas .widget {
  border: none;
}
.shop-off-canvas .widget:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}
.shop-off-canvas .sidebar-wrapper {
  width: 100% !important;
}

.sidebar-opened .shop-off-canvas .mobile-sidebar {
  transform: none;
}

.sidebar-opened .shop-off-canvas .sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.35;
  z-index: 9999;
}

.sidebar-toggle {
  display: flex;
  position: static;
  margin-right: 0.8rem;
  margin-top: 0;
  padding: 0 1.1rem 0 3px;
  align-items: center;
  width: auto;
  height: 34px;
  text-transform: uppercase;
  line-height: 36px;
  color: inherit;
  border: 1px solid #dfdfdf;
  background: #fff;
  cursor: pointer;
}
.sidebar-toggle span {
  margin-left: 0rem;
  font-size: 1.3rem;
  letter-spacing: -0.05em;
}
.sidebar-toggle:hover span {
  color: #08c;
}

.sidebar-opened .sidebar-toggle i::before {
  content: "";
}

.slide-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 11.4%;
  text-transform: uppercase;
}
.boxed-slide-1 .slide-content {
  text-align: center;
}
.boxed-slide-2 .slide-content {
  left: 6.8%;
  color: #222529;
}

.boxed-slide-1 h4 {
  font-family: Oswald, sans-serif;
  font-size: 2.7rem;
  font-weight: 500;
  letter-spacing: -0.08em;
  margin-bottom: 0;
}
.boxed-slide-1 h5 {
  font-family: "Open Sans", sans-serif;
  font-size: 3rem;
  font-weight: 800;
  letter-spacing: -0.025em;
  margin-top: -5px;
  margin-bottom: 0;
}
.boxed-slide-1 span {
  display: block;
  position: relative;
  width: 100%;
  color: #222529;
  letter-spacing: 0.05em;
  font-weight: 700;
  margin-bottom: -6px;
  margin-top: 3px;
}
.boxed-slide-1 span::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 1px;
  background-color: #74b0bb;
}
.boxed-slide-1 span::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 1px;
  background-color: #74b0bb;
}
.boxed-slide-1 b {
  font-size: 3.6rem;
  font-weight: 800;
  color: #222529;
  letter-spacing: 0.025em;
}
.boxed-slide-1 b i {
  font-weight: 500;
}
.boxed-slide-1 p {
  font-size: 13px;
  font-weight: 700;
  color: #222529;
  letter-spacing: 0.03em;
  margin-top: -5px;
  margin-bottom: 2.2rem;
}

.boxed-slide-2 h5 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 800;
  margin-bottom: 0;
}
.boxed-slide-2 h5 span {
  font-family: Oswald, sans-serif;
  font-size: 2.9rem;
}
.boxed-slide-2 h5 i {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-size: 1.6rem;
  margin-left: -2px;
  margin-bottom: 5px;
}
.boxed-slide-2 h4 {
  font-size: 3.2rem;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.02em;
  margin-bottom: 3rem;
  margin-top: -3px;
}
.boxed-slide-2 .btn {
  font-family: Oswald, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  padding: 9px 17.5px 13px;
  margin-bottom: 1.3rem;
}

.btn-loadmore {
  box-shadow: none;
  padding: 1.3rem 3rem;
  border: 1px solid #e7e7e7;
  font-size: 1.2rem;
  font-family: "Open Sans", sans-serif;
  color: #555;
}
.btn-loadmore:hover {
  border-color: #ccc;
}

.category-banner {
  padding: 6.8rem 0;
}
.category-banner .coupon-sale-text {
  font-family: "Open Sans", sans-serif;
}
.category-banner h3 {
  font-size: 3em;
  margin-left: 1.8rem;
  margin-bottom: 1.6rem;
}
.category-banner h4 {
  font-size: 1.125em;
  line-height: 1.7;
}
.category-banner h5 {
  font-size: 1em;
}
.category-banner .btn {
  font-size: 0.75em;
  letter-spacing: 0.01em;
  padding: 1em 1.6em;
  margin-left: 1.8rem;
}

@media (min-width: 992px) {
  .filter-sorts .toolbox-left {
    position: relative;
  }
  .filter-sorts .toolbox-item.toolbox-sort {
    margin-left: 0;
    margin-right: 1rem;
    background-color: #fff;
  }
  .filter-sorts select {
    border: 0;
    text-transform: uppercase;
  }
  .filter-sorts .mobile-sidebar.sidebar-shop {
    left: 0;
    padding: 0;
    visibility: visible;
    z-index: 2;
  }

  .toolbox-item.opened .sort-list {
    display: block;
  }
}
.sidebar-toggle svg {
  stroke: #222529;
  fill: #fff;
  width: 28px;
}

@media (min-width: 992px) {
  .sidebar-toggle {
    display: none;
  }
}
@media (max-width: 991px) {
  .sidebar-toggle span {
    font-size: 11px;
    font-weight: 600;
    color: #222529;
  }

  .sidebar-shop .widget {
    padding: 2rem 0;
    border: 0;
  }
  .sidebar-shop .widget:first-child {
    padding-top: 0;
  }
  .sidebar-shop .widget:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }
}
@media (max-width: 479px) {
  .sidebar-toggle {
    margin-right: 2px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .sidebar-shop .product-widget figure {
    max-width: 70px;
    margin-right: 1.5rem;
  }
}

.add-cart {
  padding: 12px 27px 10px 26px;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.015em;
  line-height: 24px;
}
.add-cart:before {
  font-size: 1.8rem;
  line-height: 0;
  vertical-align: middle;
  margin-right: 8px;
  font-weight: 900;
}

.add-wishlist {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 17px 8px;
  color: #222529;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  white-space: nowrap;
}
.add-wishlist i {
  margin-right: 4px;
  font-size: 1.6rem;
}
.add-wishlist i:before {
  font-weight: 700;
}

.product-single-container:not(.product-quick-view)
  .product-action
  .add-cart.added-to-cart:before {
  display: none;
}
.product-single-container:not(.product-quick-view)
  .product-action
  .add-cart.added-to-cart:after {
  margin-left: 8px;
  font-family: "Font Awesome 5 Free";
  content: "";
  font-weight: 600;
  font-size: 1.6rem;
}

.product-single-details {
  margin-bottom: 1.1rem;
}
.product-single-details .product-action .add-cart {
  display: inline-flex;
  align-items: center;
}
.product-single-details .product-action .add-cart:before {
  content: "";
  margin-top: -2px;
  font-family: "Porto";
  font-weight: 600;
  font-size: 1.8rem;
  margin-right: 7px;
}
.sticky-sidebar .product-single-details {
  margin-bottom: 2.7rem;
}
.product-single-details .product-title {
  margin-bottom: 1.1rem;
  color: #222529;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  width: calc(100% - 70px);
}
.product-single-details .product-nav {
  position: absolute;
  display: flex;
  top: 4px;
  right: 10px;
}
.product-single-details .product-nav.top-0 {
  top: 0;
}
.product-single-details .product-nav a {
  color: #222529;
}
.product-single-details .product-nav .product-prev,
.product-single-details .product-nav .product-next {
  float: left;
  margin-left: 2px;
}
.product-single-details .product-nav .product-prev.disabled > a,
.product-single-details .product-nav .product-next.disabled > a {
  color: #999;
  cursor: no-drop;
}
.product-single-details .product-nav .product-prev:hover .product-popup,
.product-single-details .product-nav .product-next:hover .product-popup {
  display: block;
}
.product-single-details .product-nav a:hover {
  color: #333;
}
.product-single-details .product-nav .product-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 28px;
  height: 28px;
  line-height: 23px;
  border: 2px solid #e7e7e7;
  border-radius: 14px;
  text-align: center;
  text-decoration: none;
  font-family: "porto";
}
.product-single-details .product-nav .product-prev .product-link::before {
  content: "";
  display: block;
}
.product-single-details .product-nav .product-next .product-link::before {
  content: "";
  display: block;
}
.product-single-details .product-nav .product-popup {
  position: absolute;
  top: 31px;
  display: none;
  right: 0;
  font-size: 13px;
  z-index: 999;
  width: 110px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  background-color: #fff;
}
.product-single-details .product-nav .product-popup::before {
  right: 36px;
  border-bottom: 7px solid #333;
  border-left: 7px solid transparent !important;
  border-right: 7px solid transparent !important;
  content: "";
  position: absolute;
  top: -4px;
}
.product-single-details .product-nav .box-content {
  border-top: 3px solid #222529;
  display: block;
  padding: 10px 10px 11px;
}
.product-single-details .product-nav .box-content > span {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: block;
  padding-top: 5px;
  line-height: 1.4em;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #797876;
}
.product-single-details .product-nav .product-next .product-popup::before {
  right: 7px;
}
.product-single-details .product-filters-container {
  padding-top: 2px;
  padding-bottom: 1.4rem;
}
.product-single-details .product-filters-container + .price-box {
  margin-top: 2.2rem;
  margin-bottom: 0.8rem;
}
.product-single-details .price-box {
  margin-bottom: 2.3rem;
  color: #222529;
  font-weight: 600;
  font-family: Poppins, sans-serif;
}
.product-single-details .product-filtered-price {
  display: none;
  margin-bottom: 1.3rem;
  margin-top: 0.7rem;
}
.product-single-details .new-price {
  color: #222529;
  font-size: 2.4rem;
  letter-spacing: -0.02em;
  vertical-align: middle;
  line-height: 0.8;
  margin-left: 3px;
}
.product-single-details .product-price {
  color: #222529;
  font-size: 2.4rem;
  letter-spacing: -0.02em;
  vertical-align: middle;
  line-height: 0.8;
}
.product-single-details .old-price {
  position: relative;
  top: 2px;
  color: #a7a7a7;
  font-size: 1.9rem;
  font-weight: 600;
  vertical-align: middle;
}
.product-single-details .old-price + .product-price {
  margin-left: 0.4rem;
}
.product-single-details .add-wishlist:before {
  margin-right: 0.3rem;
}
.product-single-details .short-divider {
  width: 40px;
  height: 0;
  border-top: 2px solid #e7e7e7;
  margin: 0 0 2.2rem;
  text-align: left;
}
.product-single-details .product-single-filter:last-child {
  display: none;
  margin-bottom: 1rem;
  margin-top: -2px;
}
.product-single-details .divider + .product-action {
  margin-top: -0.5rem;
}
.product-single-details .product-action + .divider {
  margin-top: 1.6rem;
}
.product-single-details .ratings-container {
  margin-bottom: 2.1rem;
  display: flex;
  align-items: center;
}
.product-single-details .ratings-container .product-ratings,
.product-single-details .ratings-container .ratings {
  font-size: 1.3rem;
}
.product-single-details .ratings-container .product-ratings {
  height: 14px;
  margin-left: -1px;
  margin-right: 1px;
}
.product-single-details .ratings-container .product-ratings:before {
  color: #999;
}
.product-single-details .ratings-container .ratings:before {
  color: #fd5b5a;
}
.product-single-details .rating-link {
  color: #999;
  font-size: 1.3rem;
  font-weight: 400;
  padding-left: 1rem;
}
.product-single-details .rating-link:hover {
  text-decoration: underline;
}
.product-single-details .rating-link-separator {
  padding-left: 0.9rem;
  font-size: 1.3rem;
}
.product-single-details .product-desc {
  margin-bottom: 1.8rem;
  font-size: 1.6rem;
  letter-spacing: -0.015em;
  line-height: 1.6875;
}
.product-single-details .product-desc a {
  color: #222529;
}
.product-single-details .product-action {
  padding: 1.5rem 0 1.6rem;
  border-top: 1px solid #e7e7e7;
}
.product-single-details .container {
  align-items: center;
  -ms-flex-align: center;
}
.product-single-details .container img {
  max-width: 5rem;
  max-height: 5rem;
  margin-right: 2rem;
}
.product-single-details .product-single-qty {
  margin: 0.5rem 0.5rem 0.5rem 1px;
}
.product-single-details .product-single-qty .form-control {
  height: 48px;
  font-size: 1.6rem;
  font-weight: 700;
}
.product-single-details .clear-btn {
  display: inline-block;
  background-color: #f4f4f4;
  margin-top: -3px;
  margin-left: -3px;
  padding: 5px 8px;
  font-size: 1rem;
  color: #777;
}
.product-single-details .clear-btn:hover {
  background-color: #08c;
  color: #fff;
}

.product-filters-container select.form-control:not([size]):not([multiple]) {
  margin-bottom: 0;
  height: 42px;
  font-weight: 600;
}
.product-filters-container .select-custom {
  max-width: 282px;
  width: 100%;
}
.product-filters-container .select-custom::after {
  right: 1.5rem;
  color: #222529;
}

.product-single-container .cart-sticky .sticky-header.fixed {
  top: 58px;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  animation: none;
}

main .sticky-header:not(.fixed) .sticky-img,
main .sticky-header:not(.fixed) .sticky-detail {
  display: none;
}
main .sticky-header:not(.fixed).container {
  padding-left: 0;
  padding-right: 0;
}
main .sticky-header.fixed .container,
main .sticky-header.fixed .container-fluid {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}
main .sticky-header.fixed .product-action {
  flex: 1;
  -ms-flex: 1;
  border: 0;
  margin: 0;
  padding: 0;
  min-width: 260px;
  text-align: right;
}
main .sticky-header .sticky-img img {
  margin: 0;
  max-width: 60px;
  max-height: 60px;
}
main .sticky-header .sticky-detail {
  display: flex;
  margin-bottom: 5px;
}
main .sticky-header .product-title {
  margin-right: 2rem;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
main .sticky-header .price-box {
  margin: 0;
  color: #2b2b2d;
  font-family: "Oswald", "Poppins";
  font-weight: 400;
  line-height: normal;
}
main .sticky-header .old-price {
  font-size: 1.4rem;
}
main .sticky-header .product-price {
  font-size: 1.8rem;
}
main .sticky-header .ratings-container {
  display: block;
  line-height: 24px;
}
main .sticky-header .product-ratings:before {
  color: rgba(0, 0, 0, 0.16);
}

.single-info-list {
  margin-bottom: 1.7rem;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}
.single-info-list li {
  margin-bottom: 1rem;
  letter-spacing: 0.001em;
}
.single-info-list li strong {
  color: #222529;
  letter-spacing: 0;
}

.product-single-filter {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.product-single-filter label {
  margin-right: 4.2rem;
  min-width: 5rem;
  margin-bottom: 0;
  color: #777;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}
.product-single-filter .config-swatch-list {
  display: inline-flex;
  margin: 0;
}
.product-single-filter .config-size-list li {
  margin-bottom: 0;
  margin-right: 0;
  color: #777;
}
.product-single-filter .config-size-list li a {
  margin: 3px 6px 3px 0;
  min-width: 3.2rem;
  height: 2.6rem;
  border: 1px solid #eee;
  color: inherit;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2.6rem;
  background-color: #fff;
}
.product-single-filter .config-size-list li a:not(.disabled):hover {
  border-color: #08c;
  background-color: #08c;
  color: #fff;
}
.product-single-filter .config-size-list li a.disabled {
  cursor: not-allowed;
  text-decoration: none;
  background-color: transparent;
  opacity: 0.5;
}
.product-single-filter .config-size-list li a.filter-color {
  height: 2.8rem;
  min-width: 2.8rem;
}
.product-single-filter .config-size-list li.active a {
  border-color: #08c;
  outline: none;
  color: #fff;
  background-color: #08c;
}
.product-single-filter .config-size-list li.active a.filter-color:before {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-family: "porto";
  font-size: 1.1rem;
  line-height: 1;
  content: "";
}
.product-single-filter .config-img-list li a {
  height: 100%;
}
.product-single-filter .config-img-list li img {
  width: 30px;
  height: 30px;
}
.product-single-filter.product-single-qty {
  max-width: 148px;
  max-height: 7.5rem;
  border-bottom: 0;
}

.product-single-qty label {
  color: #222529;
  font-weight: 600;
  font-size: 1.5rem;
}

.product-single-share {
  display: flex;
  margin-top: 0.7rem;
  align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}
.product-single-share label {
  margin-right: 1.2rem;
  margin-bottom: 0.5rem;
  color: #222529;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.1;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}
.product-single-share .social-icons {
  margin-top: 2px;
}
.product-single-share .social-icons.vertical {
  display: flex;
  flex-direction: column;
}
.product-single-share .social-icons.vertical .social-icon {
  border-radius: 0;
}
.product-single-share .social-icon {
  line-height: 2em;
  border: 2px solid transparent;
  margin: 0.2857em 1px 0.2857em 0;
}

.product-single-share:not(.icon-with-color) .social-icon {
  border-radius: 50%;
}
.product-single-share:not(.icon-with-color)
  .social-icon:not(:hover):not(:active):not(:focus) {
  color: #222529;
  background-color: transparent;
  border-color: #e7e7e7;
}

.product-single-gallery {
  margin-bottom: 3.3rem;
}
.product-single-gallery .sticky-slider:not(.sticked) {
  position: relative !important;
}
.product-single-gallery a {
  display: block;
}
.product-single-gallery img {
  display: block;
  width: 100%;
  max-width: none;
}
.product-single-gallery .prod-thumbnail .owl-nav {
  font-size: 1.6rem;
  color: #08c;
}
.product-single-gallery .prod-thumbnail .owl-nav .owl-prev {
  left: 1.5rem;
}
.product-single-gallery .prod-thumbnail .owl-nav .owl-next {
  right: 1.5rem;
}
.product-single-gallery .owl-nav {
  font-size: 2.8rem;
}
.product-single-gallery .owl-nav .owl-prev {
  left: 2.5rem;
}
.product-single-gallery .owl-nav .owl-next {
  right: 2.5rem;
}
.product-single-gallery .owl-nav button {
  transition: opacity 0.5s;
}
.product-single-gallery .product-item {
  position: relative;
  z-index: 2;
}
.product-single-gallery .product-item:not(:last-child) {
  margin-bottom: 4px;
}
.product-single-gallery .product-item:hover .prod-full-screen {
  opacity: 1;
}
.product-single-gallery .product-single-grid {
  margin-bottom: 3.6rem;
}
.product-single-gallery .label-group {
  position: absolute;
  z-index: 100;
  top: 1.1rem;
  left: 1.1rem;
}
.product-single-gallery .product-label {
  display: block;
  text-align: center;
  margin-bottom: 5px;
  text-transform: uppercase;
  padding: 7px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  border-radius: 12px;
}
.product-single-gallery .product-label.label-hot {
  background-color: #2ba968;
}
.product-single-gallery .product-label.label-sale {
  background-color: #da5555;
}
.product-single-gallery .product-label.label-new {
  background-color: #08c;
}

.product-single-tabs .tab-pane {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #7b858a;
  line-height: 1.92;
}
.product-single-tabs .sticky-header {
  height: auto;
}
.product-single-tabs .nav.nav-tabs .nav-link {
  color: #818692;
}
.product-single-tabs .nav.nav-tabs .nav-link.active {
  color: #222529;
}
.product-single-tabs .nav.nav-tabs .nav-link {
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
}
.product-single-tabs .nav.nav-tabs .nav-link:hover,
.product-single-tabs .nav.nav-tabs .nav-link.active {
  border-bottom-color: #222529;
}
.product-single-tabs .nav-item {
  font-size: 1.3rem;
}

.product-desc-content {
  margin-bottom: 2.5rem;
}
.product-desc-content .feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.9rem;
  border-style: solid;
  border-width: 2px;
  width: 64px;
  height: 64px;
  line-height: 60px;
  border-radius: 60px;
  font-size: 28px;
  color: #08c;
  background: transparent;
  border-color: #08c;
}
.product-desc-content .feature-box p {
  font-size: 14px;
  line-height: 27px;
  color: #4a505e;
  letter-spacing: 0;
}
.product-desc-content .feature-box h3 {
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
}
.product-desc-content p {
  margin-bottom: 2.3rem;
  letter-spacing: 0.005em;
}
.product-desc-content ul,
.product-desc-content ol {
  margin-bottom: 2.4rem;
  padding-left: 7.4rem;
  letter-spacing: 0.005em;
  position: relative;
  padding-top: 2px;
}
.product-desc-content li {
  margin-bottom: 9px;
  letter-spacing: 0;
}
.product-desc-content li:before {
  content: "";
  position: absolute;
  left: 4rem;
  display: inline-block;
  margin-top: -2px;
  vertical-align: middle;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 1.8rem;
  color: #21293c;
  font-size: 1.6rem;
}
.product-desc-content img.float-right,
.product-desc-content img.float-left {
  max-width: 50%;
}
.product-desc-content img {
  padding-top: 4px;
}
.product-desc-content .feature-box i {
  display: inline-block;
  font-size: 2.8rem;
  float: none;
  margin-bottom: 0;
  margin-top: 3px;
}
.product-desc-content .feature-box-content {
  margin-left: 0;
}

.table.table-striped {
  margin-top: 2rem;
  margin-bottom: 5.9rem;
}
.table.table-striped td,
.table.table-striped th {
  padding: 1.1rem 1.2rem;
}
.table.table-striped tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.product-tags-content h4 {
  margin: 0 0 2rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}
.product-tags-content form {
  margin-bottom: 2rem;
}
.product-tags-content .form-group {
  display: flex;
  align-items: stretch;
}
.product-tags-content .form-control {
  margin-right: 10px;
}
.product-tags-content .btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.product-reviews-content {
  padding-top: 2px;
  padding-bottom: 2.5rem;
  line-height: 1.92;
}
.product-reviews-content .required {
  color: #222529;
}
.product-reviews-content .reviews-title {
  margin-bottom: 1.6rem;
  font-size: 2rem;
  font-weight: 400;
}
.product-reviews-content .reviews-title + p {
  padding-bottom: 0.4rem;
  letter-spacing: 0.005em;
}
.product-reviews-content .ratings-container {
  margin: -3px -2px 0.5rem 0;
}
.product-reviews-content .divider {
  border-top: 1px solid #e7e7e7;
  margin: 4rem 0;
}
.product-reviews-content .comments .comment-block {
  padding-bottom: 2.3rem;
  background-color: #f5f7f7;
}

.add-product-review {
  padding-top: 5px;
}
.add-product-review
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:after {
  top: 4px;
  left: 2px;
}
.add-product-review form {
  padding: 3.5rem 2rem 3.3rem;
  border-radius: 3px;
  background-color: #f4f4f4;
}
.add-product-review h3 {
  margin-bottom: 1.6rem;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: -0.01em;
}
.add-product-review label {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 1.1rem;
}
.add-product-review .rating-stars {
  margin-bottom: 1rem;
}
.add-product-review .form-control {
  margin-top: 1.4rem;
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
  max-width: 100%;
  height: 37px;
}
.add-product-review textarea.form-control {
  min-height: 170px;
}
.add-product-review .btn {
  padding: 0.55em 1rem 0.5em;
  font-weight: 400;
  text-transform: none;
  font-family: "Open Sans", sans-serif;
}
.add-product-review .custom-control-label {
  letter-spacing: 0.005em;
  line-height: 1.9;
}
.add-product-review .custom-control-label:before,
.add-product-review .custom-control-label:after {
  top: 6px;
  left: 0;
  width: 15px;
  height: 15px;
  font-size: 1.2rem;
  font-weight: 300;
}
.add-product-review
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:before {
  background-color: #0075ff;
  border-color: #0075ff;
}
.add-product-review
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:after {
  color: #fff;
}
.add-product-review .custom-control {
  padding-left: 2.2rem;
  margin-bottom: 1rem;
  margin-top: -6px;
}

.rating-stars {
  display: flex;
  display: -ms-flexbox;
  position: relative;
  height: 14px;
  font-size: 1.4rem;
  margin-bottom: 2.8rem;
}
.rating-stars a {
  color: #706f6c;
  text-indent: -9999px;
  letter-spacing: 1px;
  width: 16px;
}
.rating-stars a:before {
  content: "";
  position: absolute;
  left: 0;
  height: 14px;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  text-indent: 0;
  overflow: hidden;
  white-space: nowrap;
}
.rating-stars a.active:before,
.rating-stars a:hover:before {
  content: "";
  font-weight: 900;
}
.rating-stars .star-1 {
  z-index: 10;
}
.rating-stars .star-2 {
  z-index: 9;
}
.rating-stars .star-3 {
  z-index: 8;
}
.rating-stars .star-4 {
  z-index: 7;
}
.rating-stars .start-5 {
  z-index: 6;
}
.rating-stars .star-1:before {
  width: 16px;
}
.rating-stars .star-2:before {
  width: 32px;
}
.rating-stars .star-3:before {
  width: 48px;
}
.rating-stars .star-4:before {
  width: 64px;
}
.rating-stars .star-5:before {
  content: "";
}

.products-section {
  padding-top: 3.8rem;
  padding-bottom: 3rem;
}
.products-section .owl-carousel.dots-top .owl-dots {
  margin: 0px -2px 3.5rem;
}
.products-section .owl-carousel.dots-top .owl-dots span {
  border-color: rgba(0, 68, 102, 0.4);
}
.products-section .owl-carousel.dots-top .owl-dot.active span {
  border-color: #08c;
}
.products-section .product-title {
  margin-bottom: 4px;
}
.products-section .price-box {
  margin-bottom: 1.4rem;
}
.products-section h2 {
  font-family: "Poppins";
  padding-bottom: 1rem;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 3.4rem;
  font-size: 1.8rem;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}
.products-section.pt-sm {
  padding-top: 2.5rem;
}

.products-section .container-fluid {
  padding-right: 20px;
  padding-left: 20px;
}

@media (min-width: 768px) {
  .products-section {
    padding-top: 4.8rem;
    padding-bottom: 3.6rem;
  }

  .add-product-review form {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (min-width: 992px) {
  .main-content .col-lg-7 {
    flex: 0 0 54%;
    max-width: 54%;
  }
  .main-content .col-lg-5 {
    flex: 0 0 46%;
    max-width: 46%;
  }
}
@media (min-width: 576px) {
  .product-tags-content .form-control {
    width: 250px;
  }
}
@media (max-width: 575px) {
  .rating-stars a:before {
    line-height: 1.2;
  }

  .ratings-container .ratings,
  .ratings-container .product-ratings {
    line-height: 1.2;
  }
}
@media (max-width: 480px) {
  .product-reviews-content .reviews-title {
    font-size: 1.7rem;
  }
}

.product-desc .view-more {
  color: #08c;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  body:not(.sidebar-opened) .main-content-wrap > .main-content {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .sidebar-toggle {
    display: none;
  }
}
@media (max-width: 991px) {
  .mobile-sidebar {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    padding: 2rem;
    margin: 0;
    transform: translate(-260px);
    transition: transform 0.2s ease-in-out 0s;
    background-color: #fff;
    z-index: 9999;
    overflow-y: auto;
  }
}

.table.table-cart tr th,
.table.table-cart tr td,
.table.table-wishlist tr th,
.table.table-wishlist tr td {
  vertical-align: middle;
}
.table.table-cart tr th,
.table.table-wishlist tr th {
  border: 0;
  color: #222529;
  font-weight: 700;
  line-height: 2.4rem;
  text-transform: uppercase;
}
.table.table-cart tr td,
.table.table-wishlist tr td {
  border-top: 1px solid #e7e7e7;
}
.table.table-cart tr td.product-col,
.table.table-wishlist tr td.product-col {
  padding: 2rem 0.8rem 1.8rem 0;
}
.table.table-cart tr.product-action-row td,
.table.table-wishlist tr.product-action-row td {
  padding: 0 0 2.2rem;
  border: 0;
}
.table.table-cart .product-image-container,
.table.table-wishlist .product-image-container {
  position: relative;
  width: 8rem;
  margin: 0;
}
.table.table-cart .product-title,
.table.table-wishlist .product-title {
  margin-bottom: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.75;
}
.table.table-cart .product-title a,
.table.table-wishlist .product-title a {
  color: inherit;
}
.table.table-cart .product-single-qty,
.table.table-wishlist .product-single-qty {
  margin: 0.5rem 4px 0.5rem 1px;
}
.table.table-cart .product-single-qty .form-control,
.table.table-wishlist .product-single-qty .form-control {
  height: 48px;
  width: 44px;
  font-size: 1.6rem;
  font-weight: 700;
}
.table.table-cart .subtotal-price,
.table.table-wishlist .subtotal-price {
  color: #222529;
  font-size: 1.6rem;
  font-weight: 600;
}
.table.table-cart .btn-remove,
.table.table-wishlist .btn-remove {
  right: -10px;
  font-size: 1.1rem;
}
.table.table-cart tfoot td,
.table.table-wishlist tfoot td {
  padding: 2rem 0.8rem 1rem;
}
.table.table-cart tfoot .btn,
.table.table-wishlist tfoot .btn {
  padding: 1.2rem 2.4rem 1.3rem 2.5rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  height: 43px;
  letter-spacing: -0.018em;
}
.table.table-cart tfoot .btn + .btn,
.table.table-wishlist tfoot .btn + .btn {
  margin-left: 1rem;
}
.table.table-cart .bootstrap-touchspin.input-group,
.table.table-wishlist .bootstrap-touchspin.input-group {
  margin-right: auto;
  margin-left: auto;
}

.btn-remove:hover,
.btn-remove:focus {
  color: #08c;
  text-decoration: none;
}

tbody .product-col {
  font-size: 0;
}
.product-col .product-image-container {
  display: table-cell;
  padding-right: 1.8rem;
  margin-bottom: 0;
  vertical-align: middle;
}
.product-col .product-image img {
  border: 1px solid #ccc;
}
.product-col .product-title {
  margin-bottom: 1px;
  display: table-cell;
  vertical-align: middle;
}

.cart-summary {
  margin-bottom: 3.6rem;
  padding: 2.4rem 3rem 3.4rem;
  border: 2px solid #e7e7e7;
  background: #fff;
}
.cart-summary h3 {
  margin-bottom: 2.3rem;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
}

.checkout-progress-bar li a.active,
.checkout-progress-bar li a:hover {
  color: #08c;
  opacity: 1;
}

.table.table-totals {
  margin-bottom: 2.6rem;
}
.table.table-totals .custom-radio .custom-control-input {
  width: 100px;
}
.table.table-totals h4 {
  display: inline;
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.table.table-totals .form-control {
  line-height: 1.5;
}
.table.table-totals .form-group {
  margin-bottom: 1.5rem;
}
.table.table-totals
  .select2-container.select2-container--default
  .select2-selection--single {
  min-height: 37px;
}
.table.table-totals
  .select2-container.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 1.6;
}
.table.table-totals tr th,
.table.table-totals tr td {
  padding: 1rem;
  border: 0;
  letter-spacing: -0.01em;
  font-size: 14px;
  font-weight: 600;
  color: #222529;
  font-family: "poppins";
}
.table.table-totals tr th:last-child,
.table.table-totals tr td:last-child {
  color: #777;
  text-align: right;
  font-weight: 400;
}
.table.table-totals tbody tr {
  border-bottom: 1px solid #dcdcdc;
}
.table.table-totals tfoot {
  border-top: 1px solid #ccc;
  color: #222529;
  font-size: 1.6rem;
  font-weight: 700;
}
.table.table-totals tfoot tr td {
  padding-top: 1.3rem;
  padding-bottom: 1.6rem;
  vertical-align: middle;
  font-size: 1.6rem;
  padding-left: 1.2rem;
}
.table.table-totals tfoot tr td:last-child {
  font-weight: 700;
  font-size: 22px;
  color: #222529;
}
.table.table-totals tfoot b {
  font-size: 2.2rem;
}

.checkout-methods .btn {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  letter-spacing: -0.015em;
  padding: 1em 0;
}
.checkout-methods .btn i {
  margin-left: 1.2rem;
}

.cart-summary form {
  margin-bottom: 1.6rem;
}
.cart-summary .form-group-sm {
  max-width: none;
}
.cart-summary .form-group-sm label {
  margin-top: 1.3rem;
  letter-spacing: 0;
  margin-bottom: 1.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: #777;
  font-family: "Open Sans", sans-serif;
}
.cart-summary .form-group-sm .form-control {
  height: 3.7rem;
  font-size: 1.4rem;
  letter-spacing: -0.012em;
}
.cart-summary .btn-update-total {
  background-color: #f4f4f4;
  color: #222529;
  border: none;
  padding: 8px 12px;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
}
.cart-summary .btn-update-total:hover {
  color: #fff;
  background-color: #08c;
}

@media (max-width: 479px) {
  .cart-summary {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.checkout-progress-bar {
  margin: 4.7rem 0 2.1rem;
  font-size: 0;
  line-height: 1.4;
}
.checkout-progress-bar li {
  display: inline-block;
  position: relative;
  margin-bottom: 3rem;
  color: #999;
  font-family: Poppins, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.03em;
  text-align: center;
  vertical-align: top;
}
.checkout-progress-bar li.prev {
  color: #222529;
}
.checkout-progress-bar li a {
  color: #222524;
}
.checkout-progress-bar li.active a {
  color: #08c;
}
.checkout-progress-bar li.active a:hover {
  color: #08c;
}
.checkout-progress-bar li.active + li a {
  color: #919292;
}
.checkout-progress-bar li.active + li a:hover {
  color: #08c;
}
.checkout-progress-bar li.disabled a {
  color: #919292;
}
.checkout-progress-bar li:not(:first-child) {
  margin-left: 5.8rem;
}
.checkout-progress-bar li:not(:first-child):before {
  position: absolute;
  left: -3.3rem;
  top: -0.8rem;
  content: "⟫";
  color: #999;
  font-family: "porto";
  font-size: 3rem;
}
.checkout-progress-bar li:last-child {
  pointer-events: none;
}

.checkout-steps li {
  margin-bottom: 3rem;
}
.checkout-steps li form {
  margin-top: 1.5rem;
  margin-bottom: 0;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ccc;
}
.checkout-steps li form:last-of-type {
  padding-bottom: 0.1rem;
  border-bottom: 0;
}
.checkout-steps li form p {
  margin-bottom: 1rem;
  color: #777;
  font-size: 1.3rem;
}
.checkout-steps li .form-footer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.step-title {
  margin-bottom: 0;
  color: #222529;
  font-size: 2.2rem;
}
.step-title:after {
  display: block;
  clear: both;
  content: "";
}
.step-title .step-title-edit {
  margin-top: 0.4rem;
  float: right;
  font-size: 1.4rem;
}

.order-summary {
  margin-bottom: 3rem;
  padding: 3rem;
  border: 2px solid #e7e7e7;
}
.order-summary .custom-radio .custom-control-input {
  width: 102px;
}
.order-summary h3 {
  margin-bottom: 1.9rem;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
}
.order-summary h4 {
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 19px;
}
.order-summary .info-box p {
  padding: 11px 3px;
  list-style-position: inside;
  text-align: left;
  margin-bottom: 9px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #2f6473;
}

.table-mini-cart {
  margin-bottom: 0;
}
.table-mini-cart tr,
.table-mini-cart th,
.table-mini-cart td,
.table-mini-cart thead th {
  border: 0;
  vertical-align: middle;
  color: #222529;
}
.table-mini-cart th,
.table-mini-cart td {
  padding: 1rem;
}
.table-mini-cart td:last-child {
  text-align: right;
}
.table-mini-cart thead,
.table-mini-cart tfoot tr {
  border-bottom: 1px solid #e7e7e7;
}
.table-mini-cart .product-col,
.table-mini-cart .price-col {
  padding: 1.3rem 1rem 1.4rem;
}
.table-mini-cart .product-col .product-image-container {
  max-width: 75px;
  padding-right: 1.5rem;
  vertical-align: top;
}
.table-mini-cart .price-col {
  font-size: 1.4rem;
  font-weight: 400;
}
.table-mini-cart .product-title {
  display: block;
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
}
.table-mini-cart .cart-subtotal th,
.table-mini-cart .cart-subtotal td,
.table-mini-cart .cart-tax th,
.table-mini-cart .cart-tax td {
  padding-top: 3rem;
  padding-bottom: 1.3rem;
}
.table-mini-cart .order-shipping td {
  padding-top: 1.2rem;
  padding-bottom: 0.9rem;
}
.table-mini-cart .order-shipping h4 {
  margin-bottom: 1.3rem;
}
.table-mini-cart .order-shipping .custom-radio {
  margin: 0;
}
.table-mini-cart .order-shipping .custom-radio label {
  width: 100%;
  font-size: 1.3rem;
  font-weight: 400;
}
.table-mini-cart .order-shipping ul {
  margin-bottom: 2rem;
}
.table-mini-cart .order-shipping li + li {
  margin-top: 1.5rem;
}
.table-mini-cart .order-shipping .price {
  float: right;
  font-weight: 600;
}
.table-mini-cart .order-total th {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}
.table-mini-cart .order-total td {
  padding-top: 1.8rem;
  padding-bottom: 2.3rem;
}
.table-mini-cart .order-total h4 {
  font-size: 1.6rem;
}
.table-mini-cart .order-total .total-price {
  color: #222529;
  font-size: 2.2rem;
}

.payment-methods {
  padding: 2rem 0.8rem 0;
  margin-bottom: 4.6rem;
  border-bottom: 1px solid #e7e7e7;
}
.payment-methods h4 {
  margin-bottom: 1.5rem;
}
.payment-methods ul {
  margin: 1.5rem 0 0;
}
.payment-methods .custom-radio {
  margin: 0;
}
.payment-methods li + li {
  margin-top: 1.5rem;
}

.btn-place-order {
  width: 100%;
  margin-bottom: 0.6rem;
  padding: 1.4rem;
  font-family: "Open Sans", sans-serif;
}

.cart-summary,
.order-summary {
  position: relative;
}
.cart-summary:before,
.order-summary:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  visibility: hidden;
}
.cart-summary.loading:before,
.order-summary.loading:before {
  opacity: 1;
  visibility: visible;
}

@keyframes navItemArrow {
  0% {
    position: relative;
    right: -1px;
  }
  50% {
    position: relative;
    right: 3px;
  }
  100% {
    position: relative;
    right: -1px;
  }
}

.single {
  margin-bottom: 2.3rem;
}

.single h3 {
  font-size: 2rem;
  font-weight: 600;
}
.single h3 i {
  margin-right: 7px;
  font-size: 2rem;
}
.single .social-icon {
  width: 29px;
  height: 29px;
}
.single .social-icon + .social-icon {
  margin-left: 0.8rem;
}

.sidebar {
  position: relative;
  height: 100%;
  font-size: 1.3rem;
}
.sidebar .widget {
  margin-bottom: 3.1rem;
}
.sidebar .sidebar-wrapper {
  padding-bottom: 4.2rem;
}
.sidebar .sidebar-wrapper .widget:last-child {
  margin-bottom: 0;
  padding-bottom: 3rem;
}

.widget-post .widget-title {
  margin-bottom: 1.7rem;
}

.widget-title {
  margin: 0.5rem 0 1.3rem;
  color: #313131;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.widget form {
  margin-bottom: 0;
}

.list {
  padding: 0;
  list-style: none;
  font-size: 1.3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-top: -8px;
  margin-bottom: 0;
}
.list li {
  display: block;
  position: relative;
  margin: 0;
  padding: 6px 0 6px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  line-height: 24px;
}
.list li:before {
  content: "";
  position: relative;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-left: -11px;
  margin-right: 6px;
  font-size: 7.2px;
  opacity: 0.7;
  vertical-align: middle;
}
.list li:hover::before {
  animation: navItemArrow 0.6s linear infinite;
}
.list li a {
  color: inherit;
}
.list li:first-child {
  border-top-width: 0;
}
.list .list {
  margin-top: 5px;
  margin-bottom: -6px;
  border-bottom: none;
}
.list .list li:first-child {
  border-top-width: 1px;
}

@media (max-width: 991px) {
  .sidebar.mobile-sidebar {
    position: fixed;
  }
}

.widget-dashboard h2 {
  margin-top: -1px;
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
}
.widget-dashboard .nav-item:last-child {
  margin-right: 3.5rem;
}
.widget-dashboard .list {
  border-bottom: none;
}
.widget-dashboard .list a {
  padding: 1rem 0 1.1rem;
  color: #777;
  letter-spacing: -0.025em;
  font-size: 1.4rem;
}
.widget-dashboard .list a:hover,
.widget-dashboard .list a:focus {
  background: transparent;
}
.widget-dashboard .list a.active {
  color: #222524;
  font-weight: 700;
}
.widget-dashboard li:last-child a {
  border-bottom: 0;
}
.widget-dashboard li {
  padding: 8px 0 8px 0;
}
.widget-dashboard li:before {
  display: none;
}

.account-container {
  margin-top: 4.2rem;
  margin-bottom: 4rem;
}

.nav-tabs.list .nav-item {
  padding: 0;
}
.nav-tabs.list .nav-item .nav-link {
  padding: 1.3rem 0 1.5rem;
  font-family: "Open Sans", sans-serif;
  text-transform: capitalize;
  font-size: 1.4rem;
  border: none;
}
.nav-tabs.list .nav-item .nav-link:not(.active) {
  font-weight: 400;
  color: #777;
}
.nav-tabs.list .nav-item .nav-link.address,
.nav-tabs.list .nav-item .nav-link.active {
  font-weight: 700;
  color: #222529;
}

.account-content {
  margin-top: 2.1rem;
}
.account-content label {
  margin-bottom: 0.7rem;
  color: #777;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
}
.account-content form {
  margin-bottom: 4px;
}
.account-content .form-input {
  margin-bottom: 1.7rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-color: #e7e7e7;
  line-height: 32px;
}
.account-content .form-group {
  margin-bottom: 1.7rem;
}
.account-content .form-group p {
  font-size: 1.3rem;
  margin-top: 3px;
}
.account-content .form-control {
  padding-top: 2px;
  padding-left: 1.2rem;
  font-size: 1.4rem;
  height: 50px;
  border-color: rgba(0, 0, 0, 0.09);
  letter-spacing: -0.015em;
}
.account-content .btn {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
}

.change-password {
  padding: 2.6rem 3rem 2.2rem;
  border: 2px solid #e7e7e7;
}
.change-password h3 {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
}
.change-password .btn {
  padding: 1.6rem 4.5rem 1.5rem;
  letter-spacing: -0.02em;
}

.address h3 {
  font-size: 1.8rem;
}
.address .title {
  margin-bottom: 2.9rem;
}
.address label {
  margin-bottom: 1.1rem;
}
.address .form-control {
  height: 37px;
}
.address h4 {
  font-size: 1.7rem;
  letter-spacing: -0.016em;
}
.address .heading {
  margin-bottom: 6px;
}
.address .address-action {
  padding: 0.8rem 2.3rem 0.7rem;
  background: #f4f4f4;
  color: #222524;
  font-family: "Open Sans", sans-serif;
}
.address .address-action:hover {
  background-color: #08c;
  color: #fff;
}
.address select.form-control:not([size]):not([multiple]) {
  height: 37px;
}
.address .select-custom {
  margin-bottom: 1.7rem;
}
.address .select-custom::after {
  top: 72%;
  right: 1.5rem;
}
.address .form-control {
  line-height: 1.5;
}
.address.account-content h4 {
  font-size: 1.4rem;
  letter-spacing: 0;
}

.order-table-container .btn {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
}

.custom-account-container {
  margin-bottom: 5.6rem;
}

.order-table-container .btn-dark {
  min-width: 200px;
  padding: 16px 0 15px;
  font-size: 15px;
  letter-spacing: -0.015em;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.account-sub-title {
  margin-top: 0.6rem;
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
  letter-spacing: -0.01em;
}
.account-sub-title i {
  font-size: 3.5rem;
  color: #d3d3d4;
}

.wishlist-table-container {
  margin-bottom: 5.5rem;
}
.wishlist-table-container .table-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.3em;
  font-weight: 400;
  letter-spacing: -0.7px;
  line-height: 1.42857;
  text-transform: uppercase;
}
.wishlist-table-container .btn {
  height: 42px;
  width: auto;
  padding: 0 25px !important;
  font-size: 13px;
  line-height: 42px;
  text-indent: 0;
}
.wishlist-table-container .btn-quickview {
  margin-right: 6px;
  background: #f4f4f4;
  color: #222529;
  font-family: "Open Sans", sans-serif;
}
.wishlist-table-container .btn-quickview:hover {
  background-color: #08c;
  color: #fff;
}
.wishlist-table-container .btn-shop {
  font-weight: 600;
  text-transform: uppercase;
  min-width: 160px;
}

.table.table-wishlist tr th {
  padding: 10px 5px 10px 16px;
}
.table.table-wishlist tr th.thumbnail-col {
  width: 10%;
}
.table.table-wishlist tr th.product-col {
  width: 29%;
}
.table.table-wishlist tr th.price-col {
  width: 13%;
}
.table.table-wishlist tr th.status-col {
  width: 19%;
}
.table.table-wishlist tr td {
  padding: 20px 5px 23px 16px;
}
.table.table-wishlist .product-price {
  color: inherit;
  font-size: 1.4rem;
  font-weight: 400;
}
.table.table-wishlist .price-box {
  margin-bottom: 0;
}
.table.table-wishlist .stock-status {
  color: #222529;
  font-weight: 600;
}

@media (max-width: 1199px) {
  .wishlist-table-container .btn {
    width: 100%;
  }
  .wishlist-table-container .btn:first-child {
    margin-bottom: 1rem;
  }
}
@media (max-width: 767px) {
  .wishlist-table-container {
    border-top: 4px solid #08c;
  }

  .table.table-wishlist {
    border: 1px solid #e7e7e7;
    border-top: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  }
  .table.table-wishlist,
  .table.table-wishlist tbody {
    display: block;
  }
  .table.table-wishlist thead {
    display: none;
  }
  .table.table-wishlist tr td {
    padding: 0.5rem 1rem;
    border-top: 0;
  }
  .table.table-wishlist tr td.product-col {
    padding-bottom: 0.5rem;
  }
  .table.table-wishlist .product-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    border-top: 2px solid #ddd;
  }
  .table.table-wishlist .product-row:first-child {
    border-top: 0;
  }
  .table.table-wishlist .product-col {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .table.table-wishlist .product-col .product-image-container {
    flex: 0 0 auto;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .table.table-wishlist .btn-shop {
    width: 100%;
  }
}
