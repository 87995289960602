.products-section h2 {
  font-weight: 500 !important;
}
.product-single-details .product-title {
  font-size: 20px;
  max-height: 120px;
  font-weight: 500;
  line-height: 30px !important;
}

.product-ratings {
  padding: 10px 0;
}
.ratings-container .product-ratings .ratings:before {
  display: none !important;
}
.comment-content {
  font-size: 12px;
  font-weight: 500;
  color: black;
}
.search-products .product-default {
  width: 100%;
}
.search-products .product-default figure {
  padding-top: 10px;
}
.product-action .addToCartBtn {
  background-color: #fe9900;
  border: 0;
  border-radius: 10px;
  width: 200px;
  font-size: 16px;
  padding: 12px;
}
.product-action .add-wishlist {
  padding: 10px;
  font-size: 2.2rem;
  border-radius: 10px;
}
.product_Spec {
  margin: 20px auto;
}
.product_Spec ul {
  flex-wrap: wrap;
}
.product_Spec ul li {
  display: inline-block;
  margin: 10px;
  background-color: #ebebeb;
  padding: 10px;
  border-radius: 8px;
  height: 73px;
}
.product_Spec ul li h6 {
  font-size: 12px;
  color: #000;
}

.product_Spec ul li span {
  font-size: 13px;
}
.product-single-tabs {
  width: 91%;
  margin: auto;
}
.product-single-tabs ul li {
  letter-spacing: 0.7px;
  cursor: pointer;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.product-desc-content {
  font-size: 15px;
}
.product-reviews-content .reviews-header {
  background-color: #fff3e1;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  height: 74px;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}
.product-reviews-content .reviews-header h2 {
  font-size: 25px;
  margin: 1px 20px;
  color: #898989;
  text-align: center;
}
.product-reviews-content .reviews-header span {
  font-size: 18px;
  margin: 1px 20px;
  color: #111111;
  cursor: pointer;
}
.product-reviews-content swiper-slide {
  height: 200px;
  width: 300px;
  margin: 10px 20px;
}

.product-reviews-content .review_card {
  padding: 20px;
  border: 2px solid #ebebeb;
  border-radius: 10px;
  max-height: 200px;
  min-height: 150px;
}
.product-reviews-content .comment-by {
  font-size: 14px;
}
.product-reviews-content .comment-by span {
  margin-right: 10px;
}
.Spec_list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.Spec_list li {
  background-color: #eeeeee;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}
.Spec_list li h5 {
  display: inline-block;
  color: #000;
}
.Spec_list li span {
  float: right;
  font-size: 14px;
  color: #000;
}
.product-default figure svg {
  height: 15px !important;
  width: 30px !important;
}
.product-desc-content img {
  padding-top: 4px;
  margin: 20px auto !important;
}
.Filters_list li {
  display: inline-block;
  border: 1px solid #fe9900;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  margin: 1px 10px;
  border-radius: 10px;
}

.Filters_list li svg {
  margin: 4px 10px;
  color: #ff9200;
}
.toolbox .form-control {
  font-size: 14px;
}
.sticky-add-to-cart-btn {
  position: fixed;
  rotate: -90deg;
  font-size: 14px;
  bottom: 50%;
  right: 0px;
  background-color: #fe9900;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s ease;
}
