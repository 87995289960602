@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

.arabic-text {
  text-align: right;
  font-family: "Cairo", sans-serif !important;
}
.arabic-text p,
.arabic-text h1,
.arabic-text h2,
.arabic-text h3,
.arabic-text h4,
.arabic-text h5,
.arabic-text h6,
.arabic-text a,
.arabic-text label,
.arabic-text div,
.arabic-text td,
.arabic-text th,
.arabic-text button,
span {
  font-family: "Cairo", sans-serif !important;
}

.text-777 {
  color: #777 !important;
}
