@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primary-color: #000; /* Default fallback */
  --secondary-color: #aaffffff; /* Default fallback */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Poppins", sans-serif !important;
}
a {
  text-decoration: none;
}

.primary-bg {
  background-color: var(--primary-color);
}

input:-webkit-autofill {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  color: #000 !important;
}

input:-webkit-autofill:focus {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  color: #000 !important;
}

input:-webkit-autofill:hover {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  color: #000 !important;
}

input:-webkit-autofill:active {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  color: #000 !important;
}

.secondary-bg {
  background-color: var(--secondary-color);
}
.primary-bg-light {
  background-color: rgba(
    red(--primary-color),
    green(--primary-color),
    blue(--primary-color),
    0.5
  );
}

.primary-text {
  color: var(--primary-color);
}

.secondary-text {
  color: var(--secondary-color);
}

.header {
  background-color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}

/* Basic styling for the dropdown */
/* Basic styling for the dropdown */
.navbar {
  position: relative;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.CatSliderNoImg .dropdown-menu {
  padding: 10px;
}

.CatSliderNoImg .dropdown-menu {
  position: absolute;
  border-radius: 10px;
  top: 30px !important;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure this is higher than other elements */
}

.CatSliderNoImg .dropdown-menu .dropdown-item {
  color: black;
  font-size: 15px;

  padding: 2px;
  text-decoration: none;
  display: inline-block;
}
.CatSliderNoImg .dropdown-menu .dropdown-item .subCat li {
  font-size: 12px;
  color: #888888;
  margin: 5px 0;
}
.CatSliderNoImg .dropdown-menu .dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-submenu {
  display: none;
}

.subCat li {
  display: block !important;
}
.dropdown-submenu.show {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 1600px) {
  .container {
    max-width: 1440px !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
