/* YourComponent.css */

.category-tree-container {
  max-width: 30rem; /* Adjust width as needed */
  margin: 0 auto; /* Center the container */
}

.tree-root {
  list-style-type: none; /* Remove default list styling */
  padding-left: 20px; /* Indent child nodes */
}

.tree-node-header {
  cursor: pointer; /* Change cursor to pointer */
  display: flex; /* Align items horizontally */
  align-items: center; /* Center align items */
  margin: 5px 0; /* Add spacing between items */
}

.tree-node-label {
  margin-right: 10px;
  font-size: 16px; /* Spacing between label and icon */
}

.toggle-icon {
  font-weight: bold; /* Make toggle icon stand out */
}

.tree-children {
  list-style-type: none; /* Remove default list styling */
  padding-left: 20px; /* Indent child nodes */
}
